<script setup lang="ts">
import {
  VButton,
  VInput,
  VModal,
  VSearch,
  VSpeakerAvatar,
  VTextarea,
  VToggleTwoOptions,
  VTooltip
} from '@techcast/histoire'

import autoAnimate from '@formkit/auto-animate'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import anonymousUserImage from '@/assets/images/anonymous-user.png'
import CloudImage from '@/components/utils/CloudImage.vue'
import ImageCropper from '@/components/utils/ImageCropper.vue'
import { getFilteredSpeakers } from '@/composables/speakers/useSpeakersFilter'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { useImageCrop } from '@/composables/useImageCropped'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAssetsStore } from '@/stores/assets.store'
import { useSpeakersStore } from '@/stores/speakers.store'
import type { components } from '@/types/swagger'
import { arraySortedByStringProperty } from '@/utils/arraySortedByStringProperty'
import { getImageName } from '@/utils/getImageName'

/****************************************
 * NOTIFICATIONS
 *****************************************/
const toast = useToast()

/****************************************
 * TRANSLATIONS
 *****************************************/
const { t } = useI18n()

/****************************************
 * TYPES
 *****************************************/
type Speaker = components['schemas']['Speaker']
type CreateSpeakerDto = components['schemas']['CreateSpeakerDto']
type UpdateSpeakerDto = components['schemas']['UpdateSpeakerDto']

/****************************************
 * STORES
 *****************************************/
const speakerStore = useSpeakersStore()
const { currentSpeaker, currentSpeakerLanguage, speakers, speakersAlreadyFetched } =
  storeToRefs(speakerStore)
const { createSpeaker, fetchAllSpeakers, updateSpeaker, deleteSpeaker, resetCurrentSpeaker } =
  speakerStore
const assetStore = useAssetsStore()
const { deleteAsset } = assetStore

/****************************************
 * LIFECYCLE HOOKS
 *****************************************/
onMounted(async () => {
  await fetchAllSpeakers()
  if (sortingSpeakers.value) {
    autoAnimate(sortingSpeakers.value) // animate sorting rows
  }
})

/****************************************
 * COMPOSABLES
 *****************************************/
// Unsaved changes composable
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  forgetUnsavedChanges
} = useUnsavedChanges()

// Image crop composable
const { imageUploadMessage, showImageUploadMessage, croppedImageFile, imageCropped } =
  useImageCrop()

/****************************************
 * REFS
 *****************************************/
const searchInput = ref<string>('') // Search input for filtering speakers
const sortingSpeakers = ref(null) // Ref to the speakers list element to animate sorting rows
const isSpeakerModalOpen = ref<boolean>(false) // Tracks if the speaker modal is open
const isConfirmDeleteModalOpen = ref<boolean>(false) // Tracks if the confirmation modal is open
const isNewSpeaker = ref<boolean>(true) // Flag to determine if the modal is for a new or existing speaker
let originalSpeakerState = ref<Speaker | null>(null) // Stores a copy of the original speaker data for comparison
const speakerImageCropperRef = ref<any>(null) // Ref to access the image cropper component methods
const imageTemporaryDeleted = ref(false) // Flag to track if the image was temporarily deleted
const showRemoveTemporaryImageButton = ref(false) // Flag to show 'X' button to remove the temporary image
const speakerHadAlreadyAnImage = ref(false) // Flag to track if the speaker already had an image before the modal was opened
const imageHasUnsavedChanges = ref(false) // Flag to track if the image has unsaved changes

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
// Computed property to filter the speakers based on the search input
const speakersFiltered = computed(() => {
  return getFilteredSpeakers(speakers.value, searchInput.value)
})

/****************************************
 * METHODS
 *****************************************/
/**
 * Updates the search input value.
 * @param input - The search query.
 */
function filterSpeakers(input: string) {
  searchInput.value = input
}

/**
 * Compares the original speaker state with the current speaker state and updates the hasUnsavedChanges flag
 */
function checkIfHasUnsavedChanges() {
  // Compare old and new speaker state
  const differences = findDifferences(originalSpeakerState.value, currentSpeaker.value)
  // Return whether there are unsaved changes (speaker or image)
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

/**
 * Opens the speaker modal for creating or editing a speaker.
 * If the speaker data exists, it's set for editing, otherwise the modal is opened for creating a new speaker.
 * @param data - The speaker object to edit or `null` for creating a new one.
 */
function openSpeakerModal(data: Speaker | null) {
  // Find the speaker in the 'speakers' array
  const foundSpeaker =
    data && speakers.value.find((speaker) => String(speaker.id) === String(data.id))

  if (foundSpeaker) {
    isNewSpeaker.value = false
    currentSpeaker.value = foundSpeaker
  } else {
    isNewSpeaker.value = true
  }

  isSpeakerModalOpen.value = true

  if (originalSpeakerState.value === null) {
    // Save a deep copy of the original speaker template state
    originalSpeakerState.value = cloneable.deepCopy(currentSpeaker.value)
  }

  // Check if the speaker had already an image
  speakerHadAlreadyAnImage.value = !!currentSpeaker.value.images?.length
}

/**
 * Closes the speaker modal and checks if there are any unsaved changes.
 * If there are unsaved changes, it will prompt a modal to confirm navigation.
 */
async function closeSpeakerModal() {
  if (originalSpeakerState.value !== null) {
    checkIfHasUnsavedChanges()
  }

  if (forgetUnsavedChanges.value) {
    showImageUploadMessage.value = false
    imageHasUnsavedChanges.value = false
    hasUnsavedChanges.value = false
    originalSpeakerState.value = null
    resetCurrentSpeaker()
    await fetchAllSpeakers()
    isSpeakerModalOpen.value = false
  } else {
    if (hasUnsavedChanges.value) {
      triggerUnsavedChangesModal(null)
    } else {
      isSpeakerModalOpen.value = false
      forgetUnsavedChanges.value = true
      resetCurrentSpeaker()
      originalSpeakerState.value = null
    }
  }
}

/**
 * Opens a confirmation modal to confirm deleting a speaker.
 * @param data - The speaker to be deleted.
 */
function openConfirmationModal(data: Speaker) {
  // Find the speaker in the 'speakers' array
  const foundSpeaker = speakers.value.find((speaker) => String(speaker.id) === String(data.id))

  if (foundSpeaker) {
    currentSpeaker.value = foundSpeaker
  } else {
    toast.error(t('views.speakers.index.noSpeakerFound'))
    return
  }

  // as currentSpeaker is always undefined when any modal is opened, we prevent the watch function from triggering by setting forgetUnsavedChanges to true
  forgetUnsavedChanges.value = true
  isConfirmDeleteModalOpen.value = true
}

/**
 * Closes the confirmation modal.
 */
function closeConfirmationModal() {
  isConfirmDeleteModalOpen.value = false
}

/**
 * Function to confirm and handle unsaved changes when a modal is open.
 * This function resets various unsaved change states and refetches the speakers list.
 */
async function confirmUnsavedChangesModal() {
  confirmNavigation() // Confirm navigation and reset unsaved changes related to the image and other form data
  imageHasUnsavedChanges.value = false // Reset the unsaved changes related to the speaker's image
  imageTemporaryDeleted.value = false // Reset the temporary deletion state of the image
  originalSpeakerState.value = null // Clear the original speaker state, since it's no longer needed after confirmation
  resetCurrentSpeaker() // Reset the current speaker state
  await fetchAllSpeakers() // Refetch all speakers to ensure the list is up to date after confirming the changes
}

/**
 * Cancels the unsaved changes and restores the original speaker state.
 * This function reverts any modifications made to the speaker and closes the unsaved changes modal.
 */
async function cancelUnsavedChangesModal() {
  // If the speaker is not new (his/her data is not saved yet), then restore the originalSpeakerState
  if (!isNewSpeaker.value) {
    currentSpeaker.value = cloneable.deepCopy(originalSpeakerState.value as Speaker) // Restore the original speaker state by creating a deep copy of the original speaker object
  }

  isUnsavedChangesModalOpen.value = false // Close the unsaved changes modal
  openSpeakerModal(currentSpeaker.value) // Re-open the speaker modal with the restored original speaker data
}

/**
 * Updates the image upload message to 'uploaded' and updates the state to track if there are unsaved changes in the image.
 */
function imagePreviewUploaded() {
  imageUploadMessage.value = 'uploaded'
  showRemoveTemporaryImageButton.value = true

  imageHasUnsavedChanges.value =
    !speakerHadAlreadyAnImage.value ||
    (speakerHadAlreadyAnImage.value && imageTemporaryDeleted.value)

  checkIfHasUnsavedChanges()
}

/**
 * Handles the logic to temporarily delete the image.
 * This function marks the image as temporarily deleted, hides the remove button,
 * resets the image cropper, and updates the unsaved changes status.
 */
async function handleDeleteImageTemporary() {
  imageTemporaryDeleted.value = true // Mark the image as temporarily deleted
  showRemoveTemporaryImageButton.value = false // Hide the button for removing the temporary image
  speakerImageCropperRef.value?.reset() // Reset the image cropper component, if available
  imageHasUnsavedChanges.value = speakerHadAlreadyAnImage.value // Update the imageHasUnsavedChanges flag based on whether the speaker had an image before
  checkIfHasUnsavedChanges() // Update the hasUnsavedChanges flag based on the current state
}

/**
 * Handles the logic for creating or updating a speaker.
 * If the speaker is new, it will be created, otherwise the existing speaker will be updated.
 */
async function handleSpeakerAction() {
  // Check if the image cropper is present and crop the image if necessary
  if (
    (currentSpeaker.value.images!.length === 0 && speakerImageCropperRef.value) ||
    imageTemporaryDeleted.value
  ) {
    await speakerImageCropperRef.value.cropImage()
  }

  if (isNewSpeaker.value) {
    await createSpeaker(currentSpeaker.value as CreateSpeakerDto, croppedImageFile.value!).then(
      (response) => {
        if (response) {
          croppedImageFile.value = null
          forgetUnsavedChanges.value = true
          closeSpeakerModal()
          showImageUploadMessage.value = false
          toast.success(t('views.speakers.index.speakerCreated'))
        }
      }
    )
  } else {
    if (imageTemporaryDeleted.value && currentSpeaker.value.images[0]) {
      await deleteAsset(currentSpeaker.value.images![0].id)
      // Reset the images array
      currentSpeaker.value.images = []
    }

    await updateSpeaker(currentSpeaker.value as UpdateSpeakerDto, croppedImageFile.value!).then(
      (response) => {
        if (response) {
          if (imageTemporaryDeleted.value) {
            // Reset the image temporary deleted state
            imageTemporaryDeleted.value = false
          }
          forgetUnsavedChanges.value = true
          closeSpeakerModal()
          toast.success(t('views.speakers.index.speakerUpdated'))
        }
      }
    )
  }

  if (croppedImageFile.value) {
    // Reset the image upload message and cropped image file
    croppedImageFile.value = null
    showImageUploadMessage.value = false
  }

  // reset the unsaved changes
  hasUnsavedChanges.value = false
  imageHasUnsavedChanges.value = false
  isUnsavedChangesModalOpen.value = false
  speakerHadAlreadyAnImage.value = !!currentSpeaker.value.images?.length
}

/**
 * Handles the logic to delete a speaker.
 */
async function handleDeleteSpeaker() {
  await deleteSpeaker(currentSpeaker.value.id!)

  forgetUnsavedChanges.value = true
  closeConfirmationModal()

  toast.success(t('views.speakers.index.speakerDeleted'))
}

/**
 * Handles changing the speaker language when toggled.
 * This function updates the currentSpeakerLanguage with the selected language value.
 *
 * @param value - The new language value ('de' or 'en'). This value is cast to ensure
 *                it matches one of the allowed language options.
 */
function handleSpeakerLanguageChange(value: string) {
  currentSpeakerLanguage.value = value as 'de' | 'en'
}

/****************************************
 * WATCHERS
 *****************************************/
// Watch for changes in the `currentSpeaker` object. When `currentSpeaker` changes,
// compare the new state with the original speaker state to determine if there are any
// differences. If differences are found or if there are unsaved changes to the image,
// mark the form as having unsaved changes. If `forgetUnsavedChanges` is true or
// `originalSpeakerState` is not set, the watcher will skip the comparison and reset
// `forgetUnsavedChanges` after the check.
watch(
  currentSpeaker,
  (newCurrentSpeaker) => {
    // Skip comparison if we are supposed to forget unsaved changes or if the original state is not available
    if (forgetUnsavedChanges.value || !originalSpeakerState.value) {
      forgetUnsavedChanges.value = false // Always reset after the check
      return
    }

    // Update unsaved changes if a new speaker is set
    if (newCurrentSpeaker) {
      checkIfHasUnsavedChanges()
    }

    // Reset forgetUnsavedChanges after comparison
    forgetUnsavedChanges.value = false
  },
  { deep: true } // Deep watch to ensure all nested properties are observed
)
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.speakers') }}
        </h1>
        <VButton
          type="button"
          appearance="default"
          :label="t('views.speakers.index.newSpeaker')"
          size="large"
          :functionOnClick="async () => openSpeakerModal(null)"
        >
          <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
        </VButton>
      </div>
      <VSearch
        v-model="searchInput"
        :input-id="'input-regular'"
        :label="t('views.speakers.index.searchSpeakers')"
        :button-label="'Search'"
        :placeholder="t('global.search')"
        @input="filterSpeakers(searchInput)"
        class="mb-5"
      />
      <hr class="mb-5" />
      <div
        v-if="speakersAlreadyFetched"
        class="h-[calc(100svh-18.5rem)] overflow-y-scroll rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
      >
        <div v-if="speakers.length === 0">
          <p class="mb-4">{{ t('views.speakers.index.noSpeakersAvailable') }}</p>
        </div>
        <div v-else>
          <ul v-if="speakersFiltered.length > 0" class="flex flex-col gap-4" ref="sortingSpeakers">
            <li
              v-for="speaker in arraySortedByStringProperty(speakersFiltered, 'lastName')"
              :key="`notSpeaker-${speaker.firstName}-${speaker.lastName}`"
            >
              <VSpeakerAvatar
                :type="'edit'"
                :input-id="String(speaker.id)"
                :title="`${speaker.firstName} ${speaker.lastName}`"
                :subtitle="speaker.company"
                :description="speaker.vita"
                :modelValue="true"
              >
                <template #image>
                  <CloudImage
                    v-if="speaker.images?.length"
                    :imageName="speaker.images?.[0]?.['public_id']"
                    class="h-full w-full object-cover"
                    :alt="`${speaker.firstName} ${speaker.lastName}`"
                  />

                  <img
                    v-else
                    :src="anonymousUserImage"
                    :alt="`${speaker.firstName} ${speaker.lastName}`"
                    class="h-full w-full object-cover"
                  />
                </template>
                <VButton
                  type="button"
                  appearance="empty"
                  size="medium"
                  :functionOnClick="async () => openSpeakerModal(speaker)"
                >
                  <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
                </VButton>
                <VButton
                  type="button"
                  appearance="empty"
                  size="medium"
                  :functionOnClick="async () => openConfirmationModal(speaker)"
                >
                  <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
                </VButton>
              </VSpeakerAvatar>
            </li>
          </ul>
          <div v-else>
            <p class="mb-4">{{ t('views.speakers.index.noSpeakersFound') }}</p>
          </div>
        </div>
      </div>
    </section>
    <template #modal>
      <!-- Edit speaker modal -->
      <VModal
        :trigger="isSpeakerModalOpen"
        @update:trigger="isSpeakerModalOpen = $event"
        includeForm
        :function-on-close="closeSpeakerModal"
      >
        <template #modalHeader>
          <p
            v-if="currentSpeaker.id"
            class="text-center text-xl uppercase text-dark-grey dark:text-light-grey"
          >
            {{ t('views.speakers.index.editSpeaker') }}
          </p>
          <p v-else class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.speakers.index.createSpeaker') }}
          </p>
        </template>
        <template #modalBody>
          <VInput
            v-model="currentSpeaker.firstName"
            type="text"
            :input-id="`global-input-first-name-${currentSpeaker.id}`"
            :label="t('global.firstName')"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            :placeholder="t('global.firstName')"
            class="mb-5"
          />
          <VInput
            v-model="currentSpeaker.lastName"
            type="text"
            :input-id="`global-input-last-name-${currentSpeaker.id}`"
            :label="t('global.lastName')"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            :placeholder="t('global.lastName')"
            class="mb-5"
          />
          <VInput
            v-model="currentSpeaker.company"
            type="text"
            :input-id="`global-input-company-${currentSpeaker.id}`"
            :label="t('global.company')"
            :placeholder="t('global.company')"
            class="mb-5"
          />
          <VTextarea
            v-model="currentSpeaker.position"
            :input-id="`global-input-position-${currentSpeaker.id}`"
            :label="t('global.position')"
            :placeholder="t('global.position')"
            class="mb-5"
          />
          <div class="relative my-5">
            <VToggleTwoOptions
              v-model="currentSpeakerLanguage"
              input-id="currentEventLanguage"
              leftOptionValue="de"
              rightOptionValue="en"
              @change="handleSpeakerLanguageChange"
              class="absolute -top-2 right-0"
            />
            <VTextarea
              v-model="currentSpeaker.vita[currentSpeakerLanguage]"
              :input-id="`global-input-vita-${currentSpeaker.id}`"
              :label="t('global.vita')"
              :placeholder="t('global.vita')"
              class="mb-5"
            />
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex items-end justify-between">
              <label
                class="flex flex-row items-center gap-2 text-base font-bold text-dark-grey dark:text-light-grey"
              >
                <span>
                  {{ t('views.speakers.index.image') }}
                </span>
                <span class="group relative">
                  <VTooltip
                    :modelValue="t('global.imageDeletedAfterSave')"
                    :position="'center'"
                    :className="'-top-5 text-sm'"
                  />
                  <FontAwesomeIcon :icon="['fal', 'circle-info']" />
                </span>
              </label>
              <VButton
                v-if="imageUploadMessage === 'uploaded' && showRemoveTemporaryImageButton"
                type="button"
                appearance="empty"
                :function-on-click="handleDeleteImageTemporary"
              >
                <FontAwesomeIcon :icon="['fal', 'xmark']" class="size-5" />
              </VButton>
            </div>
            <div
              v-if="!imageTemporaryDeleted && currentSpeaker.images?.[0]?.public_id"
              class="flex flex-col gap-4"
            >
              <div class="flex flex-row items-start gap-4">
                <CloudImage
                  :imageName="currentSpeaker.images[0].public_id"
                  class="h-20 w-20 rounded-full object-cover"
                />
                <VButton
                  type="button"
                  appearance="empty"
                  size="small"
                  :function-on-click="handleDeleteImageTemporary"
                >
                  <FontAwesomeIcon
                    :icon="['fal', 'trash-can']"
                    class="size-5 p-1.5 [&_path]:fill-dark-grey [&_path]:dark:fill-light-grey"
                  />
                </VButton>
              </div>
              <p class="text-xs">{{ getImageName(currentSpeaker.images[0].public_id) }}</p>
            </div>
            <div v-else class="flex flex-col">
              <p
                v-if="showImageUploadMessage"
                class="text-sm font-bold"
                :class="{
                  'text-dark-yellow dark:text-light-yellow': imageUploadMessage === 'uploading',
                  'text-dark-green dark:text-light-green': imageUploadMessage !== 'uploading'
                }"
              >
                {{
                  imageUploadMessage === 'uploading'
                    ? t('views.assets.assetBeingUploaded')
                    : t('views.assets.assetSuccessfullyUploaded')
                }}
              </p>
              <div v-else class="flex w-full flex-row items-start gap-2">
                <ImageCropper
                  ref="speakerImageCropperRef"
                  cropForm="circle"
                  @imageCropped="imageCropped"
                  @imagePreviewUploaded="imagePreviewUploaded"
                  :minWidth="120"
                  :minHeight="120"
                />
              </div>
            </div>
          </div>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="reset"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  imageTemporaryDeleted = false
                  forgetUnsavedChanges = true
                  isSpeakerModalOpen = false
                }
              "
            />
            <VButton
              type="submit"
              appearance="default"
              :label="isNewSpeaker ? t('global.save') : t('global.update')"
              size="medium"
              :disabled="!hasUnsavedChanges"
              :functionOnClick="handleSpeakerAction"
            />
          </div>
        </template>
      </VModal>
      <!-- Confirm delete speaker modal -->
      <VModal
        :trigger="isConfirmDeleteModalOpen"
        @update:trigger="isConfirmDeleteModalOpen = $event"
        avoidCloseModalOnOverlay
      >
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ `${currentSpeaker.firstName} ${currentSpeaker.lastName}` }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.speakers.index.confirmDeleteSpeaker') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="() => closeConfirmationModal()"
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="handleDeleteSpeaker"
            />
          </div>
        </template>
      </VModal>
      <!-- Unsaved Changes Modal -->
      <VModal :trigger="isUnsavedChangesModalOpen" avoidCloseModalOnOverlay>
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesTitle') }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesMessage') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.back')"
              size="medium"
              :functionOnClick="cancelUnsavedChangesModal"
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.continue')"
              size="medium"
              :functionOnClick="confirmUnsavedChangesModal"
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
