<script setup lang="ts">
import { VButton, VModal } from '@techcast/histoire'

import { useFavicon, useTitle } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useEventSetup } from '@/composables/event/useEventSetup'
import { useCustomHead } from '@/composables/head/useCustomHead'
import { audienceFormStyleClasses } from '@/composables/registration/formSharedFunctions'
import { cloneable } from '@/composables/useClone'
import router from '@/router'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import { useDesignsStore } from '@/stores/designs.store'
import { previewEvent } from '@/stores/objects/previewEvent'
import { useRegistrationsAndWebformSubmissionsStore } from '@/stores/registrationsAndWebformSubmissions.store'
import type { CreateRegistrationForm } from '@/types/CreateRegistrationForm'
import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { RegistrationFormLogin } from '@/types/RegistrationFormLogin'
import type { components } from '@/types/swagger'
import { camelCase } from '@/utils/camelCase'
import { getFormattedDate } from '@/utils/getFormattedDate'
import {
  generateRandomName,
  isBotDetected,
  isSubmissionTooFast,
  monitorFocusOnElement
} from '@/utils/honeypotFunctions'
import { updateColors } from '@/utils/updateClientFrontendColors'

import { defaultRegistrationForm } from './defaultRegistrationForm'
import AgendaSection from './sections/AgendaSection.vue'
import DescriptionSection from './sections/DescriptionSection.vue'
import DownloadsSection from './sections/DownloadsSection.vue'
import EventMenuBar from './sections/EventMenuBar.vue'
import FooterSection from './sections/FooterSection.vue'
import HeaderBar from './sections/HeaderBar.vue'
import LinksSection from './sections/LinksSection.vue'
import RegistrationSection from './sections/RegistrationSection.vue'
import SpeakersSection from './sections/SpeakersSection.vue'

/****************************************
 * TYPES
 *****************************************/
type Webform = components['schemas']['Webform']

/****************************************
 * API KEY
 *****************************************/
const apiKey = import.meta.env.VITE_PUBLIC_USER_API_KEY

/****************************************
 * ROUTER
 *****************************************/
const route = useRoute()

/****************************************
 * COMPOSABLES
 *****************************************/
const { i18n, t, locale, switchLanguage, eventId, currentEvent, currentEventLanguage, eventStore } =
  useEventSetup()

/****************************************
 * STORES
 *****************************************/
const designStore = useDesignsStore()
const { currentDesign } = storeToRefs(designStore)
const { fetchDesignById, resetCurrentDesign } = designStore

const designTemplateStore = useDesignTemplatesStore()
const { currentDesignTemplate } = storeToRefs(designTemplateStore)

const registrationStore = useRegistrationsAndWebformSubmissionsStore()
const {
  fetchAllRegistrations,
  createRegistration,
  createWebformSubmission,
  addWebformToRegistration
} = registrationStore

/****************************************
 * PROPS
 *****************************************/
const props = withDefaults(
  defineProps<{
    isPreview: boolean
    view?: string
    entityType?: string
  }>(),
  {
    isPreview: false
  }
)

/****************************************
 * CUSTOM HEADERS
 *
 * @deactivated until we have SSR support
 *****************************************/
// useCustomHead({
//   title: computed(() => currentEvent.value?.name?.[currentEventLanguage.value] ?? ''),
//   description: computed(() => currentEvent.value?.description?.[currentEventLanguage.value] ?? ''),
//   imageUrl: computed(() => currentEvent.value?.previewImages?.[0]?.secure_url ?? ''),
//   url: computed(() => `${window.location.origin}${route.fullPath}`)
// })

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
const eventName = computed(() => {
  const name = route.params.eventName || null
  return typeof name === 'string' ? name.replace(/-$/, '') : null // Remove last '-' character if it exists
})
const isPreviewDesign = props.entityType === 'design'
const isPreviewDesignTemplate = props.entityType === 'designTemplate'
const source = computed(() =>
  isPreviewDesignTemplate
    ? {
        ...previewEvent,
        design: { ...currentDesignTemplate.value, logos: currentDesignTemplate.value.logos }
      }
    : { ...currentEvent.value, design: currentDesign.value }
)
const menuItems = computed(() =>
  [
    { key: 'description', condition: source.value.name?.[currentEventLanguage.value] },
    { key: 'registration', condition: source.value.protected },
    { key: 'agenda', condition: (source.value.agendaItems ?? []).length > 0 },
    { key: 'speakers', condition: (source.value.speakers ?? []).length > 0 },
    { key: 'links', condition: (source.value.links ?? []).length > 0 },
    { key: 'downloads', condition: (source.value.downloads ?? []).length > 0 }
  ]
    .filter((item) => item.condition)
    .map((item) => item.key)
)

const translatedEventWebformElements = computed(() => {
  if (!currentEvent.value.webform || !currentEvent.value.webform.elements) {
    return [] // Return an empty array or appropriate default value if webform or elements are undefined
  }
  const translateLocale = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (value.hasOwnProperty(currentEventLanguage.value)) {
        return value[currentEventLanguage.value as keyof typeof value]
      } else {
        return Object.keys(value).reduce((accumulator: Record<string, any>, currentKey) => {
          ;(accumulator as Record<string, any>)[currentKey] = translateLocale(value[currentKey])
          return accumulator
        }, {})
      }
    } else {
      return value
    }
  }

  const translated = currentEvent.value.webform?.elements.map((element) => {
    // remove the config classes from each element, so that they get the classes of the formkit theme
    const { config, ...rest } = element

    // Retrieve the appropriate style configuration based on $formkit
    const formkitType = rest.$formkit
    const styleConfig =
      audienceFormStyleClasses[formkitType as keyof typeof audienceFormStyleClasses] || {}

    // Merge or replace the `classes` object inside config
    const updatedConfig = {
      classes: {
        ...(styleConfig || {}) // Add new styles from styleConfig
      }
    }

    // get the desired translation from each element
    return Object.keys(rest).reduce(
      (accumulator: Record<string, any>, currentKey) => {
        accumulator[currentKey] = translateLocale(rest[currentKey])
        return accumulator
      },
      {
        ...rest,
        config: updatedConfig // Assign the updated config back
      } as Record<string, any>
    )
  })

  // Extract the submit element from the translated elements
  frontendSubmitElement.value = translated?.find((item) => item.$formkit === 'submit')

  // Change the name of the element to its label so that it gets displayed correctly in the webformSubmission
  return (
    translated &&
    translated
      .map((item) => {
        // If item is required, append ' *' to the label or its innerHTML
        if (item.requiredField === true) {
          // Check if the item has __raw__sectionsSchema with a label
          if (item.__raw__sectionsSchema?.label?.attrs?.innerHTML) {
            // Wrap the existing innerHTML in a div and append a span at the end
            item.__raw__sectionsSchema.label.attrs.innerHTML = `
              <div class="relative pl-3">
              <span class="absolute top-0 left-0 text-[var(--button-background-color)]">*</span>
                ${item.__raw__sectionsSchema.label.attrs.innerHTML}
              </div>
            `
          } else if (item.label) {
            // Otherwise append ' *' to the label string
            item.label = `${item.label} *`
          }
        }
        // Change the name of the element to camelCase of its label
        item.name = camelCase(item.label)

        return item
      })
      .filter((item) => item.$formkit !== 'submit')
  ) // Removing the element with "$formkit": "submit"
})

const translatedDefaultFormElements = computed(() => {
  const translateLocale = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (value.hasOwnProperty(currentEventLanguage.value)) {
        return value[currentEventLanguage.value as keyof typeof value]
      } else {
        return Object.keys(value).reduce(
          (accumulator, currentKey) => {
            ;(accumulator as Record<string, any>)[currentKey] = translateLocale(value[currentKey])
            return accumulator
          },
          {} as Record<string, any>
        )
      }
    } else {
      return value
    }
  }

  const translated = defaultRegistrationForm.map((element: Record<string, any>) => {
    // Get the desired translation from each element
    const translatedElement = Object.keys(element).reduce(
      (accumulator, currentKey) => {
        ;(accumulator as Record<string, any>)[currentKey] = translateLocale(element[currentKey])
        return accumulator
      },
      {} as Record<string, any>
    )

    // Retrieve the appropriate style configuration based on $formkit
    const formkitType = translatedElement.$formkit
    const styleConfig =
      audienceFormStyleClasses[formkitType as keyof typeof audienceFormStyleClasses] || {}

    // Add or update the `config` property with `classes` styles
    translatedElement.config = {
      classes: {
        ...styleConfig // Add new styles from styleConfig
      }
    }

    return translatedElement
  })

  return translated
})

/****************************************
 * REFS
 *****************************************/
const registrationId = ref<number>()
const registrationToken = ref('')
const showEventWebform = ref(false)
const frontendSubmitElement = ref() // The submit button of the webform
const refreshFormkitSchema = ref<number>(0)
const isAlreadyRegisteredModalOpen = ref(false)
const emailAlreadyExists = ref(false)
const alreadyRegisteredEmail = ref<string>('')

/****************************************
 * LIFECYCLE HOOKS
 *****************************************/
onMounted(async () => {
  // Set the favicon and the title of the page
  const icon = useFavicon()
  const title = useTitle()

  if (!apiKey) {
    console.error('API key is missing')
    return
  }

  if (eventId.value && !isPreviewDesignTemplate) {
    /**
     * Redirect to the 404 page if the event is not found
     */
    try {
      /**
       *  Update the currentEvent reference with the fetched event
       */
      await eventStore.fetchEventById(+eventId.value, true, apiKey)

      /**
       *  Check if the event is protected, if it is not, then redirect to 404 page because a login site shouldn't exist for an unprotected event
       */
      if (!currentEvent.value.protected) {
        console.error("Can't find event. Redirecting to 404.")
        return router.push({ name: 'not-found' }) // Redirect to the 404 page
      }
    } catch (error) {
      console.error("Can't find event:", error)
      await router.push({ name: 'not-found' })
    }

    if (currentEvent.value.design?.id) {
      await fetchDesignById(+currentEvent.value.design.id, apiKey)

      if (route.name === 'event-login') {
        // Set the favicon dinamically with the event logo
        icon.value = currentDesign.value.logos?.[0]?.secure_url
        // Set the title of the page with the event name
        title.value = currentEvent.value.name?.[currentEventLanguage.value]
      }
    }
  }

  updateColors(source.value.design.colors as any as DesignColorVariables)

  // Look for the current locale of this event: if the event has a URL with the same name as the event, it means that the event is available in this language
  if (currentEvent.value && currentEvent.value.url) {
    for (const key of Object.keys(currentEvent.value.url) as Array<
      keyof typeof currentEvent.value.url
    >) {
      const eventUrlValue = currentEvent.value.url[key]
      const eventNameValue =
        typeof eventName.value === 'string' ? eventName.value.replace(/-$/, '') : eventName.value
      if (cloneable.isEqual(eventUrlValue, eventNameValue)) {
        // console.log('Event language found: ', key)

        currentEventLanguage.value = key
      }
    }
  }

  // Monitor focus on honeypot fields
  monitorFocusOnElement(honeypotFieldId, detectBotActivity)
  monitorFocusOnElement(extraFieldId, detectBotActivity)
})

onUnmounted(() => {
  resetCurrentDesign()
})

/****************************************
 * HONEYPOT FIELDS
 *****************************************/
const randomizedHoneypotName = generateRandomName()
const randomizedExtraFieldName = generateRandomName()
const honeypotFieldId = `support_${randomizedHoneypotName}`
const extraFieldId = `support_extra_${randomizedExtraFieldName}`
const formLoadTime = ref<number>(Date.now())
// Flags to handle bot detection
const botDetected = ref(false)

// Function to handle detected bot activity
const detectBotActivity = () => {
  botDetected.value = true
  // console.log('Potential bot activity detected!')

  // Prevent form submission if bot detected
  // This can be done by setting a flag or updating form handling logic
  // Example:
  // formSubmissionAllowed.value = false

  // Log the incident to the server
  // logBotDetection()

  // Notify administrators
  // You can implement notification logic here

  // Optionally, display an error message or redirect
  // Example:
  // alert('Suspicious activity detected. Please contact support.')
  // or
  // router.push('/error-page')
}

// TODO: Function to log bot detection (implement your own logging logic)
// const logBotDetection = async () => {
//   try {
//     await fetch('/api/log-bot-detection', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ timestamp: Date.now(), message: 'Bot activity detected' })
//     })
//   } catch (error) {
//     console.error('Failed to log bot detection:', error)
//   }
// }

/****************************************
 * METHODS
 *****************************************/
//
/**
 * Toggles the CSS classes of the email input field based on the error state.
 *
 * This function selects the email input field within the client container and
 * applies or removes specific CSS classes to indicate an error state. When the
 * `isError` parameter is `true`, it removes any conflicting classes and adds
 * error-specific classes to the email input field. When `isError` is `false`,
 * it removes the error-specific classes and restores the original classes.
 *
 * @param {boolean} isError - A boolean indicating whether to apply error state classes.
 */
function toggleEmailInputClasses(isError: boolean) {
  const emailInput = document.querySelector('.client-container .formkit-inner input[type="email"]')
  if (emailInput) {
    if (isError) {
      // Remove conflicting classes and apply error state classes
      emailInput.classList.remove('border-none', 'border-0')
      emailInput.classList.add(
        'text-dark-red',
        'dark:text-light-red',
        'border',
        'border-dark-red',
        'dark:border-light-red'
      )
    } else {
      // Remove error state classes and restore original classes
      emailInput.classList.remove(
        'text-dark-red',
        'dark:text-light-red',
        'border',
        'border-dark-red',
        'dark:border-light-red'
      )
      emailInput.classList.add('border-none', 'border-0')
    }
  }
}

/**
 *
 * @param data - The default form data to be submitted
 */
async function submitDefaultForm(data: RegistrationFormLogin) {
  const eventRegistrations = await fetchAllRegistrations(+eventId.value, apiKey)
  const existingRegistration = eventRegistrations.find(
    (registration) => registration.email === data.email
  )

  if (existingRegistration) {
    // TODO: apply styles to handle the case when the email is already registered
    toggleEmailInputClasses(true)
    emailAlreadyExists.value = true
    alreadyRegisteredEmail.value = data.email
    isAlreadyRegisteredModalOpen.value = true
    return
  }

  const payload = {
    ...data,
    locale: currentEventLanguage.value,
    eventId: +eventId.value
  } as CreateRegistrationForm

  if (
    isBotDetected(data, randomizedHoneypotName, randomizedExtraFieldName) ||
    isSubmissionTooFast(formLoadTime.value)
  ) {
    return
  }

  await createRegistration(+eventId.value, payload, apiKey).then((response) => {
    if (response.token) {
      registrationToken.value = response.token
      registrationId.value = response.id
      showEventWebform.value = true
    }
  })
}

/**
 *
 * @param data - The user webform data to be submitted
 */
async function submitWebform(data: Webform) {
  if (
    isBotDetected(data, randomizedHoneypotName, randomizedExtraFieldName) ||
    isSubmissionTooFast(formLoadTime.value)
  ) {
    return
  }

  // TODO: Input APIKey here
  await createWebformSubmission(+eventId.value, data as any, apiKey).then(
    async (webformSubmissionResponse) => {
      // TODO: Here too
      await addWebformToRegistration(
        +registrationId.value!,
        +webformSubmissionResponse.id,
        apiKey
      ).then((response) => {
        if (response) {
          router.push({
            name: 'event-show',
            params: { id: +eventId.value, token: registrationToken.value }
          })
        }
      })
    }
  )
}

/**
 * Handles sending the registration email link to the already registered email.
 * @param email - The email address to send the registration link to.
 */
async function handleSendRegistrationEmailLink(email: string) {
  toggleEmailInputClasses(false)
  // TODO: API endpoint to send the registration link
  await sendRegistrationEmailLink(email, eventId.value)

  isAlreadyRegisteredModalOpen.value = false
  alreadyRegisteredEmail.value = ''
}

/**
 * Closes the already registered modal.
 */
function closeAlreadyRegisteredModal() {
  toggleEmailInputClasses(false)
  isAlreadyRegisteredModalOpen.value = false
  alreadyRegisteredEmail.value = ''
}

/****************************************
 * WATCHERS
 *****************************************/
/**
 * This is a workaround to refresh the formkit schema when the locale changes
 * Refresh the formkit schema when the language or the webform elements change
 */
watch([currentEventLanguage, translatedEventWebformElements], () => {
  refreshFormkitSchema.value += 1
})
</script>

<template>
  <!-- @container: This class is required by the tailwindcss-container-queries plugin. 
  It marks this element as a container, allowing us to apply different styles 
  based on the size of the container, not just the viewport. 
  You can then use container query variants like @mobile: and @desktop: 
  to style elements inside the container according to its size.
  Breakpoints: https://github.com/tailwindlabs/tailwindcss-container-queries?tab=readme-ov-file#configuration -->
  <div
    v-if="currentEvent"
    :class="[
      'client-container',
      '@container',
      'text-audience-dark-grey',
      'bg-white',
      'flex',
      'flex-col',
      'min-h-screen',
      {
        'preview-wrapper': isPreviewDesign || isPreviewDesignTemplate,
        mobile: view === 'mobile'
      }
    ]"
  >
    <HeaderBar />
    <div
      class="flex h-full w-full grow flex-col 2xl:min-h-[calc(100svh-93px)]"
      :class="isPreview && '@mobile:!min-h-0'"
    >
      <DescriptionSection
        :access="false"
        :source="source"
        :currentEventLanguage="currentEventLanguage"
        :startDate="getFormattedDate(currentEventLanguage, source.startDate ?? '', false, true)"
        :startHour="getFormattedDate(currentEventLanguage, source.startDate ?? '', true, false)"
        :endHour="getFormattedDate(currentEventLanguage, source.endDate ?? '', true, false)"
        :previewImage="source.previewImages?.[0]"
        :logo="source.design?.logos?.[0]"
        :format="source.design?.format"
        :entityType="entityType"
        :view="view"
        :isPreview="isPreview"
      />
    </div>
    <EventMenuBar
      v-if="menuItems.length > 1"
      :access="false"
      :menuItems="menuItems"
      :entityType="entityType"
      :view="view"
      :class="isPreview && '@mobile:mt-5'"
    />
    <RegistrationSection
      v-if="source?.protected"
      :access="false"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :showEventWebform="showEventWebform"
      :translatedDefaultFormElements="translatedDefaultFormElements"
      :translatedEventWebformElements="translatedEventWebformElements"
      :frontendSubmitElement="frontendSubmitElement"
      :randomizedHoneypotName="randomizedHoneypotName"
      :randomizedExtraFieldName="randomizedExtraFieldName"
      :honeypotFieldId="honeypotFieldId"
      :extraFieldId="extraFieldId"
      @submitDefault="submitDefaultForm"
      @submitWebform="submitWebform"
      :refreshFormkitSchema="refreshFormkitSchema"
      :format="source.design?.format"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
    />
    <AgendaSection
      v-if="source?.agendaItems && source?.agendaItems.length > 0"
      :access="false"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :agendaItems="source?.agendaItems ?? []"
      :speakers="source?.speakers ?? []"
      :format="source.design?.format"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
    />
    <SpeakersSection
      v-if="source?.speakers && source?.speakers.length > 0"
      :access="false"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :speakers="source?.speakers ?? []"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
    />
    <LinksSection
      v-if="source?.links && source?.links.length > 0"
      :access="false"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :links="source?.links ?? []"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
    />
    <DownloadsSection
      v-if="source?.downloads && source?.downloads.length > 0"
      :access="false"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :downloads="source?.downloads ?? []"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
    />
    <FooterSection :footerData="source.design?.footer" />

    <!-- Email already registered modal -->
    <VModal
      v-model:trigger="isAlreadyRegisteredModalOpen"
      @update:trigger="isAlreadyRegisteredModalOpen = $event"
      avoidCloseModalOnOverlay
    >
      <template #modalHeader>
        <div class="flex flex-col text-center uppercase">
          <p class="text-lg text-dark-grey dark:text-white">
            {{ t('views.events.show.emailAlreadyRegistered') }}
          </p>
        </div>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.show.confirmSendRegistrationLink') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="() => closeAlreadyRegisteredModal()"
          />
          <VButton
            appearance="default"
            :label="t('global.send')"
            size="medium"
            :functionOnClick="
              () => {
                handleSendRegistrationEmailLink(alreadyRegisteredEmail)
              }
            "
          />
        </div>
      </template>
    </VModal>
  </div>
</template>
