import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { cloneable } from '@/composables/useClone'
import { useUrlToFile } from '@/composables/useUrlToFile'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import { emptyDesignTemplate } from '@/stores/objects/emptyDesignTemplate'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Design = components['schemas']['Design']
type DesignPreview = components['schemas']['DesignPreview']
type CreateDesignDto = components['schemas']['CreateDesignDto']
type CreateDesignPreviewDto = components['schemas']['CreateDesignPreviewDto']
type UpdateDesignDto = components['schemas']['UpdateDesignDto']
type UpdateDesignPreviewDto = components['schemas']['UpdateDesignPreviewDto']
type Asset = components['schemas']['Asset']

export const useDesignsStore = defineStore('designs', () => {
  /**
   * ---------- Stores ----------
   */
  const designTemplateStore = useDesignTemplatesStore()
  const { resetCurrentDesignTemplate } = designTemplateStore

  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const currentDesign = ref<Design>(structuredClone(emptyDesignTemplate) as Design)
  const previewDesign = ref<Design>(structuredClone(emptyDesignTemplate) as Design)

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Add a design to the current event.
   *
   * @param eventId - The ID of the event.
   * @param design - The design to add.
   * @param logoFile - (Optional) The logo file to upload.
   *
   * @returns The saved design.
   */
  const createDesign = async (
    eventId: number,
    design: CreateDesignDto,
    logoFile?: File
  ): Promise<Design> => {
    const response = await tcFetch('POST', `${url}/events/${eventId}/design`, design)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedCreateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedCreateDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    currentDesign.value = structuredClone(data)

    // Create a preview design for the current design
    await createPreviewDesign(data.id, design as DesignPreview)

    currentDesign.value = structuredClone(data)

    /**
     * As the logoFile comes already converted in object format after selecting a design template,
     * we need to convert it back to a File object before uploading it.
     * Check if the logoFile is not a valid File object and convert it if necessary
     */

    const { urlToFile } = useUrlToFile()

    if (logoFile && !(logoFile instanceof File)) {
      const mimeType = `image/${logoFile.format}`
      logoFile = await urlToFile(
        logoFile.secure_url,
        `${logoFile.public_id}.${logoFile.format}`,
        mimeType
      )
    }

    // If a logoFile is provided, upload it and update the design data
    if (logoFile && logoFile instanceof File) {
      const logo = await uploadLogo(data.id, logoFile)

      // Update the design template object with the logo
      currentDesign.value = {
        ...structuredClone(data),
        logos: logo ? [logo] : []
      }
    }

    return currentDesign.value
  }

  /**
   * Fetch a single design by its ID from the backend.
   *
   * @param designId - The ID of the design to fetch.
   * @param apiKey - (Optional) The API key to use for the request.
   *
   * @returns A promise with the fetched design.
   */
  const fetchDesignById = async (designId: number, apiKey?: string): Promise<Design> => {
    const response = await tcFetch('GET', `${url}/designs/${designId}`, undefined, false, apiKey)

    if (!response.ok) {
      throw new Error(`Failed to fetch designs. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    // Assign the fetched data to the currentDesign object.
    currentDesign.value = structuredClone(data) as Design
    // Assign the fetched data to the previewDesign object.
    previewDesign.value = structuredClone(data) as Design

    return data
  }

  /**
   * Update an existing design.
   *
   * @param designId - The ID of the design to update.
   * @param updateDesignDto - The updated design object.
   * @param logoFile - (Optional) The logo file to upload.
   *
   * @returns A promise with the updated design.
   */
  const updateDesign = async (
    designId: number,
    updateDesignDto: UpdateDesignDto,
    logoFile?: File
  ): Promise<Design> => {
    // Check if the request has an image for this event
    const logo = logoFile ? await uploadLogo(updateDesignDto.id, logoFile) : null

    const response = await tcFetch('PATCH', `${url}/designs/${designId}`, updateDesignDto)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    const updatedLogo = logoFile
      ? [logo]
      : updateDesignDto.logos?.[0] !== undefined
        ? [updateDesignDto.logos?.[0]]
        : []

    await updatePreviewDesign(designId, data as UpdateDesignPreviewDto)

    // Update the design object with the logo
    currentDesign.value = {
      ...structuredClone(data),
      logos: updatedLogo
    }

    return data
  }

  /**
   * Delete an design from the backend.
   * If the request is successful, remove the design from the designs array.
   *
   * @param designId - The ID of the design to delete.
   */
  const deleteDesign = async (designId: number) => {
    const response = await tcFetch('DELETE', `${url}/designs/${designId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedDeleteDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedDeleteDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    resetCurrentDesign()
    resetPreviewDesign()
    resetCurrentDesignTemplate()

    return response
  }

  /**
   * PREVIEW DESIGN
   */

  /**
   *
   * Create a preview design
   *
   * @param designId
   * @param design
   * @param logoFile
   * @returns
   */
  const createPreviewDesign = async (
    designId: number,
    createDesignPreviewDto: CreateDesignPreviewDto,
    logoFile?: File
  ): Promise<DesignPreview> => {
    const payload = cloneable.deepCopy({ ...createDesignPreviewDto, entityType: 'design' })
    // Manually delete id if found in the payload
    if ('id' in payload) {
      delete payload['id']
    }

    const response = await tcFetch('POST', `${url}/designs/${designId}/preview`, payload)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedCreateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedCreateDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    previewDesign.value = structuredClone(data)

    return data
  }

  /**
   *
   * Update a preview design
   *
   * @param designPreviewId
   * @param updateDesignPreviewDto
   * @param logoFile
   * @returns
   */
  const updatePreviewDesign = async (
    designPreviewId: number,
    updateDesignPreviewDto: UpdateDesignPreviewDto
  ): Promise<DesignPreview> => {
    const rawUpdateDesignDto = cloneable.deepCopy(updateDesignPreviewDto)

    const payload = cloneable.deepCopy({
      ...rawUpdateDesignDto,
      id: designPreviewId,
      entityType: 'design'
    })

    if ('designPreview' in payload) {
      delete payload['designPreview']
    }
    if ('event' in payload) {
      delete payload['event']
    }
    if ('eventId' in payload) {
      delete payload['eventId']
    }
    if ('logos' in payload) {
      delete payload['logos']
    }

    const response = await tcFetch('PATCH', `${url}/designs/previews/${designPreviewId}`, payload)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    let data = await response.json()

    // Update the design object with the logo
    previewDesign.value = structuredClone(data)

    return data
  }

  /**
   *
   * Fetch a preview design by id
   *
   * @param designPreviewId
   * @returns
   */
  const fetchPreviewDesignById = async (designPreviewId: number): Promise<DesignPreview> => {
    const response = await tcFetch('GET', `${url}/designs/previews/${designPreviewId}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch designs. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    // Assign the fetched data to the previewDesign object.
    previewDesign.value = structuredClone(data) as Design

    return data
  }

  /**
   * ----- NON CRUD Actions -----
   */

  /**
   * Upload a logo for a design.
   *
   * @param designId - The ID of the design.
   * @param file - The image file to upload.
   *
   * @returns A promise with the uploaded asset.
   */
  const uploadLogo = async (designId: number, file: File): Promise<Asset | undefined> => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await tcFetch('POST', `${url}/designs/${designId}/logos`, formData, true)

    //noinspection Duplicates
    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText.message is an array
      if (Array.isArray(errorText.message)) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.assets.failedUploadAsset')}: ${errorMessage}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.assets.failedUploadAsset')}: ${errorText.message}`)
      }
      return
    }

    const data = await response.json()

    return data
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Reset the currentDesign object to its initial state (emptyDesign).
   * This function can be called when adding a new design.
   */
  const resetCurrentDesign = () => {
    currentDesign.value = structuredClone(emptyDesignTemplate) as Design
  }

  /**
   * Reset the previewDesign object to its initial state (emptyDesign).
   * This function can be called when adding a new design.
   */
  const resetPreviewDesign = () => {
    previewDesign.value = structuredClone(emptyDesignTemplate) as Design
  }

  return {
    currentDesign,
    previewDesign,
    createDesign,
    updateDesign,
    fetchDesignById,
    deleteDesign,
    createPreviewDesign,
    updatePreviewDesign,
    fetchPreviewDesignById,
    resetCurrentDesign,
    resetPreviewDesign
  }
})
