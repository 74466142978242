import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyEvent } from '@/stores/objects/emptyEvent'
import type { Language } from '@/types/Language'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Event = components['schemas']['Event']
type CreateEventDto = components['schemas']['CreateEventDto']
type UpdateEventDto = components['schemas']['UpdateEventDto']
type Asset = components['schemas']['Asset']

export const useEventsStore = defineStore('events', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const events = ref<Event[]>([])
  const prevEvents = ref<Event[]>([])
  const upcEvents = ref<Event[]>([])
  const currentEvent = ref<Event>(structuredClone(emptyEvent) as Event)
  const currentEventLanguage = ref<Language>('de')

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Save an event to the backend.
   *
   * @param createEventDto - The event object to save.
   * @param eventImageFile (optional) - The image file to save.
   *
   * @returns A promise with the saved event.
   */
  const createEvent = async (
    createEventDto: CreateEventDto,
    eventImageFile?: File
  ): Promise<Event> => {
    const response = await tcFetch('POST', `${url}/events`, createEventDto)

    // TODO: Check images

    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.new.failedCreateEvent')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.new.failedCreateEvent')}: ${errorText.message}`)
      }
    }

    const data = await response.json()

    // Check if the request has an image for this event
    const eventImage = eventImageFile ? [await uploadPreviewImage(data.id, eventImageFile)] : []

    // Update the event object with the image (full or empty array if there is not image)
    events.value.push({
      ...structuredClone(data),
      previewImages: eventImage
    })
    currentEvent.value = {
      ...structuredClone(data),
      previewImages: eventImage
    }

    return data
  }

  /**
   * Fetch events from the backend, update the events array and sort it.
   *
   * @returns A promise with the events array.
   */
  const fetchAllEvents = async (): Promise<Event[]> => {
    const response = await tcFetch('GET', `${url}/events`)

    if (!response.ok) {
      throw new Error(`Failed to fetch events. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    events.value = structuredClone(data)

    sortEvents(events.value)

    return data
  }

  /**
   * Fetch a single event by its ID from the backend.
   *
   * @param eventId - The ID of the event to fetch.
   * @param inclRel - Optional parameter to include relationships in the response (default is true).
   * @param apiKey - (Optional) API key to use for the request.
   *
   * @returns A promise with the fetched event.
   */
  const fetchEventById = async (
    eventId: number,
    inclRel = true,
    apiKey?: string
  ): Promise<Event> => {
    const response = await tcFetch(
      'GET',
      `${url}/events/${eventId}?inclRel=${inclRel}`,
      undefined,
      false,
      apiKey
    )

    if (!response.ok) {
      throw new Error(`Failed to fetch event. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    // Update the currentEvent object with the fetched data
    currentEvent.value = structuredClone(data) as Event

    if (!currentEventLanguage.value) {
      currentEventLanguage.value = data.languages[0]
    }

    return data
  }

  /**
   * Update an existing event.
   * If the request is successful, update the event in the events array and sort it.
   *
   * @param updateEventDto - The event object to update.
   * @param eventImageFile (optional) The image file to update.
   *
   * @returns A promise with the updated event.
   */
  const updateEvent = async (updateEventDto: UpdateEventDto, eventImageFile?: File) => {
    // Check if the request has an image for this event
    const eventImage = eventImageFile
      ? await uploadPreviewImage(updateEventDto.id, eventImageFile)
      : null

    const response = await tcFetch('PATCH', `${url}/events/${updateEventDto.id}`, updateEventDto)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.new.failedUpdateEvent')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.new.failedUpdateEvent')}: ${errorText.message}`)
      }
    }

    const data = await response.json()

    // fetchAllEvents must be called so that the array events.value is full with the new data
    await fetchAllEvents()

    const index = events.value.findIndex((event) => String(event.id) === String(updateEventDto.id))

    const updatedEventImage = eventImageFile
      ? [eventImage]
      : currentEvent.value.previewImages[0] !== undefined
        ? [currentEvent.value.previewImages[0]]
        : []

    // If found, update the event object in the array
    if (index !== -1) {
      // Update the event object with the image
      events.value[index] = {
        ...structuredClone(data),
        previewImages: updatedEventImage
      }
      currentEvent.value = {
        ...structuredClone(data),
        previewImages: updatedEventImage
      }
    }

    return data
  }

  /**
   * Delete an event from the backend.
   * If the request is successful, remove the event from the events array.
   *
   * @param eventId - The ID of the event to delete.
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteEvent = async (eventId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/events/${eventId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.new.failedDeleteEvent')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.new.failedDeleteEvent')}: ${errorText.message}`)
      }
    }

    events.value = events.value.filter((event) => String(event.id) !== String(eventId))

    sortEvents(events.value)

    // Reset the currentEvent object
    resetCurrentEvent()

    return response
  }

  /**
   * ----- NON CRUD Actions -----
   */

  /**
   * Upload a preview image for an event.
   *
   * @param eventId - The ID of the event.
   * @param file - The image file to upload.
   *
   * @returns A promise with the uploaded asset.
   */
  const uploadPreviewImage = async (eventId: number, file: File): Promise<Asset | undefined> => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await tcFetch('POST', `${url}/events/${eventId}/images`, formData, true)

    //noinspection Duplicates
    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText.message is an array
      if (Array.isArray(errorText.message)) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.assets.failedUploadAsset')}: ${errorMessage}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.assets.failedUploadAsset')}: ${errorText.message}`)
      }
      return
    }

    return await response.json()
  }

  /**
   * Add a speaker to an event.
   *
   * @param eventId - The ID of the event.
   * @param speakerId - The ID of the speaker.
   *
   * @returns A promise with the updated event.
   */
  const addSpeakerToEvent = async (eventId: number, speakerId: number): Promise<Event> => {
    // TODO: Change this to /events/:eventId/speakers/:speakerId
    const response = await tcFetch('POST', `${url}/events/${eventId}/speakers`, {
      speakerId: speakerId
    })

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.new.failedAddSpeaker')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.new.failedAddSpeaker')}: ${errorText.message}`)
      }
    }

    const data = await response.json()
    const index = events.value.findIndex((event) => String(event.id) === String(eventId))
    events.value[index] = structuredClone(data)
    currentEvent.value = structuredClone(data)

    return data
  }

  /**
   * Remove a speaker from the current event.
   *
   * @param eventId - The ID of the event.
   * @param speakerId - The ID of the speaker.
   *
   * @returns The updated event.
   */
  const removeSpeakerFromEvent = async (eventId: number, speakerId: number): Promise<Event> => {
    const response = await tcFetch('DELETE', `${url}/events/${eventId}/speakers/${speakerId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.new.failedRemoveSpeaker')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.new.failedRemoveSpeaker')}: ${errorText.message}`)
      }
    }

    const data = await response.json()

    const index = events.value.findIndex((event) => String(event.id) === String(eventId))

    events.value[index] = structuredClone(data)

    currentEvent.value = structuredClone(data)

    return data
  }

  const addLiveProjectToEvent = async (eventId: number, streamId: number) => {
    const payload = { projectId3q: streamId }

    const response = await tcFetch(
      'PATCH',
      `${url}/events/${eventId}/manual-assign-project`,
      payload
    )

    if (!response.ok) {
      const errorText = await response.json()
      // TODO: Update error message
      toast.error(`${t('views.events.new.failedRemoveSpeaker')}: ${errorText.message}`)
    }
  }

  const removeLiveProjectFromEvent = async (eventId: number) => {
    const response = await tcFetch(
      'PATCH',
      `${url}/events/${eventId}/unassign-project`,
      undefined,
      true
    )

    if (!response.ok) {
      const errorText = await response.json()
      // TODO: Update error message
      toast.error(`${t('views.events.new.failedRemoveSpeaker')}: ${errorText.message}`)
    }
  }

  // TODO: This is not correct
  const addVodFileToEvent = async (eventId: number, vodFileId: number): Promise<Event> => {
    const payload = { vodFileIds: [vodFileId] }

    const response = await tcFetch(
      'PATCH',
      `${url}/events/${eventId}/vod/${vodFileId}`,
      undefined,
      true
    )

    if (!response.ok) {
      const errorText = await response.json()
      // TODO: Update error message
      toast.error(`${t('views.events.new.failedRemoveSpeaker')}: ${errorText.message}`)
    }

    const data = await response.json()

    const index = events.value.findIndex((e) => e.id === eventId)
    events.value[index] = structuredClone(data)
    currentEvent.value = structuredClone(data)

    return data
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Sort events into previous and upcoming events.
   * Previous events are sorted in descending order by startDate.
   * Upcoming events are sorted in ascending order by startDate.
   *
   * @param events - The events array to sort.
   */
  const sortEvents = (events: Event[]) => {
    // clear prevEvents and upcEvents arrays
    prevEvents.value = []
    upcEvents.value = []

    const now = dayjs()

    events.forEach((event: Event) => {
      const formattedStartDate = new Date(event.endDate)
      if (dayjs(formattedStartDate).isBefore(now)) {
        prevEvents.value.push(event)
      } else {
        upcEvents.value.push(event)
      }
    })

    prevEvents.value.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)))
    upcEvents.value.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)))
  }

  /**
   * Reset the currentEvent object to its initial state (emptyEvent).
   */
  const resetCurrentEvent = () => {
    currentEvent.value = structuredClone(emptyEvent) as Event
  }

  return {
    events,
    prevEvents,
    upcEvents,
    currentEvent,
    currentEventLanguage,
    createEvent,
    fetchAllEvents,
    fetchEventById,
    updateEvent,
    deleteEvent,
    addSpeakerToEvent,
    removeSpeakerFromEvent,
    addLiveProjectToEvent,
    removeLiveProjectFromEvent,
    addVodFileToEvent,
    sortEvents,
    resetCurrentEvent
  }
})
