<script setup lang="ts">
import { AudienceButton } from '@techcast/histoire'

import { FormKit } from '@formkit/vue'

import { useEventSetup } from '@/composables/event/useEventSetup'
import type { FormElement } from '@/types/FormElement'
import type { RegistrationFormLogin } from '@/types/RegistrationFormLogin'

/****************************************
 * PROPS
 *****************************************/
const props = defineProps<{
  access: boolean
  source: any
  currentEventLanguage: string
  showEventWebform: boolean
  translatedDefaultFormElements: Array<FormElement>
  translatedEventWebformElements: Array<FormElement>
  frontendSubmitElement: object
  randomizedHoneypotName: string
  randomizedExtraFieldName: string
  honeypotFieldId: string
  extraFieldId: string
  refreshFormkitSchema: number
  format?: 'rounded' | 'sharp'
  entityType?: string
  view?: string
  isPreview?: boolean
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t } = useEventSetup()

/****************************************
 * METHODS
 *****************************************/
const emit = defineEmits(['submitDefault', 'submitWebform'])

const handleDefaultFormSubmit = (data: RegistrationFormLogin) => {
  if (props.isPreview) {
    return false
  } else {
    emit('submitDefault', data)
  }
}

const handleWebformSubmit = (data: RegistrationFormLogin) => {
  if (props.isPreview) {
    return false
  } else {
    emit('submitWebform', data)
  }
}
</script>

<template>
  <section
    :id="
      source && source.url && entityType === undefined
        ? `${source.url[currentEventLanguage]}-${source.id}-registration`
        : source && source.url && entityType === 'design'
          ? `${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-registration`
          : `${access ? 'access' : 'no-access'}-${view}-template-registration`
    "
    class="container mx-auto mt-5 grid h-full w-full scroll-mt-16 grid-cols-1 gap-10 p-5 md:mt-10
      md:grid-cols-2"
    :class="isPreview && '@mobile:!mt-5 @mobile:!grid-cols-1 @desktop:!grid-cols-2'"
  >
    <div
      class="order-2 flex flex-col gap-10 md:order-1"
      :class="isPreview && '@mobile:!order-2 @desktop:!order-1'"
    >
      <div>
        <div
          v-if="source.description?.[currentEventLanguage]"
          class="leading-7"
          v-html="source.description?.[currentEventLanguage]"
        ></div>
      </div>
      <AudienceButton
        :linkTo="
          source && source.url && entityType === undefined
            ? `#${source.url[currentEventLanguage]}-${source.id}-registration`
            : source && source.url && entityType === 'design'
              ? `#${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-registration`
              : `#${access ? 'access' : 'no-access'}-${view}-template-registration`
        "
        :appearance="format || 'sharp'"
        :label="t('client.signupNow')"
        :className="'text-[var(--button-background-color)] px-0'"
      >
        <template #icon>
          <font-awesome-icon :icon="['fal', 'arrow-right']" />
        </template>
      </AudienceButton>
    </div>
    <div class="w-full md:order-2" :class="isPreview && '@mobile:!order-1 @desktop:!order-2'">
      <div
        v-if="!showEventWebform"
        class="webform-wrapper flex w-full flex-col gap-10 bg-audience-light-grey p-8 lg:p-14"
        :class="{
          'overflow-hidden rounded-3xl': format === 'rounded',
          'rounded-none': format !== 'rounded',
          '@mobile:!p-6 @desktop:!p-8': isPreview
        }"
      >
        <div
          v-if="source?.webform?.introductionText?.[currentEventLanguage]"
          class="leading-7"
          v-html="source?.webform?.introductionText?.[currentEventLanguage]"
        ></div>
        <FormKit
          type="form"
          @submit="handleDefaultFormSubmit"
          :actions="false"
          :incomplete-message="false"
          class="flex gap-12"
          novalidate
        >
          <FormKitSchema :schema="translatedDefaultFormElements" />
          <FormKit
            type="text"
            :name="randomizedHoneypotName"
            :validation="'no-validation'"
            tabindex="-1"
            autocomplete="off"
            outerClass="support-field"
            :id="honeypotFieldId"
          />
          <FormKit
            type="text"
            :name="randomizedExtraFieldName"
            :validation="'no-validation'"
            tabindex="-1"
            autocomplete="off"
            outerClass="support-field"
            :id="extraFieldId"
          />
          <FormKit
            type="submit"
            outerClass="formkit-disabled:opacity-50 mb-5"
            wrapperClass="flex justify-start"
            inputClass="border-0 text-[var(--button-text-color)] bg-[var(--button-background-color)] hover:bg-[var(--button-hover-color)] uppercase text-base mt-4 px-8 py-2 duration-300"
            messagesClass="pt-2"
            messageClass="text-xs text-dark-red"
          >
            <div class="flex items-center">
              <span>{{ t('global.send') }}</span>
            </div>
          </FormKit>
          <span>* {{ t('global.requiredField') }}</span>
        </FormKit>
      </div>
      <div
        v-else-if="showEventWebform && (source.webform?.elements ?? []).length > 0"
        class="w-full bg-light-grey/50 px-16 py-12"
      >
        <FormKit
          type="form"
          @submit="handleWebformSubmit"
          :actions="false"
          :incomplete-message="false"
          novalidate
        >
          <FormKitSchema :key="refreshFormkitSchema" :schema="translatedEventWebformElements" />
          <FormKit
            type="text"
            :name="randomizedHoneypotName"
            :validation="'no-validation'"
            tabindex="-1"
            autocomplete="off"
            outerClass="support-field"
            :id="honeypotFieldId"
          />
          <FormKit
            type="text"
            :name="randomizedExtraFieldName"
            :validation="'no-validation'"
            tabindex="-1"
            autocomplete="off"
            outerClass="support-field"
            :id="extraFieldId"
          />
          <FormKit
            type="submit"
            outerClass="formkit-disabled:opacity-50 mb-5"
            wrapperClass="flex justify-start"
            inputClass="border-0 text-[var(--button-text-color)] bg-[var(--button-background-color)] hover:bg-[var(--button-hover-color)] uppercase text-base mt-4 px-8 py-2 duration-300"
            messagesClass="pt-2"
            messageClass="text-xs text-dark-red"
          >
            <div class="flex items-center">
              <span>{{ frontendSubmitElement?.label }}</span>
            </div>
          </FormKit>
          <span>* {{ t('global.requiredField') }}</span>
        </FormKit>
      </div>
    </div>
  </section>
</template>

<style>
/* class for the honeypot inputs */
.client-container .support-field {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}
/* quill editor elements classes */
/* list items */
.client-container .webform-wrapper ol {
  padding-left: 2rem !important;
  list-style: disc !important;
}
/* border-radius of the inputs inside the formkit if client chooses 'rounded' */
.client-container .webform-wrapper.rounded-3xl .formkit-inner,
.client-container .webform-wrapper.rounded-3xl .formkit-input {
  border-radius: 24px !important;
}
.client-container .webform-wrapper {
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
