<!-- 
Quill 2.0.0 component for vue 3 
https://gist.github.com/dodgydre/d712f9cf596b281614a727ec5bd53eb0 
-->

<!-- TODO: Quill creates always OL instead of UL tags, so I just hid the OL button in the editor, so that the user can only create unordered lists (althought they will be displayed as OL in the html) -->
<!-- TODO: adding size ('normal' or 'large' for example) doesn't apply, the class inside the span is being deleted in the backend before being fetched again -->

<template>
  <div class="relative flex w-full max-h-inherit flex-col gap-4" :class="className">
    <div v-if="props.label" class="group relative w-max">
      <label :for="props.inputId" class="text-base font-bold text-dark-grey dark:text-light-grey">
        {{ props.label }}
      </label>
    </div>
    <div
      :id="props.inputId"
      class="max-h-full overflow-auto quill-editor relative z-50 !rounded border-misty-grey/20 bg-ice-grey
        focus:shadow-[0_0_5px_0_rgba(0,0,0,0.2)] dark:border-misty-grey/30 dark:bg-misty-grey
        dark:focus:shadow-[0_0_5px_0_rgba(255,255,255,0.2)] [&_*]:text-dark-grey [&_*]:dark:text-light-grey
        [&_.ql-container]:bg-ice-grey [&_.ql-container]:dark:bg-misty-grey"
    >
      <slot name="toolbar" />
      <div ref="editor" v-bind="$attrs"></div>
    </div>
    <div v-if="$slots.icon" class="absolute -right-1 -top-1">
      <slot name="icon" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Quill from 'quill'
import Delta from 'quill'
import {
  type EditorChangeHandler,
  type Module,
  QuillOptionsStatic,
  type SelectionChangeHandler,
  type Sources,
  type TextChangeHandler
} from 'quill'
import { type PropType, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

/**
 * @example with all possible events
```vue
	<QuillEditor
    :inputId="'webform-template-description'"
    ref="quillEditorRegistrationTemplate"
		:label="t('views.events.registration.introductionText')"
		v-model:content="currentWebformTemplate.introductionText[locale]"
		:contentType="'html'"
		:placeholder="t('views.events.registration.introductionText')"
		@update:content="
			(content) => {
				console.log('update:content', content)
			}
		"
		@text-change="
			({ delta, oldContent, source }) =>
				console.log('text-change', delta, oldContent, source)
		"
		@selection-change="
			({ range, oldRange, source }) =>
				console.log('selection-change', range, oldRange, source)
		"
		@editor-change="
			(eventName) => console.log('editor-change', `eventName: ${eventName}`)
		"
		@focus="(quill) => console.log('focus', quill)"
		@blur="(quill) => console.log('blur', quill)"
		@ready="(quill) => console.log('ready', quill)"
	/>
```
 *
 */

type ContentPropType = string | Delta | undefined | null

const props = defineProps({
  inputId: {
    type: String,
    required: true
  },
  content: {
    type: [String, Object] as PropType<ContentPropType>,
    default: null
  },
  contentType: {
    type: String as PropType<'delta' | 'html' | 'text'>,
    default: 'delta',
    validator: (value: string) => ['delta', 'html', 'text'].includes(value)
  },
  label: {
    type: String,
    required: false
  },
  enable: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    required: false
  },
  theme: {
    type: String as PropType<'snow' | ''>,
    default: 'snow'
  },
  toolbar: {
    type: [String, Array, Object],
    required: false
  },
  modules: {
    type: Object as PropType<Module | Module[]>,
    required: false
  },
  options: {
    type: Object as PropType<QuillOptionsStatic>,
    required: false
  },
  className: {
    type: String,
    required: false
  },
  globalOptions: {
    type: Object as PropType<QuillOptionsStatic>,
    required: false,
    default: {
      debug: 'warn',
      modules: {
        toolbar: {
          container: [
            'bold',
            'italic',
            'underline',
            { list: 'bullet' },
            // { indent: '-1' },
            // { indent: '+1' },
            // { size: ['normal', 'large'] },
            // { color: [] },
            // { background: [] },
            'clean',
            // 'image',
            'link'
          ],
          handlers: {}
        }
      },
      placeholder: '',
      theme: 'snow'
    }
  }
})

// Define the events emitted by the component
const emit = defineEmits([
  'textChange', // Emitted when the text changes
  'selectionChange', // Emitted when the selection changes
  'editorChange', // Emitted when the editor changes
  'update:content', // Emitted to update the content prop
  'blur', // Emitted when the editor loses focus
  'focus', // Emitted when the editor gains focus
  'ready' // Emitted when the editor is ready
])

let quill: Quill | null = null // Quill instance
let options: QuillOptionsStatic // Options for initializing Quill
const editor = ref<HTMLElement>() // Reference to the editor element
const isEditorFocus = ref<Boolean>(false) // Tracks if the editor is focused
let internalModel: ContentPropType // Internal model to manage content state

onMounted(() => {
  initialize() // Initialize Quill editor
})

onBeforeUnmount(() => {
  quill = null // Clean up Quill instance
})

// Registers a module with Quill if it hasn't been registered yet
const registerModule = (moduleName: string, module: any) => {
  if (Quill?.imports && moduleName in Quill.imports) {
    return // Module is already registered, do nothing
  }
  Quill.register(moduleName, module) // Register the module
}

// Initializes the Quill editor
const initialize = () => {
  if (!editor.value) return
  options = composeOptions() // Compose options for the Quill editor
  // Register user-defined modules
  if (props.modules) {
    if (Array.isArray(props.modules)) {
      for (const module of props.modules) {
        registerModule(`modules/${module.name}`, module.module) // Register each module
      }
    } else {
      registerModule(`modules/${props.modules.name}`, props.modules.module) // Register single module
    }
  }
  // Create new Quill instance
  quill = new Quill(editor.value, options)
  // Set the initial content of the editor
  setContents(props.content)
  // Set up event handlers for Quill
  quill.on('text-change', handleTextChange)
  quill.on('selection-change', handleSelectionChange)
  quill.on('editor-change', handleEditorChange)
  // Remove the 'ql-snow' class if the theme is not 'snow'
  if (props.theme !== 'snow') editor.value.classList.remove('ql-snow')
  // Prevent blur event when clicking on the toolbar
  quill.getModule('toolbar')?.container.addEventListener('mousedown', (e: MouseEvent) => {
    e.preventDefault()
  })
  // Emit ready event
  emit('ready', quill)
}

// Compose options for initializing the Quill editor
const composeOptions = (): QuillOptionsStatic => {
  const clientOptions: QuillOptionsStatic = {}
  if (props.theme !== '') clientOptions.theme = props.theme // Set theme
  if (props.placeholder) clientOptions.placeholder = props.placeholder // Set placeholder
  if (props.toolbar && props.toolbar !== '') {
    clientOptions.modules = {
      toolbar: (() => {
        if (typeof props.toolbar === 'object') {
          return props.toolbar // Return toolbar options if it's an object
        } else if (typeof props.toolbar === 'string') {
          const str = props.toolbar // Return toolbar options if it's a string
          return str
        }
        return
      })()
    }
  }
  // Handle custom modules
  if (props.modules) {
    const modules = (() => {
      const modulesOption = {}
      if (Array.isArray(props.modules)) {
        for (const module of props.modules) {
          modulesOption[module.name] = module.options ?? {} // Set options for each module
        }
      } else {
        modulesOption[props.modules.name] = props.modules.options ?? {} // Set options for single module
      }
      return modulesOption
    })()
    clientOptions.modules = Object.assign({}, clientOptions.modules, modules) // Merge module options
  }

  return Object.assign({}, props.globalOptions, props.options, clientOptions) // Merge all options
}

// Clones a Delta object or returns the original value if not an object
const maybeClone = (delta: ContentPropType) => {
  return typeof delta === 'object' && delta ? delta.slice() : delta
}

// Checks if a Delta object contains values other than retain operations
const deltaHasValuesOtherThanRetain = (delta: Delta) => {
  return Object.values(delta.ops).some((v) => !v.retain || Object.keys(v).length !== 1)
}

// Compares the internal model with another value to determine equality
const internalModelEquals = (against: ContentPropType) => {
  if (typeof internalModel === typeof against) {
    if (against === internalModel) {
      return true // Values are strictly equal
    }
    // Ref/Proxy does not support instanceof, so do a loose check
    if (
      typeof against === 'object' &&
      against &&
      typeof internalModel === 'object' &&
      internalModel
    ) {
      return !deltaHasValuesOtherThanRetain(internalModel.diff(against as Delta))
    }
  }
  return false // Values are not equal
}

// Handles text change events from the Quill editor
const handleTextChange: TextChangeHandler = (delta, oldContents, source) => {
  internalModel = maybeClone(getContents() as string | Delta) // Update internal model
  // Emit the update:content event if the model has changed
  if (!internalModelEquals(props.content)) {
    emit('update:content', internalModel)
  }
  emit('textChange', { delta, oldContents, source }) // Emit the textChange event
}

// Handles selection change events from the Quill editor
const handleSelectionChange: SelectionChangeHandler = (range, oldRange, source) => {
  // Set isEditorFocus if quill.hasFocus()
  isEditorFocus.value = !!quill?.hasFocus() // Update focus state
  emit('selectionChange', { range, oldRange, source }) // Emit the selectionChange event
}

// Handles changes in the editor. Emits events based on the type of change (text or selection).
const handleEditorChange: EditorChangeHandler = (...args) => {
  // If the change is a text change, emit an event with the relevant details.
  if (args[0] === 'text-change') {
    emit('editorChange', {
      name: args[0], // Type of change ('text-change')
      delta: args[1], // The delta representing the change
      oldContents: args[2], // Previous content before the change
      source: args[3] // Source of the change (user, API, etc.)
    })
  }
  // If the change is a selection change, emit an event with the relevant details.
  if (args[0] === 'selection-change') {
    emit('editorChange', {
      name: args[0], // Type of change ('selection-change')
      range: args[1], // Current selection range
      oldRange: args[2], // Previous selection range
      source: args[3] // Source of the change (user, API, etc.)
    })
  }
}

// Retrieves the editor's HTMLElement.
const getEditor = (): HTMLElement => {
  return editor.value as HTMLElement // Casts and returns the editor's DOM element.
}

// Retrieves the toolbar's HTMLElement.
const getToolbar = (): HTMLElement => {
  return quill?.getModule('toolbar')?.container // Returns the toolbar module's container.
}

// Retrieves the Quill instance. Throws an error if the editor is not initialized.
const getQuill = (): Quill => {
  if (quill)
    return quill // Returns the Quill instance if available.
  else
    throw `The quill editor hasn't been instantiated yet,
            make sure to call this method when the editor is ready
            or use v-on:ready="onReady(quill)" event instead.` // Error message if Quill is not initialized.
}

// Gets the contents of the editor based on the specified content type.
const getContents = (index?: number, length?: number) => {
  if (props.contentType === 'html') {
    return getHTML() // Retrieves HTML content if the content type is 'html'.
  } else if (props.contentType === 'text') {
    return getText(index, length) // Retrieves text content if the content type is 'text'.
  }
  return quill?.getContents(index, length) // Retrieves Quill contents if none of the above.
}

// Sets the contents of the editor based on the specified content type.
const setContents = (content: ContentPropType, source: Sources = 'api') => {
  // Normalize the content based on its type.
  const normalizedContent = !content ? (props.contentType === 'delta' ? new Delta() : '') : content
  if (props.contentType === 'html') {
    setHTML(normalizedContent as string) // Sets HTML content if the content type is 'html'.
  } else if (props.contentType === 'text') {
    setText(normalizedContent as string, source) // Sets text content if the content type is 'text'.
  } else {
    quill?.setContents(normalizedContent as Delta, source) // Sets Quill contents for other content types.
  }
  internalModel = maybeClone(normalizedContent) // Clone the normalized content for internal state management.
}

// Gets plain text from the editor within specified index and length.
const getText = (index?: number, length?: number): string => {
  return quill?.getText(index, length) ?? '' // Returns plain text or an empty string if Quill is not initialized.
}

// Sets plain text into the editor.
const setText = (text: string, source: Sources = 'api') => {
  quill?.setText(text, source) // Sets the provided text in the editor.
}

// Retrieves the HTML content from the editor.
const getHTML = (): string => {
  return quill?.root.innerHTML ?? '' // Returns the inner HTML of the editor root.
}

// Sets the HTML content of the editor directly.
const setHTML = (html: string) => {
  if (quill) quill.root.innerHTML = html // Sets the inner HTML of the editor root.
}

// Pastes HTML content into the editor.
const pasteHTML = (html: string, source: Sources = 'api') => {
  const delta = quill?.clipboard.convert(html as {}) // Converts the HTML to a Quill Delta format.
  if (delta) quill?.setContents(delta, source) // Sets the converted content in the editor.
}

// Focuses the editor.
const focus = () => {
  quill?.focus() // Triggers focus on the Quill editor.
}

// Reinitializes the editor, removing the toolbar if not using a custom toolbar slot.
const reinit = () => {
  nextTick(() => {
    if (!slots.toolbar && quill) quill.getModule('toolbar')?.container.remove() // Removes the toolbar if no custom slot is provided.
    initialize() // Calls the initialization function to re-initialize the editor.
  })
}

// Watches for changes in the props.content to update the editor's content accordingly.
watch(
  () => props.content,
  (newContent) => {
    if (!quill || !newContent || internalModelEquals(newContent)) return // Exit if Quill is not initialized or content is unchanged.

    // Restore the selection and cursor position after updating the content.
    const selection = quill.getSelection() // Get current selection.
    if (selection) {
      nextTick(() => quill?.setSelection(selection)) // Restore selection after next DOM update.
    }
    setContents(newContent) // Update the contents of the editor with new content.
  },
  { deep: true } // Deep watch to track nested changes.
)

// Watches for changes in the props.enable to enable or disable the editor.
watch(
  () => props.enable,
  (newValue) => {
    if (quill) quill.enable(newValue) // Enable or disable Quill based on newValue.
  }
)

// Watches for the focus state of the editor and emits events accordingly.
watch(isEditorFocus, (focus) => {
  if (focus)
    emit('focus', editor) // Emit focus event if the editor is focused.
  else emit('blur', editor) // Emit blur event if the editor is not focused.
})
</script>

<style>
.quill-editor {
  .ql-formats {
    button {
      &:hover {
        .ql-stroke {
          stroke: #52545c33;
        }
        .ql-fill {
          stroke: #52545c33;
          fill: #52545c33 !important;
        }
      }
    }
    .ql-picker {
      .ql-picker-label,
      .ql-picker-item,
      .ql-selected {
        border-radius: 5px;
        color: #444;
      }
      .ql-picker-item:hover {
        color: #52545c33;
      }
      &:hover {
        .ql-stroke,
        .ql-fill {
          stroke: #52545c33;
        }
      }
    }
    .ql-picker-options {
      border-color: rgb(40 41 45 / var(--tw-bg-opacity));
    }
  }

  .ql-toolbar {
    border-top-left-radius: 0.3125rem 5px;
    border-top-right-radius: 0.3125rem /* 5px */;
  }

  .ql-container {
    border-top: none; /* Remove top border */
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: rgb(82 84 92 / 0.2);
    border-bottom-left-radius: 0.3125rem 5px;
    border-bottom-right-radius: 0.3125rem /* 5px */;
    height: -webkit-fill-available;
    --tw-shadow: none;
    --tw-shadow-colored: none;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);

    &:focus-within {
      --tw-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      --tw-shadow-colored: 0 2px 5px 0 var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
      border-color: #28292d;
    }
  }

  .ql-editor {
    min-height: 100px;

    a {
      color: rgba(0, 0, 0, 0.5);

      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
    ol {
      li {
        list-style: initial;
        &[data-list='bullet'] > .ql-ui:before {
          display: none;
        }
      }
    }
  }

  .ql-tooltip {
    left: 0 !important;
    border: 1px solid #52545c4d !important;
    border-radius: 5px !important;

    .ql-action,
    .ql-remove {
      color: #444 !important;
    }

    .ql-preview {
      color: #000 !important;
    }
  }
}

/* Dark Mode Styles */
html.dark {
  .quill-editor {
    .ql-formats {
      button {
        .ql-stroke,
        .ql-fill,
        .ql-picker-label,
        .ql-picker-options {
          stroke: #fff;
          fill: none;
        }
        &:hover {
          .ql-stroke,
          .ql-fill {
            stroke: #28292d;
          }
        }
      }
      .ql-picker {
        .ql-stroke,
        .ql-fill,
        .ql-picker-label,
        .ql-picker-options {
          stroke: #fff;
        }
        .ql-picker-label,
        .ql-picker-item,
        .ql-selected {
          border-radius: 5px;
          color: #fff;
        }
        .ql-picker-item:hover {
          color: #28292d;
        }
        &:hover {
          .ql-stroke,
          .ql-fill {
            stroke: #28292d;
          }
        }
      }
      .ql-picker-options {
        background-color: rgb(82 84 92 / var(--tw-bg-opacity));
        border-color: rgb(40 41 45 / var(--tw-bg-opacity));
      }
    }

    .ql-toolbar {
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: rgb(40 41 45 / var(--tw-bg-opacity));
    }

    .ql-container {
      border-top: none; /* Remove top border */
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-color: rgb(82 84 92 / 0.3);
      --tw-shadow: none;
      --tw-shadow-colored: none;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
      height: 100%;
      overflow: auto;

      &:focus-within {
        --tw-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.2);
        --tw-shadow-colored: 0 0 5px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
          var(--tw-shadow);
      }

      &:focus-within ~ .ql-toolbar {
        border-bottom-color: white;
      }
    }

    .ql-editor {
      height: 100%;
      overflow: auto;
      a {
        color: rgba(255, 255, 255, 0.5);

        &:hover {
          color: rgb(255, 255, 255);
        }
      }
    }

    .ql-tooltip {
      left: 0 !important;
      border: 1px solid #52545c4d !important;
      border-radius: 5px !important;
      background-color: rgb(82 84 92 / var(--tw-bg-opacity)) !important;
      color: #fff !important;

      .ql-action,
      .ql-remove {
        color: #fff !important;
      }

      .ql-preview {
        color: #ffffffa6 !important;
        & + input {
          color: #28292d;
        }
      }
    }
  }
}
</style>
