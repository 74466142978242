import { useCookies } from '@vueuse/integrations/useCookies'

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH'

/**
 * Fetch wrapper for techcast event manager backend API.
 * If there is no API key provided, the request will include credentials (cookies).
 * With an API key, the request will include the API key in the headers and no cookies.
 *
 * The CSRF token is fetched from the cookies and included in the headers if no API key is provided.
 * With an API Key, the `x-techcast-csrf-protection` header is included in the request.
 *
 * @param method - The HTTP method to use.
 * @param url - The URL to fetch.
 * @param body - (optional) The body to send with the request (defaults to undefined).
 * @param isFile - (optional) Whether the request is for a file (defaults to false).
 * @param apiKey - (optional) The API key to use for the request.
 *
 * @returns The response from the fetch request.
 */
export const tcFetch = async (
  method: HttpMethod,
  url: string,
  body: any = undefined,
  isFile: boolean = false,
  apiKey?: string
) => {
  const cookies = useCookies()
  const csrfToken = cookies.get('csrf_token')

  if (!apiKey && !csrfToken) {
    throw new Error('No CSRF token found in cookies')
  }

  const headers: Record<string, string> = {
    /**
     * Conditionally include the API key and CSRF protection header,
     * or fetch the CSRF token from cookies if no API key is provided.
     */
    ...(apiKey
      ? { 'api-key': apiKey, 'x-techcast-csrf-protection': '1' }
      : { 'x-csrf-token': csrfToken || '' }),
    /**
     * Conditionally set the Content-Type header unless the request is for a file.
     */
    ...(isFile ? {} : { 'Content-Type': 'application/json' })
  }

  const fetchOptions: RequestInit = {
    method,
    headers,
    /**
     * If the request has a file, send the raw file as the body.
     * If it's not a file, send the body as JSON.
     * If there is no body, set it to undefined.
     */
    ...(isFile ? { body } : { body: body ? JSON.stringify(body) : undefined }),
    /**
     * If there is no API key, include credentials (cookies) in the request.
     */
    ...(apiKey ? { credentials: 'omit' } : { credentials: 'include' })
  }

  return await fetch(url, fetchOptions)
}
