<script setup lang="ts">
import { AudienceAvatar } from '@techcast/histoire'

import { useEventSetup } from '@/composables/event/useEventSetup'
import type { components } from '@/types/swagger'

/****************************************
 * TYPES
 *****************************************/
type Speaker = components['schemas']['Speaker']

/****************************************
 * PROPS
 *****************************************/
const props = defineProps<{
  access: boolean
  source: any
  currentEventLanguage: string
  speakers: Array<Speaker>
  entityType?: string
  view?: string
  isPreview?: boolean
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t } = useEventSetup()
</script>

<template>
  <section
    :id="
      source && source.url && entityType === undefined
        ? `${source.url[currentEventLanguage]}-${source.id}-speakers`
        : source && source.url && entityType === 'design'
          ? `${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-speakers`
          : `${access ? 'access' : 'no-access'}-${view}-template-speakers`
    "
    class="container mx-auto mt-5 h-full w-full scroll-mt-16 p-5 md:mt-10"
    :class="isPreview && '@mobile:!mt-2 @desktop:!mt-5'"
  >
    <h3
      class="text-[var(--primary-color)] mb-6 text-2.5xl leading-10 md:mb-12"
      :class="isPreview && '@mobile:!mb-4 @desktop:!mb-8 @desktop:!text-2xl'"
    >
      {{ t('global.speakers') }}
    </h3>
    <div
      class="grid gap-10 sm:grid-cols-2 xl:grid-cols-3"
      :class="isPreview && '@mobile:!grid-cols-1 @desktop:!grid-cols-2 @desktop:gap-4'"
    >
      <div
        v-for="(speaker, index) in speakers"
        :key="`${speaker.firstName} ${speaker.lastName}`"
        class="col-span-1"
        :class="
          isPreview &&
          `@desktop:[&_*_div]:!text-sm @desktop:[&_.rounded-full]:!w-[75px]
          @desktop:[&_.rounded-full]:!h-[75px]`
        "
      >
        <AudienceAvatar
          :type="'complete'"
          :title="`${speaker.firstName} ${speaker.lastName}`"
          :subtitle="speaker.position"
          :additionalInfo="speaker.company"
          :description="speaker.vita[currentEventLanguage]"
          :image="{
            url: speaker.images?.[0]?.secure_url,
            title: `${speaker.firstName} ${speaker.lastName}` || 'Example Image'
          }"
        />
      </div>
    </div>
  </section>
</template>
