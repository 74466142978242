// @ts-ignore
import { messages as rtaMessages } from '@rta2/rta-apps'
import { type WritableComputedRef, nextTick } from 'vue'
import { type Composer, type I18n, createI18n, useI18n } from 'vue-i18n'

export const SUPPORT_LOCALES = ['de', 'en']

/**
 * Setup i18n instance with global properties.
 * This will either create a new i18n instance or return the existing one.
 * vue-i18n has the union type of Composer | I18n, so we need to cast it to the correct type.
 * When initializing in main.ts we need to cast it to I18n, otherwise to Composer.
 * @returns i18n instance with type Composer | I18n
 * @example const i18n = setupI18n() as I18n
 * @example const i18n = setupI18n() as Composer
 */
export function setupI18n() {
  let locale = 'de'

  /**
   * When initializing we don't have access to vue-router so we need to parse
   * the initial locale from the window.location.
   */
  const path = window.location.pathname
  const pathParts = path.split('/')
  if (pathParts.length > 1) {
    const urlLocale = pathParts[1]
    locale = SUPPORT_LOCALES.includes(urlLocale) ? urlLocale : 'de'
  }

  let i18n: Composer | I18n
  try {
    /**
     * Try to use the existing i18n instance.
     */
    i18n = useI18n<false>() as Composer
    setI18nLanguage(i18n, SUPPORT_LOCALES.includes(locale) ? locale : 'de')
  } catch (e) {
    /**
     * useI18n will throw an error if no i18n instance is found
     * create a new i18n instance
     */
    i18n = createI18n<false>({ legacy: false, locale: locale })
    setI18nLanguage(i18n.global as Composer, SUPPORT_LOCALES.includes(locale) ? locale : 'de')
  }

  return i18n
}

/**
 * Set i18n language. This can either be a Composer or I18n instance.
 */
export function setI18nLanguage(i18n: Composer | I18n, locale: string) {
  try {
    ;(i18n as Composer).locale.value = locale
  } catch (e) {
    ;((i18n as I18n).global.locale as WritableComputedRef<string>).value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */

  /**
   * Bind the language to the HTML element.
   */
  const htmlElement = document.querySelector('html')
  if (htmlElement) {
    htmlElement.setAttribute('lang', locale)
  } else {
    console.error('No html element found')
  }
}

/**
 * Load locale messages. This will only load the messages of the current locale.
 */
export async function loadLocaleMessages(i18n: Composer | I18n, locale: string) {
  /**
   * load locale messages with dynamic import
   */
  const messages = await import(`../locales/${locale}.json`)

  /**
   * Merge rta messages with the vue-frontend messages
   */
  const mergedMessages = {
    ...messages.default,
    ...rtaMessages[locale]
  }

  if ('global' in i18n) {
    i18n.global.setLocaleMessage(locale, mergedMessages)
  } else {
    i18n.setLocaleMessage(locale, mergedMessages)
  }

  /**
   * nextTick is used to ensure the messages are loaded before continuing
   */
  return await nextTick()
}
