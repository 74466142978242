import { storeToRefs } from 'pinia'
import { type WritableComputedRef, nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/stores/auth.store'
import { handleLocale } from '@/utils/handleLocale'
import { setupI18n } from '@/utils/i18n'
import pinia from '@/utils/piniaStore'
import DummyView from '@/views/Dummy.vue'
import NotFound from '@/views/NotFound.vue'
import LoginView from '@/views/auth/Login.vue'
import DesignIndexView from '@/views/design/Index.vue'
import DesignNewEditView from '@/views/design/New_Edit.vue'
import EmailIndexView from '@/views/email/Index.vue'
import EmailNewEditView from '@/views/email/New_Edit.vue'
import AgendaView from '@/views/events/Agenda.vue'
import AnalyticsRegistrationsView from '@/views/events/AnalyticsRegistrations.vue'
import EventsDesignView from '@/views/events/Design.vue'
import DownloadsLinks from '@/views/events/DownloadsLinks.vue'
import EventsEmailView from '@/views/events/Email.vue'
import EventsIndexView from '@/views/events/Index.vue'
import EventsInteractionView from '@/views/events/Interactions.vue'
import EventsNewEditView from '@/views/events/New_Edit.vue'
import EventsRegistrationView from '@/views/events/Registration.vue'
import EventSpeakersView from '@/views/events/Speakers.vue'
import Show from '@/views/events/client/Show.vue'
import ShowLogin from '@/views/events/client/ShowLogin.vue'
import LiveView from '@/views/events/video/Live.vue'
import VideoView from '@/views/events/video/VideoOverview.vue'
import VodView from '@/views/events/video/Vod.vue'
import RegistrationIndexView from '@/views/registration/Index.vue'
import RegistrationNewEditView from '@/views/registration/New_Edit.vue'
import SpeakersIndexView from '@/views/speakers/Index.vue'
import StatisticsUsageOverview from '@/views/statistics/Overview.vue'
import UserProfile from '@/views/users/UserProfile.vue'
import UsersManagement from '@/views/users/UsersManagement.vue'

const i18n = setupI18n()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      // redirect to the current locale
      redirect: () => {
        let locale: string

        if ('global' in i18n) {
          locale = (i18n.global.locale as WritableComputedRef<string>).value
        } else {
          locale = i18n.locale.value
        }

        return { name: 'events', params: { locale: locale } }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/logout',
      name: 'logout',
      component: LoginView,
      beforeEnter: async () => {
        const authStore = useAuthStore(pinia)
        await authStore.logout()

        return { name: 'login' }
      }
    },
    {
      path: '/:locale(de|en)',
      name: 'locale',
      // Route guard to handle locale changes and load the currently active messages
      beforeEnter: async (to, from) => {
        await handleLocale(to, from)

        /**
         * Skip auth for public routes.
         */
        const nonAuthRoutes = ['event-show', 'event-login']
        if (to.name && nonAuthRoutes.includes(to.name as string)) {
          return true
        }

        const authStore = useAuthStore(pinia)
        await authStore.initializeStore()
        const { user } = storeToRefs(authStore)

        await nextTick()

        if (!user.value) {
          console.log('Redirecting to login')
          return { name: 'login' }
        }
      },
      // routes are nested behind the locale
      children: [
        // Inspiration: https://stackoverflow.com/questions/47463470/vue-js-dynamic-route-matching-of-slug-with-number
        {
          path: 'events-public/:eventName(.*-)?:id(\\d+)',
          name: 'event-show',
          component: Show,
          beforeEnter: async () => {
            // No callback active right now
          }
        },
        {
          path: 'events-public/:eventName(.*-)?:id(\\d+)/login',
          name: 'event-login',
          component: ShowLogin,
          beforeEnter: async () => {
            // No callback active right now
          }
        },
        {
          path: 'profile',
          name: 'profile',
          component: UserProfile
        },
        {
          path: 'management',
          name: 'management',
          component: UsersManagement,
          beforeEnter: async (to, from) => {
            const authStore = useAuthStore(pinia)
            await authStore.initializeStore()
            const { user } = storeToRefs(authStore)

            if (user.value && user.value.roles.includes('admin')) {
              await nextTick()
            } else {
              return { name: 'root' }
            }
          }
        },
        {
          path: 'statistics-usage',
          name: 'statistics-usage',
          component: StatisticsUsageOverview
        },
        {
          path: 'templates',
          children: [
            {
              path: 'webform',
              name: 'templates-webform',
              component: RegistrationIndexView
            },
            {
              path: 'webform/new',
              name: 'templates-webform-new',
              component: RegistrationNewEditView
            },
            {
              path: 'webform/:id/update',
              name: 'templates-webform-update',
              component: RegistrationNewEditView
            },
            {
              path: 'design',
              name: 'templates-design',
              component: DesignIndexView
            },
            {
              path: 'design/new',
              name: 'templates-design-new',
              component: DesignNewEditView
            },
            {
              path: 'design/:id/update',
              name: 'templates-design-update',
              component: DesignNewEditView
            },
            {
              path: 'email',
              name: 'templates-email',
              component: EmailIndexView
            },
            {
              path: 'email/new',
              name: 'templates-email-new',
              component: EmailNewEditView
            },
            {
              path: 'email/:id/update',
              name: 'templates-email-update',
              component: EmailNewEditView
            }
          ]
        },
        {
          path: 'speakers',
          name: 'speakers',
          component: SpeakersIndexView
        },
        {
          path: 'events',
          children: [
            {
              path: '',
              name: 'events',
              component: EventsIndexView
            },
            {
              path: 'new',
              name: 'event-new',
              component: EventsNewEditView
            },
            {
              path: ':id/update',
              name: 'event-update',
              component: EventsNewEditView
            },
            {
              path: ':id/video',
              name: 'event-video',
              component: VideoView
            },
            {
              path: ':id/video/live',
              name: 'event-video-live',
              component: LiveView
            },
            {
              path: ':id/video/vod',
              name: 'event-video-vod',
              component: VodView
            },
            {
              path: ':id/design',
              name: 'event-design',
              component: EventsDesignView
            },
            {
              path: ':id/interaction',
              name: 'event-interaction',
              component: EventsInteractionView
            },
            {
              path: ':id/downloads-and-links',
              name: 'event-downloads-and-links',
              component: DownloadsLinks
            },
            {
              path: ':id/webform',
              name: 'event-webform',
              component: EventsRegistrationView
            },
            {
              path: ':id/email',
              name: 'event-email',
              component: EventsEmailView
            },
            {
              path: ':id/agenda',
              name: 'event-agenda',
              component: AgendaView
            },
            {
              path: ':id/speakers',
              name: 'event-speakers',
              component: EventSpeakersView
            },
            {
              path: ':id/analytics',
              name: 'event-analytics',
              component: DummyView
            },
            {
              path: ':id/analytics/registrations',
              name: 'event-analytics-registrations',
              component: AnalyticsRegistrationsView
            }
          ]
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound
    }
  ]
})

export default router
