import '@techcast/histoire/dist/styles/style.css'

import { all } from '@awesome.me/kit-878aa3b85a/icons'
import { defaultConfig, plugin } from '@formkit/vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// @ts-ignore
import { RtaInteraction } from '@rta2/rta-apps'
import * as Sentry from '@sentry/vue'
import { createHead } from '@unhead/vue'
import Editor from 'quill'
import 'quill/dist/quill.snow.css'
import { type WritableComputedRef, createApp } from 'vue'
import type { I18n } from 'vue-i18n'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import { loadLocaleMessages, setupI18n } from '@/utils/i18n'
import pinia from '@/utils/piniaStore'

import App from './App.vue'
import './assets/css/main.css'
import './assets/css/scrollbar.css'
import router from './router'

/**
 * FONT AWESOME
 */
// @ts-ignore
library.add(...all)

/**
 * VUE APP
 */
const app = createApp(App)

/**
 * SITE TITLE
 */
document.title = import.meta.env.VITE_SITE_TITLE || 'techcast.event.cloud'

/**
 * HEAD
 */
const head = createHead()
app.use(head)

/**
 * SENTRY
 */
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_MANAGER_FRONTEND_RELEASE,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false, // Maybe we have to set this to true when instances are self managed by the client
      maskAllInputs: true,
      networkDetailAllowUrls: [
        window.location.origin, // Allow all requests to the current origin (especially to /manager-backend)
        /^https:\/\/[a-z0-9.-]+\.3qsdn\.com$/, // match all 3qsdn subdomains
        'https://res.cloudinary.com'
      ]
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      /**
       * Filter Key set in `vite.config.ts`
       * This helps sepparate the errors from the third party scripts
       */
      filterKeys: ['techcast-sentry-key'],
      /**
       * We still see third party errors, but they are tagged with `third-party-frame`
       */
      behaviour: 'apply-tag-if-exclusively-contains-third-party-frames'
    })
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  /**
   * Exclude traces for proxy requests to the 3Q backend
   */
  tracePropagationTargets: [new RegExp(`^${window.location.origin}(?!/3qproxy)`)],
  replaysSessionSampleRate: 1.0, // These values remain at 1 until we run into storage issues on our sentry server
  replaysOnErrorSampleRate: 1.0
  // Not used right now - too annoying
  // Report Modal
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception && event.event_id) {
  //     Sentry.showReportDialog({ eventId: event.event_id })
  //   }
  //   return event
  // }
})

const i18n = setupI18n() as I18n

/**
 * TOAST OPTIONS
 */
const toastOptions = {
  shareAppContext: true,
  position: 'bottom-right',
  timeout: 5000,
  transition: 'Vue-Toastification__fade',
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  draggable: false
}

/**
 * .then syntax is needed here because top level await is not supported
 * TODO: In newer versions of Node, top level await is supported and this can be refactored
 */
loadLocaleMessages(i18n, (i18n.global.locale as WritableComputedRef<string>).value)
  .then(() => {
    app.component('FontAwesomeIcon', FontAwesomeIcon)
    app.component('QuillEditor', Editor)
    app.component('rta-interaction', RtaInteraction)
    app.use(router)
    app.use(pinia)
    app.use(i18n)
    app.use(Toast, toastOptions)
    app.use(plugin, defaultConfig())

    router.isReady().then(() => {
      app.mount('#app')
    })
  })
  .catch((error) => {
    console.error('Failed to load locale messages:', error)
  })
