<template>
  <div id="cropper-wrapper" class="w-full">
    <div v-if="image.src">
      <Cropper
        ref="cropperRef"
        class="cropper mb-4"
        :stencil-component="
          cropForm === 'circle' ? CircleStencil : cropForm === 'rectangle' ? RectangleStencil : null
        "
        :stencil-props="{ aspectRatio: cropForm === 'rectangle' ? 16 / 9 : null }"
        :src="image.src"
      />
    </div>
    <VImageUpload v-else v-model="image.src" @change="uploadImage" inputId="image-upload" />
    <p
      v-if="!image.src && isImageDimensionsError"
      class="text-sm text-dark-red dark:text-light-red"
    >
      {{ t('views.speakers.index.imageSizeError', { minWidth, minHeight }) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { VImageUpload } from '@techcast/histoire'

import { onUnmounted, ref } from 'vue'
import { CircleStencil, Cropper, RectangleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useI18n } from 'vue-i18n'

/**
 * Component: ImageCropper
 *
 * Description:
 * This component provides functionality for cropping images. It displays
 * an image upload interface (VImageUpload component) if no image is currently selected, or a cropping
 * tool if an image is already available. The cropping tool allows users to crop
 * images in either a circular or rectangular shape.
 * https://github.com/advanced-cropper/vue-advanced-cropper
 * The component emits events when an image is preview uploaded or cropped.
 *
 * Image Handling:
 * - When you upload an image using the VImageUpload component, the selected file is represented as a File object
 * - The File object is converted to an object URL using URL.createObjectURL(), allowing you to preview the image in the browser
 * - After cropping the image with the Cropper component, the cropped image is converted to a Blob object. The Blob
 * is then used to create a File object and the image data is temporarily stored in the component’s state (image.src and image object).
 *
 * Props:
 * - cropForm (String): Determines the shape of the crop stencil. Can be 'circle' or 'rectangle'.
 *
 * Events:
 * - imagePreviewUploaded (Boolean): Emitted when an image is successfully uploaded.
 * - imageCropped (File): Emitted with the cropped image file.
 *
 * Example:
 * To use this component and handle image cropping, ensure you have a ref:
 * const imageCropperRef = ref<any>(null)
 *
 * Usage:
 * <ImageCropper
 *   ref="imageCropperRef"
 *   cropForm="free"
 *   @imageCropped="imageCropped"
 *   @imagePreviewUploaded="imagePreviewUploaded"
 * />
 */

const { t } = useI18n()

const props = defineProps<{
  cropForm: string
  minWidth?: number // Minimum width for the uploaded image
  minHeight?: number // Minimum height for the uploaded image
}>()

const cropperRef = ref<any>(null)

const image = ref({
  src: '',
  type: 'image/jpg',
  title: '',
  width: 0,
  height: 0
})

const isImageDimensionsError = ref(false)

const emit = defineEmits(['imagePreviewUploaded', 'imageCropped'])

// Function to crop the image and emit the cropped result
const cropImage = async () => {
  const result = await cropperRef.value?.getResult()

  if (result && result.canvas) {
    const dataUrl = await result.canvas.toDataURL(image.value.type) // Convert the cropped image to a data URL
    const blob = await (await fetch(dataUrl)).blob() // Convert the data URL to a Blob
    const file = new File([blob], image.value.title, { type: blob.type }) // Create a File object from the Blob

    // Emit the cropped image file to the parent component
    emit('imageCropped', file)
  }
}

// Function to handle image file uploads
const uploadImage = (e: any) => {
  const { files } = e.target
  if (files && files[0]) {
    const file = files[0]
    const img = new Image()

    // Reset the error message when the user tries to upload again
    isImageDimensionsError.value = false

    // Create an object URL for the image to check its dimensions
    const blob = URL.createObjectURL(file)
    img.src = blob

    img.onload = () => {
      const { width, height } = img

      // Validate image dimensions against the props
      if (width < (props.minWidth ?? 0) || height < (props.minHeight ?? 0)) {
        // Trigger the modal with the error message
        isImageDimensionsError.value = true
        return
      }

      // Update the image object properties
      image.value.src = blob
      image.value.type = file.type
      image.value.title = file.name
      image.value.width = width
      image.value.height = height

      // Emit event indicating an image preview has been uploaded
      emit('imagePreviewUploaded', { success: true, file })
    }
  }
}

// Function to reset the component state
const reset = () => {
  if (image.value.src) {
    URL.revokeObjectURL(image.value.src) // Revoke the object URL to free up memory
  }
  // Reset image properties
  image.value = { src: '', type: 'image/jpg', title: '', width: 0, height: 0 }
  // Reset the Cropper instance (clear it by setting the ref to null)
  cropperRef.value = null
}

// Expose the cropImage method to be called from the parent
defineExpose({ cropImage, reset })

onUnmounted(() => {
  if (image.value.src) {
    URL.revokeObjectURL(image.value.src) // Cleanup when the component is unmounted
  }
})
</script>

<style>
.vue-advanced-cropper__stretcher {
  max-height: 400px;
}
</style>
