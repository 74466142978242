<script setup lang="ts">
import { VButton, VModal, VSelect, VTooltip } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import QuillEditor from '@/components/utils/QuillEditor.vue'
import { cloneable } from '@/composables/useClone'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEmailTemplatesStore } from '@/stores/emailTemplates.store'
import { useEventsStore } from '@/stores/events.store'

/**
 * Toast notification
 */
const toast = useToast()

/**
 * Translation
 */
const { t, locale } = useI18n()

/****************************************
* STORES
*****************************************/
const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent } = eventStore
const emailTemplatesStore = useEmailTemplatesStore()
const { emailTemplates, currentEmailTemplate } = storeToRefs(emailTemplatesStore)
const { fetchEmailTemplateById } = emailTemplatesStore

const route = useRoute()
const eventId = route.params.id

/**
 * Lyfecycle hooks
 */
onMounted(async () => {
  // console.log('onmounted')
})

/****************************************
* REFS
*****************************************/
const openModalDeleteEmailTemplate = ref(false) // Modal to confirm the deletion of the email
const showForm = ref(false) // Show the form when a email template is selected
// Clone the initial form data to compare against later
let originalEmailState = ref<Email | null>(null)

/****************************************
* COMPUTED VARIABLES
*****************************************/
const emailId = computed(() => currentEvent.value.email?.id as number)

const listOfMultilanguageEmailTemplates = computed(() => {
  return emailTemplates.value.filter((template) => template.isMultilanguage)
})

const listOfSingleLanguageEmailTemplates = computed(() => {
  return emailTemplates.value.filter((template) => !template.isMultilanguage)
})

/****************************************
* METHODS
*****************************************/
// This function will select a template from the list of available templates
async function onSelectTemplate(e: any) {
  const selectedTemplate = await fetchEmailTemplateById(e.value)

  if (selectedTemplate) {
    updateSubjectAndContent(selectedTemplate.subject, selectedTemplate.content)

    originalEmailState.value = cloneable.deepCopy(currentEvent.value.email)
  } else {
    currentEvent.value.email = {} as Email
  }

  showForm.value = true
}

// This function will update the subject and the content of the email when an email template is selected
function updateSubjectAndContent(
  subject: { [key: string]: string },
  content: { [key: string]: string }
) {
  if (currentEvent.value.email) {
    currentEvent.value.email.subject = subject as { [key: string]: string }
    currentEvent.value.email.content = content as { [key: string]: string }
  }
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.email') }}
        </h1>
      </div>
      <div v-if="!emailId && !showForm">
        <div v-if="emailTemplates.length > 0" class="mb-6 flex flex-col gap-1">
          <span class="group relative">
            <VTooltip
              :modelValue="t('views.events.registration.informationToEmailTemplates')"
              :position="'center'"
              :className="'-top-5 text-sm'"
            />
            <FontAwesomeIcon :icon="['fal', 'circle-info']" />
          </span>
          <VSelect
            :label="`${t('views.events.registration.selectTemplate')}:`"
            :placeholder="t('views.events.registration.selectTemplate')"
            @selectOption="onSelectTemplate"
            :options="
              currentEvent.isMultilanguage
                ? listOfMultilanguageEmailTemplates
                : listOfSingleLanguageEmailTemplates
            "
            labelKey="name"
            valueKey="id"
          />
        </div>
        <I18nRouterLink v-else to="/templates/email">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.email.goToEmailManagement')"
            size="medium"
          >
            <FontAwesomeIcon :icon="['fal', 'headset']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <form v-else @submit.prevent class="prose-ul:list-none" novalidate>
        <div
          class="h-[calc(100svh-18rem)] overflow-y-auto rounded-lg bg-white p-10 shadow md:h-[calc(100svh-21.5rem)]
            dark:bg-dark-grey"
        >
          <div class="mb-4 grid grid-cols-1 gap-4 xl:grid-cols-12 xl:gap-8">
            <div class="max-h-80 w-full relative z-10 col-span-1 xl:col-span-8">
              <QuillEditor
                :inputId="'registration-email'"
                ref="quillEditorEmail"
                :label="t('views.templates.email.subject')"
                v-model:content="currentEvent.email"
                contentType="html"
                :placeholder="t('views.events.email.contentPlaceholder')"
              />
            </div>
            <div class="col-span-1 xl:col-span-4">
              <h3 class="mb-4">
                <strong>{{ t('views.templates.email.textBlocks') }}</strong>
              </h3>
              <p class="mb-3 text-xs">
                {{ $t('views.templates.email.placeholderInfo') }}
              </p>
            </div>
            <VButton
              type="button"
              appearance="default"
              :label="t('views.templates.email.sendTestEmail')"
              :disabled="false"
              size="medium"
              :functionOnClick="() => console.log('sendTestEmail')"
            />
          </div>
        </div>
        <div class="relative z-10 flex justify-end gap-8 py-5">
          <!-- TODO: add logic to display 'update'/'delete' or 'save' -->
          <!-- TODO: add logic for unsaved changes (:disabled="!hasUnsavedChanges") -->
          <div v-if="true" class="flex items-center gap-4">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.delete')"
              :disabled="false"
              size="large"
              :functionOnClick="() => (openModalDeleteEmailTemplate = true)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.update')"
              size="large"
              :functionOnClick="() => console.log('update')"
            />
          </div>
          <VButton
            v-if="false"
            type="submit"
            appearance="default"
            :label="t('global.save')"
            size="large"
            :functionOnClick="() => console.log('save')"
          />
        </div>
      </form>
    </section>
    <template #modal>
      <!-- Delete Modal -->
      <VModal
        v-model:trigger="openModalDeleteEmailTemplate"
        :title="t('views.events.email.modalDeleteRegistrationEmail')"
      >
        <template #modalHeader>
          <p>
            <strong>{{ t('views.events.email.modalDeleteRegistrationEmail') }}</strong>
          </p>
        </template>
        <template #modalBody>
          <p>
            {{ t('views.events.email.confirmDeleteRegistrationEmail') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="medium"
              :functionOnClick="() => (openModalDeleteEmailTemplate = false)"
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.yes')"
              :disabled="false"
              size="medium"
              :functionOnClick="() => (openModalDeleteEmailTemplate = false)"
            />
          </div>
        </template>
      </VModal>
    </template>
    <!-- TODO: Unsaved Changes Modal -->
  </MainLayout>
</template>
