<script setup lang="ts">
import {
  VButton,
  VCheckbox,
  VDatepicker,
  VInput,
  VMessage,
  VModal,
  VToggle,
  VToggleTwoOptions,
  VTooltip
} from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave } from 'vue-router'
import { useToast } from 'vue-toastification'

import CloudImage from '@/components/utils/CloudImage.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import ImageCropper from '@/components/utils/ImageCropper.vue'
import QuillEditor from '@/components/utils/QuillEditor.vue'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { useImageCrop } from '@/composables/useImageCropped'
import MainLayout from '@/layouts/MainLayout.vue'
import router from '@/router'
import { useAssetsStore } from '@/stores/assets.store'
import { useEventsStore } from '@/stores/events.store'
import type { Language } from '@/types/Language'
import type { components } from '@/types/swagger'
import { generateSlug } from '@/utils/generateSlug'
import { getImageName } from '@/utils/getImageName'
import { SUPPORT_LOCALES } from '@/utils/i18n'
import { useDarkMode } from '@/utils/useDarkMode'

/****************************************
 * EXTERNE COMPOSABLES
 ****************************************/
const toast = useToast()
const { t, locale } = useI18n()

/****************************************
 * INTERNE COMPOSABLES
 ****************************************/
const { isDarkMode } = useDarkMode()
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()
const { imageUploadMessage, showImageUploadMessage, croppedImageFile, imageCropped } =
  useImageCrop()

/****************************************
 * TYPES
 ****************************************/
type Event = components['schemas']['Event']
type CreateEventDto = components['schemas']['CreateEventDto']
type UpdateEventDto = components['schemas']['UpdateEventDto']

/****************************************
 * STORES
 ****************************************/
const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { createEvent, updateEvent, fetchEventById, resetCurrentEvent } = eventStore
const assetStore = useAssetsStore()
const { deleteAsset } = assetStore

/****************************************
 * REFS
 ****************************************/
const imageCropperRef = ref<any>(null)
const imageCropperCurrentImage = ref<File | null>(null)
const eventHadAlreadyAPreviewImage = ref(false)
const imageHasUnsavedChanges = ref(false)
const imagePreviewWasTemporaryDeleted = ref(false)
const showButtonToRemoveUncroppedImage = ref(false)
const isUrlManuallyEdited = ref<Record<Language, boolean>>({
  de: false,
  en: false
})
const isPageLoaded = ref(false)
/**
 * Clone the initial form data to compare against later
 */
let originalEventState = ref<Event | null>(null)

/****************************************
 * COMPUTED VARIABLES
 ****************************************/
const eventId = computed(() => router.currentRoute.value.params.id as string)

/**
 * Check if all required fields are filled in for the current event language and all supported languages (checked languages)
 * All required fields for the creation of a new event will be included in the requiredFields array
 */
const isFormValid = computed(() => {
  const requiredFields: (keyof typeof currentEvent.value)[] = ['name', 'url']

  if (currentEvent.value.isMultilanguage || areAllLanguagesSelected.value) {
    for (const locale of SUPPORT_LOCALES) {
      for (const field of requiredFields) {
        if (!(currentEvent.value[field] as Record<string, string>)[locale]) {
          return false
        }
      }
    }
    return true
  }
})

/**
 * Check if any language is selected or not to show the validation message
 */
const isAnyLanguageSelected = computed(() => {
  return Object.values(currentEvent.value.languages!).some((checked) => checked)
})

/**
 * Check if all supported languages are selected to show the validation message in multilanguage events
 * which have still empty required fields in any other language
 */
const areAllLanguagesSelected = computed(() => {
  return SUPPORT_LOCALES.every((lang) => currentEvent.value.languages.includes(lang))
})

/****************************************
 * LIFECYCLE HOOKS
 ****************************************/
onMounted(async () => {
  if (eventId && eventId.value) {
    await fetchEventById(+eventId.value)
    /**
     * Set the current event language to the first supported language of the current event
     */
    currentEventLanguage.value = currentEvent.value.languages[0] as Language
  } else {
    /**
     * Set the default language to German when creating a new event
     */
    currentEvent.value.languages = [SUPPORT_LOCALES[0]]
  }

  /**
   * Initialize the manual edit flags based on existing URLs
   * This is used to prevent the URL from being overwritten by the generated slug
   */
  for (let i = 0; i < currentEvent.value.languages.length; i++) {
    const lang = currentEvent.value.languages[i] as Language
    const generatedUrl = generateSlug(currentEvent.value.name[lang])

    isUrlManuallyEdited.value[lang] = !!(
      currentEvent.value.url[lang] && currentEvent.value.url[lang] !== generatedUrl
    )
  }

  /**
   * Save a deep copy of the original event state
   */
  originalEventState.value = cloneable.deepCopy(currentEvent.value)
  /**
   * Check if the event has a preview image
   */
  eventHadAlreadyAPreviewImage.value = !!currentEvent.value.previewImages?.length

  /**
   * Set isPageLoaded to true after the page is fully loaded so that the watcher can start
   */
  isPageLoaded.value = true
})

onUnmounted(() => {
  resetCurrentEvent()
  nextRoute.value = ''
})

/**
 * Check for unsaved changes before navigating away
 */
onBeforeRouteLeave((to, _from, next) => {
  if (hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }
})

/****************************************
 * METHODS
 ****************************************/
/**
 * change the value of currentEventLanguage to the current selected language in the toggle component
 *
 * @param newValue - The new value of the currentEventLanguage
 */
const toggleCurrentEventLanguage = (newValue: Language) => {
  currentEventLanguage.value = newValue
}

/**
 * The primary purpose of the function is to check whether the event should be considered multilingual,
 * based on whether all the required supported languages (2 languages inside the toggle button) are selected.
 */
const checkIfEventIsMultilingual = () => {
  currentEvent.value.isMultilanguage = SUPPORT_LOCALES.every((lang) =>
    currentEvent.value.languages.includes(lang)
  )
}

/**
 * Handles the state updates triggered when an image preview is uploaded via the <ImageCropper> component.
 *
 * When the image preview is uploaded via the <ImageCropper> component, this function will update the image upload message
 * in order to show the preview image as well as it will check if the image has unsaved changes, when?
 *
 * - If the user uploads a new image and no previous image existed
 * - If the user deletes the previously existing image and uploads a new one
 * - If the user discards the uncropped image after uploading it to the <ImageCropper> component
 *
 */
const temporaryImageWasUploaded = (event: { success: boolean; file: File }) => {
  /**
   * Update the current image file with the uploaded file inside the <ImageCropper> component
   */
  if (event.success && event.file) {
    imageCropperCurrentImage.value = event.file
  }

  /**
   * Update the image upload message and show the 'X' button that allows the user to remove the uncropped image (temporary image)
   */
  imageUploadMessage.value = 'uploaded'
  showButtonToRemoveUncroppedImage.value = true

  imageHasUnsavedChanges.value =
    !eventHadAlreadyAPreviewImage.value ||
    (eventHadAlreadyAPreviewImage.value && imagePreviewWasTemporaryDeleted.value)

  /**
   * Compare old and new event state
   */
  const differences = findDifferences(originalEventState.value, currentEvent.value)

  /**
   * Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
   */
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

/**
 * Handles the deletion of the uncropped image (temporary image) and updates relevant state variables.
 *
 * This function is triggered when the user clicks the 'X' button to remove the temporary, uncropped image
 * that was uploaded via the <ImageCropper> component.
 */
const handleDeleteImageTemporary = async () => {
  /**
   * Remove the imageCropperCurrentImage, mark the temporary image as deleted and hide the "Remove Uncropped Image" button
   */
  imageCropperCurrentImage.value = null
  imagePreviewWasTemporaryDeleted.value = true
  showButtonToRemoveUncroppedImage.value = false

  /**
   * Reset the <ImageCropper> component to its initial state
   */
  imageCropperRef.value?.reset()

  /**
   * Updates the `imageHasUnsavedChanges` value to reflect the state of the event's preview image:
   *   - If a preview image existed before, it ensures unsaved changes are reset
   *   - If no preview image existed, this action is considered an unsaved change
   */
  imageHasUnsavedChanges.value = eventHadAlreadyAPreviewImage.value

  /**
   * Compare the original event state to the current event state to check for unsaved changes
   */
  const differences = findDifferences(originalEventState.value, currentEvent.value)

  /**
   * Update the global `hasUnsavedChanges` flag to indicate whether there are any unsaved changes in the event (either to the image or other event details)
   */
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

/**
 * Handles the process of creating or updating an event, including image cropping, uploading, and state management.
 *
 * This function checks the state of the event and determines whether to create a new event or update an existing one.
 *
 * @returns Resolves when the event is created or updated successfully.
 */
const handleCreateEvent = async () => {
  /**
   * If the event is not marked as already multilingual, check and update its multilingual status based on the selected languages
   */
  !currentEvent.value.isMultilanguage && checkIfEventIsMultilingual()

  /**
   * Handle image cropping if required.
   * If a file was uploaded into the <ImageCropper> component, crop the image before proceeding.
   */
  if (imageCropperCurrentImage.value) {
    await imageCropperRef.value.cropImage()
  }

  /**
   * If the event already has an ID, it is being updated.
   */
  if (eventId.value) {
    /**
     * If the preview image was temporarily deleted and the user uploads another preview image, the previous one will be deleted before the update process continues
     */
    if (imagePreviewWasTemporaryDeleted.value && currentEvent.value.previewImages[0]) {
      await deleteAsset(currentEvent.value.previewImages![0].id)
      /**
       * Reset the previewImages array
       */
      currentEvent.value.previewImages = []
    }

    /**
     * Update event with the new data and image (if any)
     */
    await updateEvent(currentEvent.value as UpdateEventDto, croppedImageFile.value!).then(() => {
      if (imagePreviewWasTemporaryDeleted.value) {
        /**
         * Reset the image temporary deleted state and the image upload message
         */
        imagePreviewWasTemporaryDeleted.value = false
        showImageUploadMessage.value = false
      }
    })

    if (croppedImageFile.value) {
      /**
       * Reset the image upload message and cropped image file after the image has been uploaded so that the message is not shown and croppedImageFile is not sent again
       */
      croppedImageFile.value = null
      showImageUploadMessage.value = false
    }

    /**
     * Update original event state and reset unsaved changes flags
     */
    originalEventState.value = cloneable.deepCopy(currentEvent.value)
    hasUnsavedChanges.value = false
    imageHasUnsavedChanges.value = false
    eventHadAlreadyAPreviewImage.value = !!currentEvent.value.previewImages?.length

    toast.success(t('views.events.new.eventUpdated'))
  } else {
    /**
     * Create a new event with the new data and image (if any)
     */
    await createEvent(currentEvent.value as CreateEventDto, croppedImageFile.value!)

    if (croppedImageFile.value) {
      /**
       * Reset the image upload message and cropped image file after the image has been uploaded so that the message is not shown and croppedImageFile is not sent again
       */
      croppedImageFile.value = null
      showImageUploadMessage.value = false
    }

    /**
     * Update original event state and reset unsaved changes flags
     */
    originalEventState.value = cloneable.deepCopy(currentEvent.value)
    hasUnsavedChanges.value = false
    imageHasUnsavedChanges.value = false
    eventHadAlreadyAPreviewImage.value = !!currentEvent.value.previewImages?.length

    await router.push({ name: 'event-update', params: { id: currentEvent.value.id.toString() } })

    toast.success(t('views.events.new.eventCreated'))
  }
}

/**
 * Check if the language is already in the initial state of the event (if the event was already
 * saved with this language) and disable the checkbox
 *
 * @param lang - The language code ('de', 'en', etc.) associated with the checkbox.
 */
const isLanguageDisabled = (lang: Language): boolean => {
  return !!(eventId.value && originalEventState.value?.languages.includes(lang))
}

/**
 * Clears all fields for the unchecked language.
 * Deletes the language-specific data for each field.
 */
const clearEventFormFields = (lang: Language) => {
  const eventFormFields: Array<'name' | 'subtitle' | 'url' | 'description'> = [
    'name',
    'subtitle',
    'url',
    'description'
  ]

  eventFormFields.forEach((field) => {
    if (currentEvent.value[field]) {
      /**
       * Clear the data for the unchecked language
       */
      delete currentEvent.value[field][lang]
    }
  })
}

/**
 * Handles the selection and deselection of languages in the event's language list.
 *
 * This function updates the `currentEvent.languages` array when a language checkbox is checked or unchecked.
 *
 * The function also ensures that the `currentEventLanguage` state always reflects the first language in the
 * `currentEvent.languages` array, even if the user interacts with the checkboxes.
 *
 * @param lang The language code ('de', 'en', etc.) associated with the checkbox.
 * @param isChecked Boolean indicating whether the checkbox is checked or unchecked.
 *
 */
const manageLanguageSelection = (lang: Language, isChecked: boolean) => {
  if (isChecked && !currentEvent.value.languages.includes(lang)) {
    /**
     * Add the language to the languages array if it is checked and not already in the array
     */
    currentEvent.value.languages.push(lang)
  } else if (!isChecked && currentEvent.value.languages.includes(lang)) {
    const index = currentEvent.value.languages.indexOf(lang)

    if (index > -1) {
      /**
       * Remove the language from the languages array if it is unchecked and in the array
       */
      currentEvent.value.languages.splice(index, 1)

      /**
       * Clear all fields for the unchecked language
       */
      clearEventFormFields(lang)
    }
  }

  /**
   * This ensures that the selected VToggleTwoOptions shows always the first language in the array
   */
  currentEventLanguage.value = currentEvent.value.languages[0] as Language
}

/**
 * Handles url input changes. It sets the isUrlManuallyEdited flag and prevents generateSlug
 * from being triggered after a manual edit.
 *
 * @param newUrl - The new URL value entered by the user.
 */
const handleUrlInput = (newUrl: string) => {
  const lang = currentEventLanguage.value as Language

  /**
   * URL from name property without manual edits
   */
  const generatedUrlFromName = generateSlug(currentEvent.value.name[lang])

  /**
   * New URL input from user (reformatted)
   */
  const sanitizedUrl = generateSlug(newUrl)

  /**
   * Update the URL property with the new User input
   */
  currentEvent.value.url[lang] = sanitizedUrl

  /**
   * Determine if the user manually edited the URL
   * The URL is considered manually edited if it differs from the name-based generated URL
   */
  isUrlManuallyEdited.value[lang] = sanitizedUrl !== generatedUrlFromName
}

/****************************************
 * WATCHERS
 ****************************************/
/**
 * Watcher to check if the start date is greater than the end date and reset the end date if it is
 * This watcher is only active when the start date changes
 */
watch(
  () => currentEvent.value.startDate,
  (newStartDate) => {
    if (new Date(newStartDate) > new Date(currentEvent.value.endDate)) {
      currentEvent.value.endDate = ''
    }
  },
  { deep: true }
)

/**
 * Watcher to generate a URL based on the event name when the name changes
 * This gets deactivated via isUrlManuallyEdited after the user manually edits the URL
 */
watch(
  () => currentEvent.value.name[currentEventLanguage.value],
  (newName) => {
    const lang = currentEventLanguage.value as Language

    /**
     * Early return if the page is not fully loaded or the URL has been manually edited
     */
    if (!isPageLoaded.value || isUrlManuallyEdited.value[lang]) return

    /**
     * Update the URL property with the generated Slug
     */
    currentEvent.value.url[lang] = generateSlug(newName)
  }
)

/**
 * Watch currentEvent.value for changes to track unsaved changes
 */
watch(
  () => currentEvent.value,
  () => {
    if (originalEventState.value) {
      /**
       * Compare the original cloned event and the new event state
       *  */
      const differences = findDifferences(originalEventState.value, currentEvent.value)
      /**
       * Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
       */
      hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
    }
  },
  { deep: true }
)
</script>

<template>
  <MainLayout>
    <section class="flex h-full flex-col pb-4 text-dark-grey dark:text-light-grey">
      <h1 class="truncate text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
        {{ currentEvent.name[currentEventLanguage] || '\u200B' }}
      </h1>
      <div class="my-5 flex w-full justify-between">
        <div class="flex flex-row items-center gap-6">
          <VToggleTwoOptions
            v-if="currentEvent.isMultilanguage || currentEvent.languages?.length > 1"
            v-model="currentEventLanguage"
            input-id="currentEventLanguage"
            leftOptionValue="de"
            rightOptionValue="en"
            @changeOption="toggleCurrentEventLanguage"
          />
          <VMessage
            v-if="areAllLanguagesSelected && !isFormValid"
            :errorMessage="{ text: t('views.events.new.mandatoryFieldsNotFilledIn') }"
          />
        </div>
        <div v-if="eventId" class="ml-auto flex justify-end gap-x-2.5">
          <VButton
            :href="`/${currentEventLanguage}/events-public/${currentEvent.url[currentEventLanguage]}-${eventId}`"
            type="button"
            appearance="outline"
            :label="t('views.events.new.registered')"
            :disabled="false"
            size="small"
            :has-language="true"
            :language="currentEventLanguage"
          />
          <VButton
            v-if="currentEvent.protected"
            :href="`/${currentEventLanguage}/events-public/${currentEvent.url[currentEventLanguage]}-${eventId}/login`"
            type="button"
            appearance="outline"
            :label="t('views.events.new.public')"
            :disabled="false"
            size="small"
            :has-language="true"
            :language="currentEventLanguage"
          />
          <VButton
            :href="`/producer/#/${currentEvent.id}/cockpit`"
            type="button"
            appearance="default"
            :label="t('views.events.new.producerView')"
            :disabled="false"
            size="small"
          />
        </div>
      </div>
      <!-- the class 'group/form' triggers the form validation classes of the submit button -->
      <form class="group/form grow tracking-wide" novalidate>
        <div class="rounded-lg bg-white p-10 shadow dark:bg-dark-grey">
          <VInput
            v-model="currentEvent.name[currentEventLanguage]!"
            type="text"
            input-id="event-name"
            :label="t('views.events.new.eventTitle')"
            :placeholder="t('views.events.new.eventTitlePlaceholder')"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            :disabled="false"
            class="mb-7"
          />
          <div class="mb-7 grid grid-cols-2 gap-8 lg:gap-16">
            <VInput
              v-model="currentEvent.url[currentEventLanguage]!"
              type="text"
              input-id="event-url"
              label="Url"
              placeholder="Event Url..."
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              @update:modelValue="handleUrlInput"
              class="col-span-2 lg:col-span-1"
            />
            <VInput
              v-model="currentEvent.subtitle[currentEventLanguage]!"
              type="text"
              input-id="event-subtitle"
              :label="t('views.events.new.eventSubtitle')"
              :placeholder="t('views.events.new.eventSubtitlePlaceholder')"
              :disabled="false"
              class="col-span-2 lg:col-span-1"
            />
          </div>
          <div class="mb-8 grid grid-cols-2 gap-8 lg:gap-16">
            <VDatepicker
              :input-id="`event-start-date-${currentEvent.id}`"
              v-model="currentEvent.startDate"
              :label="t('views.events.new.eventStartDate')"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              :locale="locale"
              class="col-span-2 lg:col-span-1"
              :select-text="t('global.select')"
              :cancel-text="t('global.cancel')"
              :now-button-label="t('global.now')"
              :dark="isDarkMode"
            />
            <VDatepicker
              :input-id="`event-end-date-${currentEvent.id}`"
              v-model="currentEvent.endDate"
              :label="t('views.events.new.eventEndDate')"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              :locale="locale"
              :min-date="currentEvent.startDate"
              class="col-span-2 lg:col-span-1"
              :select-text="t('global.select')"
              :cancel-text="t('global.cancel')"
              :now-button-label="t('global.now')"
              :dark="isDarkMode"
            />
          </div>
          <div class="mb-7 grid grid-cols-2 gap-8 lg:gap-16">
            <div class="col-span-2 max-h-80 w-full lg:col-span-1">
              <QuillEditor
                input-id="event-description"
                ref="quillEditorDescription"
                :label="t('views.events.new.eventDescription')"
                :placeholder="t('views.events.new.eventDescriptionPlaceholder')"
                v-model:content="currentEvent.description[currentEventLanguage]"
                contentType="html"
              />
            </div>
            <div class="col-span-2 flex flex-col gap-2 lg:col-span-1">
              <div class="flex items-end justify-between">
                <label
                  class="flex flex-row gap-2 text-base font-bold text-dark-grey dark:text-light-grey"
                  ><span>{{ t('components.events.EventTable.previewImage') }}</span
                  ><span class="group relative">
                    <VTooltip
                      :modelValue="t('global.imageDeletedAfterSave')"
                      :position="'center'"
                      :className="'-top-5 text-sm'"
                    />
                    <FontAwesomeIcon :icon="['fal', 'circle-info']" /> </span
                ></label>
                <VButton
                  v-if="imageUploadMessage === 'uploaded' && showButtonToRemoveUncroppedImage"
                  type="button"
                  appearance="empty"
                  :function-on-click="handleDeleteImageTemporary"
                >
                  <FontAwesomeIcon :icon="['fal', 'xmark']" class="size-5" />
                </VButton>
              </div>
              <div
                v-if="
                  currentEvent.previewImages?.[0]?.public_id && !imagePreviewWasTemporaryDeleted
                "
                class="flex flex-col gap-4"
              >
                <div class="flex flex-row items-start gap-4">
                  <CloudImage
                    :imageName="currentEvent.previewImages[0].public_id"
                    class="aspect-video max-h-60 max-w-[calc(100%-3rem)] rounded object-cover"
                  />
                  <VButton
                    type="button"
                    appearance="empty"
                    size="small"
                    :function-on-click="handleDeleteImageTemporary"
                  >
                    <FontAwesomeIcon :icon="['fal', 'trash-can']" class="size-5 p-1.5" />
                  </VButton>
                </div>
                <p class="text-xs">{{ getImageName(currentEvent.previewImages[0].public_id) }}</p>
              </div>
              <div v-else class="flex flex-col">
                <p
                  v-if="showImageUploadMessage"
                  class="mt-2 text-sm font-bold"
                  :class="{
                    'text-dark-yellow dark:text-light-yellow': imageUploadMessage === 'uploading',
                    'text-dark-green dark:text-light-green': imageUploadMessage !== 'uploading'
                  }"
                >
                  {{
                    imageUploadMessage === 'uploading'
                      ? t('views.assets.assetBeingUploaded')
                      : t('views.assets.assetSuccessfullyUploaded')
                  }}
                </p>
                <div v-else class="flex w-full flex-row items-start gap-2">
                  <ImageCropper
                    ref="imageCropperRef"
                    cropForm="rectangle"
                    @imageCropped="imageCropped"
                    @imagePreviewUploaded="temporaryImageWasUploaded"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="relative mt-3 flex flex-wrap items-center justify-between gap-4">
            <div class="relative flex items-center">
              <span
                class="font-bold"
                :class="!isAnyLanguageSelected && 'text-dark-red dark:text-light-red'"
              >
                {{ t('global.languages') }}:
              </span>
              <VCheckbox
                :model-value="currentEvent.languages.includes('de')"
                @change="
                  manageLanguageSelection('de', ($event.target as HTMLInputElement)?.checked)
                "
                input-id="event-languages-german"
                :label="t('global.german')"
                :disabled="isLanguageDisabled('de')"
              />
              <VCheckbox
                :model-value="currentEvent.languages.includes('en')"
                @change="
                  manageLanguageSelection('en', ($event.target as HTMLInputElement)?.checked)
                "
                input-id="event-languages-english"
                :label="t('global.english')"
                :disabled="isLanguageDisabled('en')"
              />
              <!-- Hidden Input Field: This field is updated with the selected value, making it part of the form submission. -->
              <!-- TODO: Does this even work when `display: none` is active? I think this removes the input from the DOM -->
              <input
                v-if="!isAnyLanguageSelected"
                class="invisible hidden h-0 w-0"
                type="text"
                required
              />
              <VMessage
                v-if="!isAnyLanguageSelected"
                class="absolute left-0 top-full h-4 w-max overflow-hidden"
                :errorMessage="{ text: t('global.requiredField') }"
              />
            </div>
            <div class="flex items-center">
              <span class="mr-4 font-bold"> {{ t('views.events.new.eventProtected') }}: </span>
              <VToggle
                v-model="currentEvent.protected"
                input-id="event-protected"
                :value="currentEvent.protected"
                :disabled="false"
              />
            </div>
          </div>
        </div>
        <div class="mt-10 flex justify-end gap-8">
          <I18nRouterLink to="/events" class="overflow-hidden">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="large"
              :functionOnClick="confirmNavigation"
            />
          </I18nRouterLink>
          <VButton
            type="submit"
            appearance="default"
            :label="t('global.save')"
            :disabled="(areAllLanguagesSelected && !isFormValid) || !hasUnsavedChanges"
            size="large"
            :functionOnClick="handleCreateEvent"
          />
        </div>
      </form>
    </section>
    <template #modal>
      <!-- Unsaved Changes Modal -->
      <VModal :trigger="isUnsavedChangesModalOpen" avoidCloseModalOnOverlay>
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesTitle') }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesMessage') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isUnsavedChangesModalOpen = false
                }
              "
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.continue')"
              size="medium"
              :functionOnClick="confirmNavigation"
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
