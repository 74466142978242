/*
	This file contains the default registration form for the client view
	These are the default fields that will be shown in the first registration form
*/
export const defaultRegistrationForm = [
	{
		$formkit: 'email',
		name: 'email',
		label: {
			de: 'E-Mail *',
			en: 'Email *'
		},
		displayedName: { de: 'E-Mail', en: 'Email' },
		placeholder: {
			de: '',
			en: ''
		},
		help: {
			de: '',
			en: ''
		},
		validation: 'email|required',
		validationMessages: {
			required: {
				de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
				en: 'Please enter a valid email address'
			}
		},
		validationVisibility: 'submit',
		id: 1
	},
	{
		$formkit: 'select',
		name: 'salutation',
		label: {
			de: 'Anrede *',
			en: 'Salutation *'
		},
		displayedName: { de: 'Selectfeld', en: 'Select field' },
		placeholder: {
			de: ' ',
			en: ' '
		},
		help: {
			de: '',
			en: ''
		},
		options: {
			de: ['Frau', 'Herr'],
			en: ['Ms', 'Mr']
		},
		validation: 'required',
		validationMessages: {
			required: {
				de: 'Bitte geben Sie eine gültige Anrede ein',
				en: 'Please enter a valid salutation'
			}
		},
		validationVisibility: 'submit',
		id: 2,
	},
	{
		$formkit: 'text',
		name: 'firstName',
		label: {
			de: 'Vorname *',
			en: 'First name *'
		},
		displayedName: { de: 'Vorname', en: 'First name' },
		placeholder: {
			de: '',
			en: ''
		},
		help: {
			de: '',
			en: ''
		},
		validation: 'required',
		validationMessages: {
			required: {
				de: 'Bitte geben Sie einen gültigen Vornamen ein',
				en: 'Please enter a valid first name'
			}
		},
		validationVisibility: 'submit',
		id: 3
	},
	{
		$formkit: 'text',
		name: 'lastName',
		label: {
			de: 'Nachname *',
			en: 'Last name *'
		},
		displayedName: { de: 'Nachname', en: 'Last name' },
		placeholder: {
			de: '',
			en: ''
		},
		help: {
			de: '',
			en: ''
		},
		validation: 'required',
		validationMessages: {
			required: {
				de: 'Bitte geben Sie einen gültigen Nachnamen ein',
				en: 'Please enter a valid last name'
			}
		},
		validationVisibility: 'submit',
		id: 4
	}
]
