<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { ref } from 'vue'

import UserInfoMenu from '@/components/nav/UserInfoMenu.vue'
import { useAuthStore } from '@/stores/auth.store'
import { useNavStore } from '@/stores/nav.store'

/**
 * This component displays the user's information (name, email, and roles) in the left-side menu.
 * It also includes a menu toggle button for additional options, handled by the `UserInfoMenu` component.
 *
 * The component:
 * - Shows the user's full name, email, and roles.
 * - Allows collapsing and expanding the `UserInfoMenu`.
 * - Adjusts layout based on the state of the navigation menu (open or collapsed).
 */

// Retrieves the logged-in user data from the authentication store.
const { user } = useAuthStore()

// Accesses the navigation store to determine if the navigation menu is open.
const { isOpen } = storeToRefs(useNavStore())

/****************************************
 * REFS
 *****************************************/
// Controls the state of the user information menu (collapsed or expanded).
const userMenuCollapsed = ref(true)
const userInfoRef = ref(null)

const toggleUserMenu = () => {
  userMenuCollapsed.value = !userMenuCollapsed.value
}

/****************************************
 * METHODS
 *****************************************/
// Collapse the menu if a click outside of the menu is detected.
onClickOutside(userInfoRef, (event) => {
  if (event.target !== userInfoRef.value) {
    userMenuCollapsed.value = true
  }
})
</script>

<template>
  <section
    ref="userInfoRef"
    v-if="user"
    :class="
      twMerge(
        'flex min-h-[4.75rem] justify-between rounded-lg bg-glass-light-grey/75 p-6 pr-4 backdrop-blur',
        !isOpen && '!justify-center pr-6'
      )
    "
  >
    <div v-if="isOpen" class="truncate">
      <p class="text-base font-bold text-light-grey">
        {{ user.firstName + ' ' + user.lastName }}
      </p>
      <p class="text-[12px] leading-none text-medium-light-grey">
        {{ user.email }}
      </p>
      <p class="text-[12px] leading-none text-medium-light-grey">
        {{ user.roles.join(', ') }}
      </p>
    </div>
    <!-- Toggle button for user info menu -->
    <div @click="toggleUserMenu" class="cursor-pointer self-center px-4 py-2">
      <font-awesome-icon :icon="['fal', 'ellipsis-vertical']" class="text-3xl text-light-grey" />
    </div>
    <!-- UserInfoMenu emits an update event when the closing cross is clicked -->

    <Transition>
      <UserInfoMenu
        v-if="!userMenuCollapsed"
        :collapse="userMenuCollapsed"
        @update:collapse="userMenuCollapsed = $event"
        class="absolute -top-2.5 left-0 origin-bottom -translate-y-full"
      />
    </Transition>
  </section>
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
