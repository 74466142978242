<script setup lang="ts">
// @ts-ignore
import { icon } from '@fortawesome/fontawesome-svg-core'
import { RtaInteraction, detectWindowWidth } from '@rta2/rta-apps'
import '@rta2/rta-apps/lib/style.css'
import { useFullscreen, useScreenOrientation } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

import CloudImage from '@/components/utils/CloudImage.vue'
import Player3q from '@/components/video/Player3q.vue'
import { useEventSetup } from '@/composables/event/useEventSetup'
import { useStreamingStore } from '@/stores/streaming.store'
import type { components as components3q } from '@/types/types3q'
import { getImageName } from '@/utils/getImageName'

type Channel = components3q['schemas']['Channel']

const props = defineProps<{
  access: boolean
  source: any
  currentEventLanguage: string
  startDate: string
  startHour: string
  endHour: string
  previewImage?: { public_id: string }
  logo?: { public_id: string }
  format?: string
  entityType?: string
  view?: string
  isPreview: boolean
  apiKey?: string
  isFullscreen?: boolean
  rtaIconsPosition?: string
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t } = useEventSetup()
const vodProjectId = +import.meta.env.VITE_3Q_VOD_PROJECT_ID
const showRTA = ref<boolean>(props.source.liveProject?.projectId3q)

/****************************************
 * GET CHANNEL ID FROM 3Q
 * TODO: Move this to the backend
 *****************************************/
const apiKey = import.meta.env.VITE_PUBLIC_USER_API_KEY

const streamingStore = useStreamingStore()
const { getChannels } = streamingStore

const projectChannels = ref<Channel[]>([])
const playerTimestamp = ref<number>(0)
const streamDelay = ref<number>(0)

// Screen size and orientation detection
const { orientation } = useScreenOrientation()
const screenWidth = detectWindowWidth()
const isBigScreen = computed(
  () => screenWidth.value >= 768 || orientation.value === 'landscape-primary'
)
const isMobileHorizontal = computed(() => screenWidth.value < 768 && orientation.value === 'landscape-primary')

onMounted(async () => {
  if (props.source.liveProject?.projectId3q) {
    const channels = await getChannels(props.source.liveProject.projectId3q, apiKey)

    if (channels.Channels) {
      projectChannels.value = channels.Channels
    } else {
      console.error('No channels found for project', props.source.liveProject.projectId3q)
    }
  } else {
    console.error('No live project found for event', props.source.id)
  }
})

/**
 * callback for the player component to update the player timestamp and pass it to the
 * rta-interaction component
 * @param data
 */
function updatePlayerTimestamp(data: { timestamp: number; delay: number }) {
  playerTimestamp.value = data.timestamp
  streamDelay.value = data.delay
}
</script>

<template>
  <section
    :id="
      source && source.url && entityType === undefined
        ? `${source.url[currentEventLanguage]}-${source.id}-info`
        : source && source.url && entityType === 'design'
          ? `${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-info`
          : `${access ? 'access' : 'no-access'}-${view}-template-info`
    "
    :class="[
      isPreview
        ? 'flex flex-col gap-5 @mobile:py-5 @desktop:py-10'
        : isFullscreen && !isPreview
          ? 'flex h-full w-full flex-col'
          : 'container mx-auto flex h-full w-full scroll-mt-16 flex-col gap-5 py-5 @mobile:h-auto md:py-0'
    ]"
  >
    <!-- Header (logo and date) -->
    <div
      class="flex flex-col justify-between gap-5 md:flex-row"
      :class="isFullscreen && !isPreview && 'hidden'"
    >
      <div class="flex flex-row items-center md:justify-between">
        <div>
          <CloudImage
            v-if="logo"
            :imageName="logo['public_id']"
            class="h-auto max-h-[56px] w-auto max-w-[152px] md:max-h-[64px] md:max-w-[178px]"
            :class="
              isPreview && '@mobile:!max-h-[56px] @mobile:!max-w-[152px] @desktop:!max-h-[64px]'
            "
            :alt="getImageName(logo['public_id'])"
          />
        </div>
        <div class="flex flex-col" :class="logo && 'ml-3 border-l border-black pl-3'">
          <div
            class="flex flex-col items-start md:flex-row md:gap-5"
            :class="
              isPreview && '@mobile:!flex-col @mobile:!gap-0 @desktop:!flex-row @desktop:!gap-5'
            "
          >
            <div class="flex flex-row items-center gap-2 text-sm font-bold">
              <font-awesome-icon
                :icon="['fal', 'calendar']"
                class="text-[var(--secondary-color)]"
              />
              <span>{{ startDate }}</span>
            </div>
            <div class="flex flex-row items-center gap-2 text-sm">
              <font-awesome-icon
                :icon="['fal', 'clock']"
                class="text-sm text-[var(--secondary-color)]"
              />
              <span>{{ startHour.replace(' Uhr', '') }} - {{ endHour }}</span>
            </div>
          </div>
          <h1
            class="hidden text-xl font-semibold leading-10 text-[var(--primary-color)] md:block"
            :class="isPreview && '@mobile:!hidden @mobile:!text-xl @desktop:!block'"
          >
            {{ source.name?.[currentEventLanguage] }}
          </h1>
        </div>
      </div>
    </div>
    <!-- Header (title) -->
    <div
      class="flex flex-col items-start gap-2 md:gap-4"
      :class="isFullscreen && !isPreview && 'hidden'"
    >
      <h1
        class="text-xl font-semibold leading-10 text-[var(--primary-color)] md:hidden md:text-6xl md:leading-11"
        :class="isPreview && '@mobile:!block @mobile:!text-xl @desktop:!hidden'"
      >
        {{ source.name?.[currentEventLanguage] }}
      </h1>
    </div>
    <!-- RTA Layout -->
    <div
      :class="{
        'fixed left-0 top-0 h-full w-full': isFullscreen && !isPreview,
        'flex justify-center align-top w-full xl:w-3/4': !isFullscreen && !isPreview,
        'overflow-hidden rounded-3xl': !isFullscreen && format === 'rounded',
        'rounded-none': !isFullscreen && format !== 'rounded',
        'aspect-video': !isFullscreen && rtaIconsPosition === 'top' && isBigScreen && !isMobileHorizontal,
        'h-screen': isMobileHorizontal && !isFullscreen,
        '@mobile:aspect-auto': isPreview,
        '@desktop:aspect-video': isPreview && rtaIconsPosition === 'top',
      }"
    >
      <rta-interaction
        v-if="showRTA"
        :event-id="source.id"
        :format="format"
        :iconsPosition="rtaIconsPosition"
        :isPreview="isPreview"
        :view="view"
        :showStudioguests="source.interaction?.studioguests || false"
        :showPublicAnswers="source.interaction?.publicAnswers || false"
        :showChat="source.interaction?.chat || false"
        :playerTimestamp="playerTimestamp"
        :streamDelay="streamDelay"
        :isBigScreen="isBigScreen"
      >
        <Player3q
          v-if="
            source.liveProject && projectChannels && projectChannels[0] && projectChannels[0].Id
          "
          :key="source.liveProject.id"
          :apiKey="apiKey"
          :identifiers="{
            channelId: projectChannels[0].Id
          }"
          :embedParams="{ Autostart: !isPreview ? true : false }"
          @update-timestamp="updatePlayerTimestamp"
          status="pause"
          class="flex h-full max-h-full w-full items-center justify-center [&>video]:!h-full [&>video]:!w-auto"
        />
      </rta-interaction>
      <template v-else-if="source.vodFileIds && source.vodFileIds.length > 0">
        <Player3q
          :key="source.vodFileIds[0]"
          :apiKey="apiKey"
          :identifiers="{
            projectId: vodProjectId,
            fileId: source.vodFileIds[0]
          }"
          :embedParams="{ Autostart: !isPreview && true }"
          status="pause"
          class="flex !aspect-video !h-full max-h-full w-full items-center [&>iframe]:aspect-video [&>iframe]:h-fit
            [&>iframe]:max-h-full [&>iframe]:w-full [&>iframe]:object-contain"
        />
      </template>
      <template v-else>
        <CloudImage
          v-if="previewImage"
          :imageName="previewImage['public_id']"
          class="aspect-video h-full w-full"
          :alt="getImageName(previewImage['public_id'])"
        />
        <div
          v-else
          class="aspect-video h-full w-full bg-audience-light-grey"
          :title="source.name?.[currentEventLanguage] || 'Event placeholder'"
        ></div>
      </template>
    </div>
  </section>
</template>
