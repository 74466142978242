<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { inject, ref } from 'vue'

/**
 * Component: NavIcon
 *
 * Description:
 * The `NavIcon` component is responsible for rendering an icon within a navigation link. It conditionally
 * applies styles based on the active state of the link. The active state (`isActive`) is injected from the parent NavLink
 * component and controls whether the icon appears highlighted or in its default state.
 * RouterLink has it's internal active value but it can't be accessed.
 *
 * Slots:
 * - default: The default slot allows the user to pass any icon or content they wish to render inside the `NavIcon`.
 *   Typically, a `FontAwesomeIcon` is passed to display the appropriate navigation icon.
 *
 * Usage:
 * <NavIcon>
 *   <FontAwesomeIcon :icon="['fal', 'circle-user']" />
 * </NavIcon>
 */
const isActive = inject('isActive', ref(false))
</script>

<template>
  <span
    :class="
      twMerge(
        'flex h-12 w-12 items-center justify-center rounded-lg bg-dark-grey text-light-grey shadow',
        isActive && 'bg-light-grey text-dark-grey'
      )
    "
  >
    <slot />
  </span>
</template>
