<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import VideoUpload from '@/components/video/VideoUpload.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useStreamingStore } from '@/stores/streaming.store'
import type { FileData } from '@/types/Custom3qTypes'
import { formatTime } from '@/utils/formatTime'

const { t } = useI18n()

const route = useRoute()

/**
 * ----- Stores -----
 */
const streamingStore = useStreamingStore()
const { combineVodData } = streamingStore

const eventStore = useEventsStore()
const { fetchEventById } = eventStore
const { currentEvent } = storeToRefs(eventStore)

/**
 * ----- Data -----
 */
const vodProjectId = +import.meta.env.VITE_3Q_VOD_PROJECT_ID

let checkInterval: NodeJS.Timeout | null = null
let pollingTimeout: NodeJS.Timeout | null = null

const vodData = ref<FileData[]>([])

/**
 * ----- Functions -----
 */
const populateVodData = async () => {
  const eventId = route.params.id as string

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }
  await fetchEventById(+eventId)

  await nextTick()

  vodData.value = await combineVodData(vodProjectId, currentEvent.value.vodFileIds)

  const hasIncompleteFiles = vodData.value.some((file) => file.progress.Status !== 'finished')

  if (hasIncompleteFiles) {
    if (!checkInterval) {
      startPolling()
    }
  } else {
    clearPolling()
  }
}

const startPolling = () => {
  checkInterval = setInterval(async () => {
    console.log('Polling for VOD data...')
    await populateVodData()
  }, 15000)
}

const clearPolling = () => {
  if (checkInterval) {
    clearInterval(checkInterval)
    checkInterval = null
    console.log('Polling stopped')
  }
}

/**
 * Right after upload 3Q sends the status 'finished' and then later upadtes it.
 * To not falsely show the video as finished we wait 15 seconds before polling.
 */
const startDelayedPolling = () => {
  if (pollingTimeout) {
    clearTimeout(pollingTimeout)
  }

  pollingTimeout = setTimeout(() => {
    console.log('Starting delayed polling...')
    populateVodData()
  }, 15000)
}

/**
 * ----- Lifecycle hooks -----
 */
onMounted(async () => {
  await populateVodData()
})

onUnmounted(() => {
  clearPolling()

  if (pollingTimeout) {
    clearTimeout(pollingTimeout)
  }
})
</script>

<template>
  <MainLayout>
    <h1
      class="mb-10 mr-8 text-[32px] font-bold text-dark-grey lg:text-[42px] xl:text-[58px] dark:text-light-grey"
    >
      Video on Demand
    </h1>
    <!-- Video Upload -->
    <VideoUpload @uploadCompleted="startDelayedPolling" />
    <hr class="my-5 border-[1px]" />
    <!-- Uploaded videos list -->
    <section class="rounded-lg bg-white text-dark-grey dark:bg-dark-grey dark:text-light-grey">
      <div
        class="grid grid-cols-[11.5rem_1fr_1fr_1fr_1fr_1fr] overflow-x-auto border-b py-[18px] text-sm
          leading-tight tracking-[.015em] [&>*]:min-w-24"
      >
        <p class="mx-3 font-bold">Preview Image</p>
        <p class="pr-3 font-bold">Dateiname</p>
        <p class="pr-3 font-bold">Dauer</p>
        <p class="pr-3 font-bold">Status</p>
        <p class="pr-3 font-bold">Encoding Process</p>
        <div />
        <template v-for="file in vodData.slice().reverse()" :key="file.file3q.Id">
          <hr class="col-span-full my-3" />
          <div class="flex h-20 w-40 items-center justify-center px-3">
            <img
              v-if="file.file3q.Metadata?.StandardFilePicture"
              :src="file.file3q.Metadata?.StandardFilePicture?.ThumbURI"
              :alt="file.file3q.Metadata?.Title"
              class="h-full"
            />
            <font-awesome-icon v-else :icon="['fal', 'photo-film']" class="size-10" />
          </div>
          <p class="pr-3 text-xs">{{ file.file3q.Metadata?.Title }}</p>
          <p class="pr-3">{{ formatTime(file.file3q.Properties?.Length || 0) }}</p>
          <p class="pr-3">{{ file.progress.Status }}</p>
          <p class="pr-3">{{ file.progress.EncodingJobsProgressAVG }}%</p>
          <div />
        </template>
        <template v-if="vodData.length === 0">
          <hr class="col-span-full my-1" />
          <p class="col-span-full my-1 text-center text-base">
            {{ t('views.events.video.noVideosAvailable') }}
          </p>
        </template>
      </div>
    </section>
  </MainLayout>
</template>
