<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import NavLink from '@/components/nav/utilities/NavLink.vue'
import { useAuthStore } from '@/stores/auth.store'
import { useNavStore } from '@/stores/nav.store'

/**
 * This component represents the user info menu that appears when the user clicks on the toggle button
 * in the `UserInfo` component. It contains links to various user-related pages such as Profile,
 * Management, and Logout, and includes role-based conditionals.
 *
 * The component:
 * - Uses click-outside behavior to collapse the menu if the user clicks outside of it.
 * - Supports collapsing and expanding via an `update:collapse` event.
 * - Dynamically changes its visibility with smooth transitions.
 */

/****************************************
 * PROPS
 *****************************************/
const props = withDefaults(
  defineProps<{
    collapse: boolean
  }>(),
  {
    collapse: true
  }
)

// emit an update event when the closing cross is clicked
const emit = defineEmits<{
  'update:collapse': [collapse: boolean]
}>()

/****************************************
 * TRANSLATIONS
 *****************************************/
const { t } = useI18n()

/****************************************
 * STORES
 *****************************************/
const userStore = useAuthStore()
const { user } = storeToRefs(userStore)
const navStore = useNavStore()
const { isOpen } = storeToRefs(navStore)

/****************************************
 * REFS
 *****************************************/
const userInfoMenu = ref(null)
</script>

<template>
  <div
    ref="userInfoMenu"
    :class="
      twMerge(
        `flex w-full flex-col gap-y-3 rounded-lg bg-glass-misty-grey/75 p-6 px-[1.375rem] backdrop-blur
        [&>a]:text-xl`,
        !isOpen && 'items-center gap-y-5 px-0 pb-4 pt-10'
      )
    "
  >
    <NavLink to="/profile" :class="twMerge('pl-0', !isOpen && 'justify-center px-0')">
      <FontAwesomeIcon
        :icon="['fal', 'circle-user']"
        :class="twMerge('mr-2 size-5 p-1.5', !isOpen && 'mr-0 size-6')"
      />
      <span>{{ t('components.nav.UserInfoMenu.profile') }}</span>
    </NavLink>
    <NavLink
      v-if="user?.roles.includes('admin')"
      to="/management"
      :class="twMerge('pl-0', !isOpen && 'justify-center px-0')"
    >
      <FontAwesomeIcon
        :icon="['fal', 'users']"
        :class="twMerge('mr-2 size-5 p-1.5', !isOpen && 'mr-0')"
      />
      <span>{{ t('views.user.usersManagement') }}</span>
    </NavLink>
    <NavLink
      v-if="user?.roles.includes('admin')"
      to="/statistics-usage"
      :class="twMerge('pl-0', !isOpen && 'justify-center px-0')"
    >
      <FontAwesomeIcon
        :icon="['fal', 'chart-simple']"
        :class="twMerge('mr-2 size-5 p-1.5', !isOpen && 'mr-0')"
      />
      <span>{{ t('components.nav.UserInfoMenu.statisticsUsage') }}</span>
    </NavLink>
    <NavLink
      :noLocale="true"
      to="/logout"
      :class="twMerge('pl-0', !isOpen && 'justify-center px-0')"
    >
      <FontAwesomeIcon
        :icon="['fal', 'arrow-right-from-bracket']"
        :class="twMerge('mr-2 size-5 p-1.5', !isOpen && 'mr-0')"
      />
      <span>{{ t('global.logout') }}</span>
    </NavLink>
    <font-awesome-icon
      @click="emit('update:collapse', !collapse)"
      :icon="['fas', 'x']"
      class="absolute right-1.5 top-1.5 cursor-pointer p-1 text-sm text-light-grey"
    />
  </div>
</template>
