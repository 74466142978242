<script setup lang="ts">
import { AudienceAvatar } from '@techcast/histoire'

import { useEventSetup } from '@/composables/event/useEventSetup'
import type { components } from '@/types/swagger'
import { arraySortedByStringProperty } from '@/utils/arraySortedByStringProperty'
import { getFormattedDate } from '@/utils/getFormattedDate'

/****************************************
 * TYPES
 *****************************************/
type AgendaItem = components['schemas']['AgendaItem']
type Speaker = components['schemas']['Speaker']

/****************************************
 * PROPS
 *****************************************/
const props = defineProps<{
  access: boolean
  source: any
  currentEventLanguage: string
  agendaItems: Array<AgendaItem>
  speakers: Array<Speaker>
  format?: string
  entityType?: string
  view?: string
  isPreview?: boolean
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t } = useEventSetup()

/****************************************
 * METHODS
 *****************************************/
function getSpeakerImage(speaker: Speaker): string | undefined {
  // Find the speaker in props.speakers by matching firstName and lastName
  const foundSpeaker = props.speakers.find(
    (s) => s.firstName === speaker.firstName && s.lastName === speaker.lastName
  )
  // Return the speaker's image URL if it exists
  return foundSpeaker?.images?.[0]?.secure_url
}
</script>

<template>
  <section
    :id="
      source && source.url && entityType === undefined
        ? `${source.url[currentEventLanguage]}-${source.id}-agenda`
        : source && source.url && entityType === 'design'
          ? `${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-agenda`
          : `${access ? 'access' : 'no-access'}-${view}-template-agenda`
    "
    class="container mx-auto mt-5 h-full w-full scroll-mt-16 p-5 md:mt-10"
    :class="isPreview && '@mobile:!mt-2 @desktop:!mt-5'"
  >
    <div class="w-full min-w-0 flex-col gap-5">
      <h3
        class="text-[var(--primary-color)] mb-6 text-2.5xl leading-10 md:mb-12"
        :class="isPreview && '@mobile:!mb-4 @desktop:!mb-8 @desktop:!text-2xl'"
      >
        {{ t('global.agenda') }}
      </h3>
      <div
        v-for="(item, index) in arraySortedByStringProperty(agendaItems, 'startDate')"
        :class="{
          'grid grid-cols-4 gap-3 border border-x-0 border-y-black py-4 xl:w-3/4': true,
          'border-b-1': index === agendaItems.length - 1,
          'border-b-0': index !== agendaItems.length - 1,
          '@mobile:!w-full @desktop:!w-3/4': isPreview
        }"
      >
        <div
          class="col-span-4 w-full sm:col-span-1 md:col-span-4 lg:col-span-1 xl:col-span-1"
          :class="isPreview && '@mobile:!col-span-4 @desktop:!col-span-1'"
        >
          <p class="text-base" :class="isPreview && '@desktop:!text-sm'">
            <span>{{
              getFormattedDate(currentEventLanguage, item.startDate, true).replace(' Uhr', '')
            }}</span>
            -
            <span>{{ getFormattedDate(currentEventLanguage, item.endDate, true) }}</span>
          </p>
        </div>
        <div
          class="col-span-4 flex w-full flex-col gap-4 pl-10 sm:col-span-3 md:col-span-4 lg:col-span-3 xl:col-span-3"
        >
          <p
            :class="{
              'text-[var(--primary-color)] text-2.5xl leading-10': item.type !== 'pause',
              'text-base': item.type === 'pause',
              '@desktop:!text-2xl': isPreview
            }"
          >
            {{ item.title[currentEventLanguage] }}
          </p>
          <template v-if="item.type !== 'pause'">
            <p class="block text-base" :class="isPreview && '@desktop:!text-sm'">
              {{ item.description[currentEventLanguage] }}
            </p>
            <div class="flex flex-col flex-wrap gap-5 text-base md:flex-row">
              <div
                v-for="(speaker, i) in item.speakers"
                :key="`${speaker.firstName} ${speaker.lastName}`"
              >
                <AudienceAvatar
                  :type="'simple'"
                  :appearance="format || 'sharp'"
                  :title="`${speaker.firstName} ${speaker.lastName}`"
                  :image="{
                    url: getSpeakerImage(speaker),
                    title: `${speaker.firstName} ${speaker.lastName}` || 'Example Image'
                  }"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>
