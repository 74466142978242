/**
 * Formats a number of seconds into a human-readable time string
 * @example
 * formatTime(0) // '00:00:00'
 * formatTime(60) // '00:01:00'
 * formatTime(3600) // '01:00:00'
 * formatTime(3661) // '01:01:01'
 * formatTime(999999) // '277:46:39'
 *
 * @param seconds - The number of seconds to format
 */
export const formatTime = (seconds: number) => {
  /**
   * Ensure the number of seconds is at least 0
   */
  seconds = Math.max(0, seconds)

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}
