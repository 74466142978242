<script setup lang="ts">
import { VButton, VModal, VSelect, VToggleTwoOptions, VTooltip } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import draggable from 'vuedraggable'

import EditableFormElement from '@/components/registration/EditableFormElement.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import QuillEditor from '@/components/utils/QuillEditor.vue'
import { generatelistOfAvailableInputs } from '@/composables/registration/formSharedFunctions'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useWebformTemplatesStore } from '@/stores/webformTemplates.store'
import { useWebformsStore } from '@/stores/webforms.store'
import type { FormElement } from '@/types/FormElement'
import type { components } from '@/types/swagger'

type Webform = components['schemas']['Webform']

const toast = useToast()

const { t, locale } = useI18n()

const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent } = eventStore

const route = useRoute()
const eventId = route.params.id

// Clone the initial form data to compare against later
let originalWebformState = ref<Webform | null>(null)
// Composable setup
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()

// This variable will store the current form that is going to be saved or updated
// const form = ref<Webform>(currentEvent.value.webform || {})
const form = ref<Webform>(
  {
    ...currentEvent.value.webform
  } || {}
)

const listOfAvailableInputs = ref<Array<any>>([]) // List of available inputs to drag and drop into the final form

onMounted(async () => {
  listOfAvailableInputs.value = generatelistOfAvailableInputs()
  // initialize the current event after the component is mounted
  if (eventId) {
    const response = await fetchEventById(+eventId, true)

    if (response.webform?.id) {
      // fetch the webform by the id of the current event to set language toggle
      await fetchWebformById(+response.webform.id).then((res) => {
        form.value = structuredClone(res) as Webform
        showMultilanguageCheckbox.value = res.isMultilanguage

        // Save a deep copy of the original webform state
        originalWebformState.value = cloneable.deepCopy(form.value)
        hasUnsavedChanges.value = false
      })
    }

    await fetchAllWebformTemplates()
  }
})

onUnmounted(async () => {
  resetCurrentEvent()
  // Maybe not necessary, but just to be save we reset the form ¯\_(ツ)_/¯
  Object.assign(form, {})

  nextRoute.value = ''
})

const webformTemplateStore = useWebformTemplatesStore()
const { webformTemplates } = storeToRefs(webformTemplateStore)
const { fetchAllWebformTemplates, fetchWebformTemplateById } = webformTemplateStore

const webformStore = useWebformsStore()
const { fetchWebformById, createWebform, updateWebform, deleteWebform } = webformStore

const webformId = computed(() => currentEvent.value.webform?.id as number)

const showMultilanguageCheckbox = ref<boolean>(false) // Show the multilanguage checkbox only if the template is not a multilanguage template yet
// change the value of currentEventLanguage to the current selected language in the toggle component
function handleEventLanguageChange(newValue: String) {
  currentEventLanguage.value = newValue as 'de' | 'en'
}

/*
  When cloning the field, it will be checked if the element already brings an id, so that the new elements that are dragged inside the form get always a new id
  idGlobal is a global variable that will be used to give the new elements a new id and it starts with 1
*/
let idGlobal: number = 1

// When cloning the field, it will be checked if the element already brings an id, so that the new elements that are dragged inside the form get always a new id
function cloneElement(element: FormElement) {
  const clonedElement = ref<FormElement>({ ...element })
  const existingIds: Array<number> =
    form.value.elements &&
    form.value.elements.map((item: any) => parseInt(item.id, 10)).filter((id: number) => !isNaN(id)) // filter out non-numeric ids

  // Find the highest existing ID
  let highestId: number = existingIds && existingIds.length > 0 ? Math.max(...existingIds) : 0

  // when I clone any element it will check for the current highest id, compare it to the idGlobal and give the element a new id that is not already in the list of elements
  if (!clonedElement.value.id) {
    idGlobal = highestId + 1
    clonedElement.value.id = parseInt(idGlobal.toString())
  }

  return clonedElement.value
}

// The component DefaultInput will emit a function to run this one and refresh the updated element inside the draggable listOfFormElements
const refreshKey = ref(0)
function updateElement() {
  refreshKey.value++
}

// This function will update the form elements when a new element is dragged and dropped into the form
function updateFormElements(updatedElements: FormElement[]) {
  // TODO: fix type of elements in backend
  if (form.value) {
    form.value.elements = updatedElements as []
    updateElement()
  }
}

function updateIntroductionText(introductionText: { [key: string]: string }) {
  // Update or add introductionText in form
  if (form.value) {
    form.value.introductionText = introductionText as { [key: string]: string }
  }
}

// This watcher will watch the form, the currentEvent and the refreshKey to update the listOfAvailableInputs and also to track unsaved changes
watch(
  () => [form.value, refreshKey.value, currentEvent.value],
  () => {
    listOfAvailableInputs.value = generatelistOfAvailableInputs()

    if (originalWebformState.value) {
      // Compare each property
      const differences = findDifferences(originalWebformState.value, form.value)

      // Update hasUnsavedChanges
      hasUnsavedChanges.value = differences.length > 0
    }
  },
  {
    deep: true // Enable deep watching to watch nested properties of objects
  }
)

const openModalDeleteWebform = ref(false) // Modal to confirm the deletion of the webform

const showDraggableForm = ref(false) // Show the draggable form when a webform is selected

// This function will handle the saving of the webform
async function handleSaveWebform() {
  // TODO: Update type of createWebformDto in backend
  await createWebform(+eventId, form.value as Webform)
    .then((response) => {
      currentEvent.value.webform = response

      originalWebformState.value = cloneable.deepCopy(form.value)
      hasUnsavedChanges.value = false
    })
    .finally(() => {
      toast.success(t('views.events.registration.webformCreated'))
    })
}

async function handleUpdateWebform() {
  // TODO: Update type of createWebformDto in backend
  await updateWebform(+webformId.value, form.value as Webform)
    .then((response) => {
      currentEvent.value.webform = response

      originalWebformState.value = cloneable.deepCopy(form.value)
      hasUnsavedChanges.value = false
    })
    .finally(() => {
      toast.success(t('views.events.registration.webformUpdated'))
    })
}

// This function will handle the deletion of the webform and close the modal
async function handleDeleteWebform() {
  await deleteWebform(+webformId.value)
    .then(async (response) => {
      if (response.ok) {
        currentEvent.value.webform = {} as Webform
        openModalDeleteWebform.value = false
        showDraggableForm.value = false
        showMultilanguageCheckbox.value = false
      }
    })
    .finally(() => {
      toast.success(t('views.events.registration.webformDeleted'))
    })
}

// This function will select a template from the list of available templates
async function onSelectTemplate(e: any) {
  const selectedTemplate = await fetchWebformTemplateById(e.value)

  if (selectedTemplate) {
    // check if the selected template is multilanguage and set the currentEvent.value.webform.isMultilanguage to that value
    showMultilanguageCheckbox.value = selectedTemplate.isMultilanguage

    updateIntroductionText(selectedTemplate.introductionText)
    // TODO: Fix type of elements in backend
    updateFormElements(selectedTemplate.elements as [])
    listOfAvailableInputs.value = generatelistOfAvailableInputs()

    originalWebformState.value = cloneable.deepCopy(currentEvent.value.webform)
    hasUnsavedChanges.value = true
  } else {
    currentEvent.value.webform = {} as Webform
  }

  showDraggableForm.value = true
}

const listOfMultilanguageTemplates = computed(() => {
  return webformTemplates.value.filter((template) => template.isMultilanguage)
})

const listOfSingleLanguageTemplates = computed(() => {
  return webformTemplates.value.filter((template) => !template.isMultilanguage)
})

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }
})
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.registrationForm') }}
        </h1>
      </div>
      <div class="my-5 mr-auto flex w-full flex-row justify-start gap-4">
        <VToggleTwoOptions
          v-if="showMultilanguageCheckbox"
          v-model="currentEventLanguage"
          input-id="currentEventLanguage"
          leftOptionValue="de"
          rightOptionValue="en"
          @change="handleEventLanguageChange"
        />
      </div>
      <div
        v-if="!currentEvent.protected"
        class="flew-wrap mb-6 flex w-full flex-row items-center gap-3"
      >
        <p class="relative pl-4 text-sm before:absolute before:left-0 before:content-['*']">
          {{ t('views.events.registration.infoNonProtectedEvent') }}
        </p>
        <I18nRouterLink :to="`/events/${eventId}/update`" class="min-w-max">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.registration.goToEvent')"
            size="medium"
          >
            <FontAwesomeIcon :icon="['fal', 'calendar-pen']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <div v-if="!webformId && !showDraggableForm">
        <div v-if="webformTemplates.length > 0" class="mb-6 flex flex-col gap-1">
          <div class="flex flex-col gap-6">
            <VSelect
              :label="`${t('views.events.registration.selectTemplate')}:`"
              :placeholder="t('views.events.registration.selectTemplate')"
              @selectOption="onSelectTemplate"
              :options="
                currentEvent.isMultilanguage
                  ? listOfMultilanguageTemplates
                  : listOfSingleLanguageTemplates
              "
              labelKey="name"
              valueKey="id"
            />
            <p class="relative pl-4 text-sm before:absolute before:left-0 before:content-['*']">
              {{
                `${t('views.events.registration.informationToWebforms')}: ${currentEvent.isMultilanguage ? t('views.events.registration.multilanguage') : t('views.events.registration.monolingual')}`
              }}
            </p>
          </div>
        </div>
        <I18nRouterLink v-else to="/templates/webform">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.registration.goToWebformManagement')"
            size="medium"
          >
            <FontAwesomeIcon :icon="['fal', 'headset']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <form
        v-if="webformId || showDraggableForm"
        @submit.prevent
        class="prose-ul:list-none"
        novalidate
      >
        <div class="rounded-lg bg-white p-10 shadow dark:bg-dark-grey">
          <div class="mb-4 max-h-80 w-full">
            <QuillEditor
              v-if="form.introductionText && form.introductionText[currentEventLanguage]"
              :inputId="'webform-template-description'"
              ref="quillEditorRegistration"
              :label="t('views.events.registration.introductionText')"
              v-model:content="form.introductionText[currentEventLanguage]"
              contentType="html"
              :placeholder="t('views.events.registration.introductionText')"
            />
          </div>
          <div class="mb-4 grid grid-cols-1 gap-4 md:grid-cols-[1fr_auto] md:gap-4 lg:grid-cols-12">
            <div class="relative z-10 lg:col-span-4 2xl:col-span-3">
              <h3 class="mb-4">
                <strong>{{ t('views.events.registration.elements') }}</strong>
              </h3>
              <draggable
                v-model="listOfAvailableInputs"
                itemKey="id"
                :animation="300"
                :group="{ name: 'form-items', pull: 'clone', put: false }"
                :sort="false"
                :clone="cloneElement"
                :filter="'.cursor-not-allowed'"
                :onEnd="updateElement"
                class="flex flex-row flex-wrap gap-4 rounded bg-transparent xl:flex-col"
              >
                <template #item="{ element, index }">
                  <div
                    :key="element.id"
                    class="w-full min-w-[132px] rounded border border-misty-grey/20 p-6 text-center shadow-sm shadow-ice-grey
                      dark:shadow-black/5"
                    :class="
                      element.disabled
                        ? 'cursor-not-allowed bg-ice-grey/60 opacity-50 dark:bg-misty-grey/60'
                        : 'cursor-move bg-ice-grey dark:bg-misty-grey'
                    "
                  >
                    {{ element.displayedName[locale] }}
                  </div>
                </template>
              </draggable>
            </div>

            <div class="lg:col-span-8 2xl:col-span-9">
              <h3 class="mb-4">
                <strong>{{ t('global.form') }}</strong>
              </h3>
              <draggable
                v-model="form.elements"
                itemKey="id"
                :animation="300"
                group="form-items"
                handle=".handle"
                class="flex h-[calc(100%-2.5rem)] flex-col gap-4 overflow-auto rounded bg-ice-grey p-4 dark:bg-misty-grey"
              >
                <template #item="{ element, index }">
                  <div :class="element.$formkit === 'submit' && 'mt-auto'">
                    <EditableFormElement
                      :locale="currentEventLanguage"
                      :element="element"
                      :key="refreshKey"
                      :list="form.elements"
                      class="bg-white dark:bg-dark-grey"
                      @updateFormElements="updateFormElements"
                    />
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
        <div class="relative z-10 flex justify-end gap-8 py-5">
          <div v-if="webformId" class="flex items-center gap-4">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.delete')"
              :disabled="false"
              size="large"
              :functionOnClick="() => (openModalDeleteWebform = true)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.update')"
              :disabled="!hasUnsavedChanges"
              size="large"
              :functionOnClick="handleUpdateWebform"
            />
          </div>
          <VButton
            v-if="!webformId"
            type="submit"
            appearance="default"
            :label="t('global.save')"
            :disabled="!hasUnsavedChanges"
            size="large"
            :functionOnClick="handleSaveWebform"
          />
        </div>
      </form>
    </section>
    <VModal
      v-model:trigger="openModalDeleteWebform"
      :title="t('views.events.registration.modalDeleteWebform')"
    >
      <template #modalHeader>
        <p>
          <strong>{{ t('views.events.registration.modalDeleteWebform') }}</strong>
        </p>
      </template>
      <template #modalBody>
        <p>
          {{ t('views.events.registration.confirmDeleteWebform') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            :disabled="false"
            size="medium"
            :functionOnClick="() => (openModalDeleteWebform = false)"
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.yes')"
            :disabled="false"
            size="medium"
            :functionOnClick="handleDeleteWebform"
          />
        </div>
      </template>
    </VModal>
    <!-- Unsaved Changes Modal -->
    <VModal
      :type="'edit'"
      :trigger="isUnsavedChangesModalOpen"
      :function-on-close="
        () => {
          isUnsavedChangesModalOpen = false
        }
      "
    >
      <template #modalHeader>
        <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesTitle') }}
        </p>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesMessage') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="
              () => {
                isUnsavedChangesModalOpen = false
              }
            "
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.continue')"
            size="medium"
            :functionOnClick="confirmNavigation"
          />
        </div>
      </template>
    </VModal>
  </MainLayout>
</template>
