export const getFormattedDate = (
  locale: string,
  dateString: string,
  onlyHours: boolean = false,
  onlyDate: boolean = false
): string => {
  const optionsComplete: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  const optionsOnlyDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  const optionsOnlyHours: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  }

  const date = new Date(dateString)
  const localeString = locale === 'de' ? 'de-DE' : 'en-US'
  const options = onlyDate
    ? optionsOnlyDate
    : onlyHours
      ? optionsOnlyHours
      : optionsComplete

  let formattedDate = onlyHours
    ? date.toLocaleTimeString(localeString, options)
    : date.toLocaleDateString(localeString, options)

  // Append 'Uhr' only if the locale is 'de' and `onlyDate` is false
  if (locale === 'de' && !onlyDate) {
    formattedDate += ' Uhr'
  }

  return formattedDate
}
