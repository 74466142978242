<script setup lang="ts">
import { useEventSetup } from '@/composables/event/useEventSetup'
import type { components } from '@/types/swagger'

/****************************************
 * TYPES
 *****************************************/
type Download = components['schemas']['Download']

/****************************************
 * PROPS
 *****************************************/
const props = defineProps<{
  access: boolean
  source: any
  currentEventLanguage: string
  downloads: Array<Download>
  entityType?: string
  view?: string
  isPreview?: boolean
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t } = useEventSetup()
</script>

<template>
  <section
    :id="
      source && source.url && entityType === undefined
        ? `${source.url[currentEventLanguage]}-${source.id}-downloads`
        : source && source.url && entityType === 'design'
          ? `${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-downloads`
          : `${access ? 'access' : 'no-access'}-${view}-template-downloads`
    "
    class="container mx-auto mt-5 h-full w-full scroll-mt-16 p-5 md:mt-10"
    :class="isPreview && '@mobile:!mt-2 @desktop:!mt-5'"
  >
    <h3 class="text-[var(--primary-color)] mb-6 text-2.5xl leading-10 md:mb-12"
      :class="isPreview && '@mobile:!mb-4 @desktop:!mb-8 @desktop:!text-2xl'">Downloads</h3>
    <div class="flex flex-col gap-4 xl:w-3/4">
      <div
        v-for="(download, index) in downloads"
        :key="download.assets[0].asset_id"
        class="flex flex-row flex-wrap items-end gap-2"
      >
        <a
          class="flex w-fit flex-row items-center gap-2.5 text-base font-bold md:gap-5 [&_span]:hover:border-black"
          target="_blank"
          :href="download.assets[0].secure_url"
          download
        >
          <font-awesome-icon
            :icon="['fal', 'calendar']"
            class="text-[var(--secondary-color)] border-b border-transparent"
          />
          <span class="border-b border-transparent duration-300">{{ download.name }}</span>
        </a>
        <span class="text-xs leading-6">({{ download.assets[0].format }})</span>
      </div>
    </div>
  </section>
</template>
