<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { computed, provide } from 'vue'
import { useI18n } from 'vue-i18n'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { useNavStore } from '@/stores/nav.store'
import { useLocalePath } from '@/utils/useLocalePath'

/**
 * Component: NavLink
 *
 * Description:
 * The `NavLink` component creates a customizable navigation link in the left-hand menu.
 * It determines if the link is active by comparing the current route to the target route or checking
 * if the target route is a parent of the current route.
 * The active state is then provided to child components using the `provide` and `inject` API.
 * The component supports locale-specific routes and can be styled with CSS classes.
 * It utilizes the `I18nRouterLink` component to ensure proper locale handling in navigation paths.
 *
 * Props:
 * - `to` (String, required): The target route for the navigation link.
 * - `noLocale` (Boolean, optional): Determines whether the locale should be included in the path. Default is `false`.
 * - `class` (String, optional): Additional CSS classes to be applied to the link.
 *
 * Injected:
 * - `isActive`: Provided to child components, indicating whether the link is active.
 *
 * Reactive:
 * - `currentRoute`: The current route, retrieved from the `navStore`.
 * - `isOpen`: Indicates whether the left navigation menu is open, also from `navStore`.
 *
 * Computed Properties:
 * - `localePath`: Generates the target route path with the locale included (if `noLocale` is false).
 * - `isActive`: Computes if the link is active based on the current route or if the target route is a parent of the current route.
 *
 * Functions:
 * - `arePathsEqual`: Compares the current path with the target path to check if they are exactly equal.
 * - `isTargetParentPath`: Determines if the target path is a parent route of the current path by comparing URL segments.
 *
 * Usage Example:
 * ```vue
 * <NavLink :to="`/profile`">
 *   <NavIcon>
 *     <FontAwesomeIcon :icon="['fal', 'circle-user']" />
 *   </NavIcon>
 *   <span>{{ t('global.profile') }}</span>
 * </NavLink>
 * ```
 */

const props = withDefaults(
  defineProps<{
    /** The route to navigate to */
    to: string
    /** Whether to show the locale in the path */
    noLocale?: boolean
    /** Class attribute */
    class?: string
  }>(),
  {
    class: ''
  }
)

/**
 * We use isOpen from the nav store to determine if the left menu is open.
 */
const navStore = useNavStore()
const { currentRoute, isOpen } = storeToRefs(navStore)
const i18n = useI18n()
const localePath = useLocalePath(props.to, i18n)

/**
 * Check if the current route and the target route are equal.
 */
const arePathsEqual = () => {
  const currentPath = currentRoute.value.replace(/\/$/, '')
  const targetPath = localePath.value.replace(/\/$/, '')

  return currentPath === targetPath
}

/**
 * Check if the target route is a parent of the current route.
 */
const isTargetParentPath = () => {
  const currentPath = currentRoute.value.replace(/\/$/, '')
  const targetPath = localePath.value.replace(/\/$/, '')

  const currentPathSegments = currentPath.split('/')
  const targetPathSegments = targetPath.split('/')

  /**
   * The target path should have fewer segments than the current path to be a parent
   */
  if (targetPathSegments.length >= currentPathSegments.length) {
    return false
  }

  /**
   * Compare each segment of the target path with the current path.
   * If all segments match, the target path is a parent of the current path.
   */
  return targetPathSegments.every((segment, index) => segment === currentPathSegments[index])
}

/**
 * Combine `arePathsEqual` and `isTargetParentPath` to determine if the link is active.
 */
const isActive = computed(() => {
  return arePathsEqual() || isTargetParentPath()
})

/**
 * While RouterLink has its own internal active value, it cannot be accessed directly.
 * Therefore we provide the `isActive` value here and inject it in child components.
 *
 * @example
 * ```ts
 * const isActive = inject('isActive', ref(false))
 * ```
 */
provide('isActive', isActive)
</script>

<template>
  <I18nRouterLink
    v-if="!noLocale"
    :to="to"
    :class="
      twMerge(
        'flex items-center gap-4 px-3.5 text-[22px] text-light-grey [&>*]:shrink-0',
        !isOpen && '[&>span:last-child]:hidden',
        isActive && 'font-bold',
        props.class
      )
    "
  >
    <slot />
  </I18nRouterLink>
  <RouterLink
    v-if="noLocale"
    :to="to"
    :class="
      twMerge(
        'flex items-center gap-4 px-3.5 text-[22px] text-light-grey [&>*]:shrink-0',
        !isOpen && '[&>span:last-child]:hidden',
        isActive && 'font-bold',
        props.class
      )
    "
  >
    <slot />
  </RouterLink>
</template>
