<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import BackButton from '@/components/nav/utilities/BackButton.vue'
import NavIcon from '@/components/nav/utilities/NavIcon.vue'
import NavLink from '@/components/nav/utilities/NavLink.vue'
import SubMenu from '@/components/nav/utilities/SubMenu.vue'
import { useNavStore } from '@/stores/nav.store'
import { matchesBasePath } from '@/utils/matchesBasePath'

/**
 * Component: LeftMenuEvent
 *
 * Description:
 * This component represents a section of the left-side navigation menu specific to event management. It provides navigation links
 * for various event-related sections. The component uses dynamic route parameters and localization to render appropriate links and
 * labels based on the current event context and user locale.
 *
 * Exposed Methods:
 * - `getRootElement` (Function): Returns a reference to the root element of the component, used to check scrollability.
 *
 */

 /****************************************
 * TRANSLATIONS
 *****************************************/
const { t, locale } = useI18n()

/****************************************
 * ROUTER
 *****************************************/
const route = useRoute()

/****************************************
 * STORES
 *****************************************/
const navStore = useNavStore()
const { currentRoute } = storeToRefs(navStore)

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
const currentEventId = computed(() => (route.params.id as string) || null)

/**
 * Expose the root element to the parent component. This is used to check if the container is scrollable.
 */
const rootElement = ref(null) // used to hold a reference to the DOM element represented by the <section> tag in the template.
const getRootElement = () => rootElement.value // used to access the DOM element referenced by rootElement from outside the component’s template
defineExpose({ getRootElement })
</script>

<template>
  <section ref="rootElement" class="relative overflow-scroll pb-[2rem]">
    <BackButton to="/events" />
    <!-- EVENT -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/update`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'calendar-pen']" />
      </NavIcon>
      <span>Event</span>
    </NavLink>
    <NavLink v-else to="/events/new">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'calendar-pen']" />
      </NavIcon>
      <span>Event</span>
    </NavLink>
    <!-- VIDEO -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/video`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'clapperboard-play']" />
      </NavIcon>
      <span>Video</span>
    </NavLink>
    <!-- matchesBasePath is used to check if the current route is a child of the video section
         If it is, the SubMenuVideo component is displayed. -->
    <SubMenu
      v-if="
        currentEventId && matchesBasePath(currentRoute, `${locale}/events/${currentEventId}/video`)
      "
    >
      <NavLink :to="`/events/${currentEventId}/video/live`">
        <span>Live</span>
      </NavLink>
      <NavLink :to="`/events/${currentEventId}/video/vod`">
        <span>On Demand</span>
      </NavLink>
    </SubMenu>
    <!-- DESIGN -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/design`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'brush']" />
      </NavIcon>
      <span>Design</span>
    </NavLink>
    <!-- INTERACTION -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/interaction`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'comment']" />
      </NavIcon>
      <span>{{ t('components.nav.LeftMenuEvent.interaction') }}</span>
    </NavLink>
    <!-- DOWNLOADS & LINKS -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/downloads-and-links`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'folder-arrow-down']" />
      </NavIcon>
      <span>Downloads & Links</span>
    </NavLink>
    <!-- REGISTRATION -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/webform`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'address-card']" />
      </NavIcon>
      <span>{{ t('global.registration') }}</span>
    </NavLink>
    <!-- EMAIL TODO: TEMPORARY OFF -->
    <!-- <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/email`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'envelope']" />
      </NavIcon>
      <span>Email</span>
    </NavLink> -->
    <!-- SPEAKERS -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/speakers`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'headset']" />
      </NavIcon>
      <span>{{ t('global.speakers') }}</span>
    </NavLink>
    <!-- AGENDA -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/agenda`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'list-ol']" />
      </NavIcon>
      <span>Agenda</span>
    </NavLink>
    <!-- ANALYTICS -->
    <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/analytics/registrations`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'chart-mixed']" />
      </NavIcon>
      <span>Analytics</span>
    </NavLink>
    <SubMenu v-if="matchesBasePath(currentRoute, `${locale}/events/${currentEventId}/analytics`)">
       <NavLink v-if="currentEventId" :to="`/events/${currentEventId}/analytics/registrations`">
         <span>{{ t('global.registrations') }}</span>
       </NavLink>
     </SubMenu>
  </section>
</template>
