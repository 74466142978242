/**
 * Utility function to format bytes into human readable format.
 * Values up to MB have 2 decimal places, values in GB and above have 3 decimal places.
 *
 * @example
 * formatBytes(1024) // 1 KB
 * formatBytes(123456789) // 117.74 MB
 * formatBytes(9876543210) // 9.203 GB
 *
 * @see {@link https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c}
 *
 * @param bytes - number of bytes to format
 */
export const formatBytes = (bytes: number): string => {
  if (+bytes === 0) return '0'

  const k = 1024
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  const i = Math.floor(Math.log(+bytes) / Math.log(k))

  /**
   * 2 decimals for values up to MB
   * 3 decimals for values in GB and above
   */
  const decimals = i >= 3 ? 3 : 2

  return parseFloat((+bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
}
