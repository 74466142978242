<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import MainLayout from '@/layouts/MainLayout.vue'
import { useStreamingStore } from '@/stores/streaming.store'
import type { components } from '@/types/swagger'
import { formatBytes } from '@/utils/formatBytes'

type UsageTotalsDto = components['schemas']['UsageTotalsDto']
type MonthlyUsageDto = components['schemas']['MonthlyUsageDto']

const { t } = useI18n()

const streamingStore = useStreamingStore()
const { getUsage, getMonthlyUsage } = streamingStore

const totalUsage = ref<UsageTotalsDto | null>(null)
const monthlyUsage = ref<MonthlyUsageDto[]>([])

onMounted(async () => {
  totalUsage.value = await getUsage()
  monthlyUsage.value = await getMonthlyUsage()
})
</script>

<template>
  <MainLayout>
    <h1
      class="mb-10 mr-8 text-[32px] font-bold text-dark-grey lg:text-[42px] xl:text-[58px] dark:text-light-grey"
    >
      {{ t('views.statistics.statisticsUsage') }}
    </h1>
    <section class="text-dark-grey xl:flex xl:flex-wrap dark:text-light-grey">
      <h2 class="mb-3 text-2xl font-bold xl:w-full">
        {{ t('views.statistics.totalUsage') }}
      </h2>
      <div
        class="inline-block w-full rounded-t bg-white px-2 py-1 xl:w-[50%] xl:rounded-bl xl:rounded-tl
          xl:rounded-tr-none dark:bg-dark-grey"
      >
        <h3 class="text-xl font-bold">Live</h3>
        <hr class="my-1" />
        <ul v-if="totalUsage" class="text-md inline-block">
          <li v-for="(value, key) in totalUsage.live" :key="key">
            <template v-if="key !== 'drmLicenses'">
              <p v-if="['bandwidth', 'storage'].includes(key)">
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ formatBytes(value) }}
              </p>
              <p v-else>
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ value }}
              </p>
            </template>
          </li>
        </ul>
      </div>
      <div
        class="inline-block w-full rounded-b bg-white px-2 py-1 xl:w-[50%] xl:rounded-bl-none xl:rounded-br
          xl:rounded-tr dark:bg-dark-grey"
      >
        <h3 class="mb-1 text-xl font-bold">Video on Demand</h3>
        <hr class="my-1" />
        <ul v-if="totalUsage" class="text-md inline-block">
          <li v-for="(value, key) in totalUsage.vod" :key="key">
            <template v-if="key !== 'drmLicenses'">
              <p v-if="['bandwidth', 'storage'].includes(key)">
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ formatBytes(value) }}
              </p>
              <p v-else>
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ value }}
              </p>
            </template>
          </li>
        </ul>
      </div>
    </section>
    <hr class="my-3" />
    <section class="text-dark-grey dark:text-light-grey">
      <h2 class="mb-3 text-2xl font-bold">
        {{ t('views.statistics.usagePerMonth') }}
      </h2>
      <div
        class="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr] overflow-x-auto rounded bg-white px-2 py-1
          dark:bg-dark-grey [&>*]:min-w-24"
      >
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">Month</p>
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">Bandwidth</p>
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">Storage</p>
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">Encoding Minutes</p>
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">Transcoding Minutes</p>
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">AI Minutes</p>
        <p class="mb-3 pr-3 text-sm font-bold leading-tight">Users</p>
        <template v-for="month in monthlyUsage">
          <hr class="col-span-full my-1" />
          <div class="flex justify-between gap-1 pr-5">
            <p class="flex flex-col">
              <span class="mb-0.5 self-center">{{ month.month.split('-')[1] }}</span>
              <span class="text-sm">{{ month.month.split('-')[0] }}</span>
            </p>
            <p class="flex flex-col font-bold">
              <span>Live:</span>
              <span>Vod:</span>
            </p>
          </div>
          <p class="flex flex-col">
            <span>
              {{ formatBytes(month.live.bandwidth) }}
            </span>
            <span>
              {{ formatBytes(month.vod.bandwidth) }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ formatBytes(month.live.storage) }}
            </span>
            <span>
              {{ formatBytes(month.vod.storage) }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.encodingMinutes }}
            </span>
            <span>
              {{ month.vod.encodingMinutes }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.transcodingMinutes }}
            </span>
            <span>
              {{ month.vod.transcodingMinutes }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.videoAIMinutes }}
            </span>
            <span>
              {{ month.vod.videoAIMinutes }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.users }}
            </span>
            <span>
              {{ month.vod.users }}
            </span>
          </p>
        </template>
      </div>
    </section>
  </MainLayout>
</template>
