<script setup lang="ts">
import { VButton, VModal, VToggleTwoOptions } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import type { components } from '@/types/swagger'

type UpdateEventDto = components['schemas']['UpdateEventDto']

const toast = useToast()

const { t, locale } = useI18n()

/****************************************
 * STORES
 *****************************************/
const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent, updateEvent } = eventStore

const route = useRoute()

/**
 * Use route params to get current event id
 */
const eventId = route.params.id

/****************************************
 * COMPOSABLES
 *****************************************/
// Unsaved changes composable
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  forgetUnsavedChanges,
  nextRoute
} = useUnsavedChanges()

/****************************************
 * REFS
 *****************************************/
const imageHasUnsavedChanges = ref(false)
const originalEventState = ref<Event | null>(null) // Clone the initial form data to compare against later
const showPublicAnswers = ref<boolean>(false)
const showStudioguests = ref<boolean>(false)
const showChat = ref<boolean>(false)
const eventHasLiveVideo = ref<boolean>(false)

/****************************************
 * COMPUTED
 *****************************************/
const interaction = computed(() => {
  return {
    publicAnswers: showPublicAnswers.value,
    studioguests: showStudioguests.value,
    chat: showChat.value
  }
})

const shouldShowForm = computed(() => {
  return eventId && eventHasLiveVideo.value
})

/****************************************
 * LIFECYCLE HOOKS
 *****************************************/
onMounted(async () => {
  // initialize the current event after the component is mounted
  if (eventId) {
    await fetchEventById(+eventId)

    // TODO: TEMPORARY SOLUTION: if currentEvent.value.interaction is null, set it to an empty object with default values
    if (!currentEvent.value.interaction) {

      currentEvent.value.interaction = {
        publicAnswers: false,
        studioguests: false,
        chat: false
      }
    } else {

      showPublicAnswers.value = currentEvent.value.interaction?.publicAnswers || false
      showStudioguests.value = currentEvent.value.interaction?.studioguests || false
      showChat.value = currentEvent.value.interaction?.chat || false
    }

    eventHasLiveVideo.value = !!currentEvent.value?.liveProject?.projectId3q

    // set the current webform template language to the first language of the supported locales always on onMounted
    currentEventLanguage.value = 'de'

    // Save a deep copy of the original event state
    originalEventState.value = cloneable.deepCopy(currentEvent.value)
  }
})

onUnmounted(() => {
  resetCurrentEvent()
  nextRoute.value = ''
})

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  // console.log('forgetUnsavedChanges.value', forgetUnsavedChanges.value)

  if (!forgetUnsavedChanges.value && hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }

  forgetUnsavedChanges.value = false
})

/****************************************
 * METHODS
 *****************************************/
// This function will update the current design
async function handleUpdateInteractions() {
  currentEvent.value.interaction = interaction.value

  await updateEvent(currentEvent.value as UpdateEventDto)

  hasUnsavedChanges.value = false
  originalEventState.value = cloneable.deepCopy(currentEvent.value)
  toast.success(t('views.templates.design.designTemplateUpdated'))
}

/****************************************
 * WATCHERS
 *****************************************/
// Watch interaction values for changes to track unsaved changes
watch(
  () => interaction.value,
  () => {

    if (originalEventState.value) {
      const oldEventState = originalEventState.value.interaction
      const currentEventState = interaction.value

      // Compare original and current interaction state
      const differences = findDifferences(oldEventState, currentEventState)

      // Update hasUnsavedChanges based on differences
      hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
    }
  },
  { deep: true }
)
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.interactions') }}
        </h1>
      </div>
      <!-- @container: This class is required by the tailwindcss-container-queries plugin. 
        It marks this element as a container, allowing us to apply different styles 
        based on the size of the container, not just the viewport. 
        You can then use container query variants like @mobile: and @desktop: 
        to style elements inside the container according to its size.
        Breakpoints: https://github.com/tailwindlabs/tailwindcss-container-queries?tab=readme-ov-file#configuration -->
      <!-- TODO: remove false from v-if -->
      <form
        v-if="shouldShowForm && currentEvent.value?.interaction"
        @submit.prevent
        class="prose-ul:list-none @container"
        novalidate
      >
        <div class="grid grid-cols-1 rounded-lg bg-white p-10 shadow xl:gap-16 dark:bg-dark-grey">
          <div class="mb-10 flex flex-col gap-4 lg:gap-8">
            <!-- TODO: correct type EVENT -->
            <VToggleTwoOptions
              v-model="showPublicAnswers"
              :title="t('views.events.interaction.publicAnswers')"
              :input-id="'currentDesignTemplate.publicAnswers'"
              :leftOptionLabel="t('global.on')"
              :leftOptionValue="true"
              :rightOptionLabel="t('global.off')"
              :rightOptionValue="false"
              class="gap-4 self-start"
              @change="() => (showPublicAnswers = !showPublicAnswers)"
              :class="
                !showPublicAnswers &&
                '[&_label:last-child]:text-dark-red [&_label:last-child]:dark:text-light-red'
              "
            />
            <hr class="my-10" />
            <VToggleTwoOptions
              v-model="showStudioguests"
              :title="t('views.events.interaction.studioguests')"
              :input-id="'currentDesignTemplate.studioguests'"
              :leftOptionLabel="t('global.on')"
              :leftOptionValue="true"
              :rightOptionLabel="t('global.off')"
              :rightOptionValue="false"
              class="gap-4 self-start"
              @change="() => (showStudioguests = !showStudioguests)"
              :class="
                !showStudioguests &&
                '[&_label:last-child]:text-dark-red [&_label:last-child]:dark:text-light-red'
              "
            />
            <hr class="my-10" />
            <VToggleTwoOptions
              v-model="showChat"
              :title="t('views.events.interaction.chat')"
              :input-id="'currentDesignTemplate.chat'"
              :leftOptionLabel="t('global.on')"
              :leftOptionValue="true"
              :rightOptionLabel="t('global.off')"
              :rightOptionValue="false"
              class="gap-4 self-start"
              @change="() => (showChat = !showChat)"
              :class="
                !showChat &&
                '[&_label:last-child]:text-dark-red [&_label:last-child]:dark:text-light-red'
              "
            />
          </div>
        </div>
        <div class="relative z-10 flex justify-end gap-8 py-5">
          <div class="flex items-center gap-4">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="large"
              :functionOnClick="() => (openModalDeleteDesign = true)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.update')"
              :disabled="!hasUnsavedChanges"
              size="large"
              :functionOnClick="handleUpdateInteractions"
            />
          </div>
        </div>
      </form>
      <!-- 
      TODO: TEMPORARY SOLUTION UNTIL DB:RESET
      There are old events where 'interaction' is null because they where created before the migration UpdateEventsTable, where the interaction object is added as default.
      So we need to check if the interaction object is available before we can show the form. 
      -->
      <form
        v-else-if="shouldShowForm"
        @submit.prevent
        class="prose-ul:list-none @container"
        novalidate
      >
        <div class="grid grid-cols-1 rounded-lg bg-white p-10 shadow xl:gap-16 dark:bg-dark-grey">
          <div class="mb-10 flex flex-col gap-4 lg:gap-8">
            <!-- TODO: correct type EVENT -->
            <VToggleTwoOptions
              v-model="showPublicAnswers"
              :title="t('views.events.interaction.publicAnswers')"
              :input-id="'currentDesignTemplate.publicAnswers'"
              :leftOptionLabel="t('global.on')"
              :leftOptionValue="true"
              :rightOptionLabel="t('global.off')"
              :rightOptionValue="false"
              class="gap-4 self-start"
              @change="() => (showPublicAnswers = !showPublicAnswers)"
              :class="
                !showPublicAnswers &&
                '[&_label:last-child]:text-dark-red [&_label:last-child]:dark:text-light-red'
              "
            />
            <hr class="my-10" />
            <VToggleTwoOptions
              v-model="showStudioguests"
              :title="t('views.events.interaction.studioguests')"
              :input-id="'currentDesignTemplate.studioguests'"
              :leftOptionLabel="t('global.on')"
              :leftOptionValue="true"
              :rightOptionLabel="t('global.off')"
              :rightOptionValue="false"
              class="gap-4 self-start"
              @change="() => (showStudioguests = !showStudioguests)"
              :class="
                !showStudioguests &&
                '[&_label:last-child]:text-dark-red [&_label:last-child]:dark:text-light-red'
              "
            />
            <hr class="my-10" />
            <VToggleTwoOptions
              v-model="showChat"
              :title="t('views.events.interaction.chat')"
              :input-id="'currentDesignTemplate.chat'"
              :leftOptionLabel="t('global.on')"
              :leftOptionValue="true"
              :rightOptionLabel="t('global.off')"
              :rightOptionValue="false"
              class="gap-4 self-start"
              @change="() => (showChat = !showChat)"
              :class="
                !showChat &&
                '[&_label:last-child]:text-dark-red [&_label:last-child]:dark:text-light-red'
              "
            />
          </div>
        </div>
        <div class="relative z-10 flex justify-end gap-8 py-5">
          <div class="flex items-center gap-4">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="large"
              :functionOnClick="() => (openModalDeleteDesign = true)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.update')"
              :disabled="!hasUnsavedChanges"
              size="large"
              :functionOnClick="handleUpdateInteractions"
            />
          </div>
        </div>
      </form>
      <div v-else>
        <p class="mb-4">{{ t('views.events.interaction.noLivestreamAvaliable') }}</p>
        <I18nRouterLink :to="`/events/${eventId}/video/live`">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.interaction.goToLiveVideo')"
            size="medium"
          >
            <FontAwesomeIcon :icon="['fal', 'clapperboard-play']" />
          </VButton>
        </I18nRouterLink>
      </div>
    </section>
    <!-- Unsaved Changes Modal -->
    <VModal
      :trigger="isUnsavedChangesModalOpen"
      :function-on-close="
        () => {
          isUnsavedChangesModalOpen = false
        }
      "
    >
      <template #modalHeader>
        <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesTitle') }}
        </p>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesMessage') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="
              () => {
                isUnsavedChangesModalOpen = false
              }
            "
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.continue')"
            size="medium"
            :functionOnClick="confirmNavigation"
          />
        </div>
      </template>
    </VModal>
  </MainLayout>
</template>
