<script setup lang="ts">
import { VButton, VModal } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import Player3q from '@/components/video/Player3q.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useStreamingStore } from '@/stores/streaming.store'
import type { components } from '@/types/swagger'

type LiveProject = components['schemas']['LiveProject']

const { t } = useI18n()

/**
 * ----- Stores -----
 */
const streamingStore = useStreamingStore()
const { currentProject, liveProjects } = storeToRefs(streamingStore)
const { getAllLiveProjects, loadProject, resetCurrentProject } = streamingStore

const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent, addLiveProjectToEvent, removeLiveProjectFromEvent } =
  eventStore

/**
 * ----- Refs -----
 */
const isLoading = ref(false)
const isRemoveProjectIdModalOpen = ref(false)
const playerKey = ref(0)

/**
 * ----- Functions -----
 */
const refreshPlayer = () => {
  playerKey.value += 1
}

const connectExistingProject = async (event: Event) => {
  event.preventDefault()
  isLoading.value = true

  const projectId = (event.target as HTMLFormElement).projectId.value

  try {
    await loadProject(+projectId)
    await addLiveProjectToEvent(currentEvent.value.id, +projectId)
    await fetchEventById(currentEvent.value.id)
  } catch (error) {
    console.error('Failed to connect to the existing project', error)
  } finally {
    isLoading.value = false
  }
}

const removeLiveProject = async () => {
  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await removeLiveProjectFromEvent(currentEvent.value.id)
  await fetchEventById(currentEvent.value.id)

  resetCurrentProject()
  await getAllLiveProjects()
}

/**
 * ----- Utility Functions -----
 */
const findFreeProjectIds = (projects: LiveProject[]): number[] => {
  return projects
    .filter((project) => project.assignedAt === null)
    .map((project) => project.projectId3q)
}

/**
 * ----- Lifecycle hooks -----
 */
onMounted(async () => {
  const route = useRoute()
  const eventId = route.params.id as string

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await fetchEventById(+eventId)

  await nextTick()

  if (currentEvent.value.liveProject) {
    await loadProject(currentEvent.value.liveProject.projectId3q)
  }

  await getAllLiveProjects()
})

onUnmounted(() => {
  resetCurrentEvent()
  resetCurrentProject()
})
</script>

<template>
  <MainLayout>
    <div
      class="flex h-full flex-col gap-3 text-dark-grey xl:max-h-[calc(100vh_-_5.5rem)] dark:text-light-grey"
    >
      <h1 class="mb-10 mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">Live</h1>

      <!-- No Live Project connected -->
      <section v-if="!currentProject && !currentEvent.liveProject">
        <h2 class="my-6 text-xl italic">{{ t('views.events.video.noLiveProject3q') }}</h2>
        <hr class="my-5 border-[1px]" />
        <div class="w-fit">
          <p class="mb-1 font-semibold">{{ t('views.events.video.availableProjectIds') }}</p>
          <p>
            <span v-for="(projectId, ind) of findFreeProjectIds(liveProjects)">
              {{ projectId + (ind !== findFreeProjectIds(liveProjects).length - 1 ? ', ' : '') }}
            </span>
          </p>
          <form @submit.prevent="connectExistingProject">
            <div class="my-4 flex items-center gap-3">
              <label for="project-id-input" class="mb-2 block text-lg font-bold">
                {{ t('views.events.video.enterProjectId') }}
              </label>
              <input
                id="project-id-input"
                type="text"
                class="w-36 rounded-md border px-3 py-2 shadow-sm"
                :placeholder="t('views.events.video.enterProjectIdPlaceholder')"
                name="projectId"
              />
              <VButton
                type="submit"
                appearance="default"
                :disabled="isLoading"
                :label="t('global.save')"
                size="large"
              />
            </div>
          </form>
        </div>
      </section>

      <!-- Live Project connected -->
      <section
        v-if="currentProject && currentProject.channels && currentProject.channels[0]"
        class="flex w-full grow flex-col gap-3 xl:grid xl:grow-0 xl:grid-cols-[1fr_1fr] xl:gap-0"
      >
        <div class="h-fit xl:pr-3">
          <p class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelLiveProject3qId') }}
            </span>
            <span
              class="inline-flex w-[62%] items-center gap-2 rounded-t bg-white px-2 py-1 dark:bg-dark-grey"
            >
              {{ currentProject.Id }}
              <font-awesome-icon
                :icon="['fal', 'circle-xmark']"
                class="size-5 cursor-pointer hover:scale-110"
                @click="() => (isRemoveProjectIdModalOpen = true)"
              />
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelLiveProject3q') }}
            </span>
            <span class="inline-block w-[62%] bg-white px-2 py-1 dark:bg-dark-grey">
              {{ currentProject.Label }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelChannelId') }}
            </span>
            <span class="inline-block w-[62%] bg-white px-2 py-1 dark:bg-dark-grey">
              {{ currentProject.channels[0].Id }}
            </span>
          </p>
          <div class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelChannelStatus') }}
            </span>

            <ul
              v-if="currentProject.channels[0].ChannelStatus?.[0]"
              class="inline-block w-[62%] rounded-b bg-white px-2 py-1 text-sm dark:bg-dark-grey"
            >
              <li v-for="(value, key) in currentProject.channels[0].ChannelStatus[0]" :key="key">
                <span class="font-bold">{{ key }}: </span>{{ value }}
              </li>
            </ul>
            <p v-else class="inline-block w-[62%] rounded-b bg-white px-2 py-1 dark:bg-dark-grey">
              {{ t('views.events.video.noChannelStatus') }}
            </p>
          </div>
          <p class="mt-3 w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">rtmp: </span>
            <textarea
              :value="currentProject.channels[0].ingest?.IngestPoints?.[0].ServerURI"
              readonly
              class="w-[62%] resize-none rounded-t px-2 py-1 align-top shadow-[inset_0px_0px_2px_0px_black] outline-none
                dark:bg-dark-grey"
              rows="2"
            />
          </p>
          <p class="inline-block h-fit w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">rtmps: </span>
            <textarea
              :value="currentProject.channels[0].ingest?.IngestPoints?.[0].SecureServerURI"
              readonly
              class="w-[62%] resize-none px-2 py-1 align-top shadow-[inset_0px_0px_2px_0px_black] outline-none
                dark:bg-dark-grey"
              rows="2"
            />
          </p>
          <p class="h-fit w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">Stream Name: </span>
            <textarea
              :value="currentProject.channels[0].ingest?.IngestPoints?.[0].StreamName"
              readonly
              class="w-[62%] resize-none rounded-b px-2 py-1 align-top shadow-[inset_0px_0px_2px_0px_black] outline-none
                dark:bg-dark-grey"
              rows="1"
            />
          </p>
        </div>
        <!-- Video Player -->
        <Player3q
          v-if="currentProject.channels[0].Id"
          :key="playerKey"
          :identifiers="{ channelId: currentProject.channels[0].Id }"
          :embedParams="{}"
          status="pause"
          class="mb-3 h-full min-h-[300px] overflow-hidden xl:h-fit xl:w-full [&>iframe]:aspect-video
            [&>iframe]:h-full [&>iframe]:max-h-[100%] [&>iframe]:w-auto [&>iframe]:max-w-[100%]
            [&>iframe]:rounded [&>iframe]:outline-none xl:[&>iframe]:h-auto xl:[&>iframe]:w-full"
        />
        <div class="flex w-full items-center xl:col-start-2 xl:justify-end">
          <VButton
            type="button"
            appearance="default"
            size="medium"
            :label="t('views.events.video.refreshPlayer')"
            :functionOnClick="refreshPlayer"
          />
        </div>
      </section>

      <!-- Channel Events are not used right now     -->
      <!--      <section v-if="currentProject?.channels">-->
      <!--        <span class="font-bold">Channel Events: </span>-->
      <!--        {{ currentProject.channels[0].channelEvents }}-->
      <!--      </section>-->
    </div>

    <!-- Remove Project ID Confirm Modal -->
     <!-- as the class sdn-display from the 3QPlayer has z-index=997 we need to add manually a higher z-index to this modal -->
    <template #modal>
      <VModal
        :trigger="isRemoveProjectIdModalOpen"
        @update:trigger="isRemoveProjectIdModalOpen = $event"
        :class="'z-999'"
      >
        <template #modalHeader>
          <p class="text-center text-xl text-dark-grey dark:text-light-grey">
            {{ t('views.events.video.confirmModalHeader') }}
          </p>
        </template>
        <template #modalBody>
          <p class="my-5 text-dark-grey dark:text-light-grey">
            {{ t('views.events.video.confirmModalText') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isRemoveProjectIdModalOpen = false
                }
              "
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.confirm')"
              size="medium"
              :functionOnClick="
                () => {
                  removeLiveProject()
                  isRemoveProjectIdModalOpen = false
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
