<script setup lang="ts">
import { VButton, VInput } from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { type ComponentPublicInstance, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import LoginLayout from '@/layouts/LoginLayout.vue'
import { useAuthStore } from '@/stores/auth.store'
import { usePasswordVisibility } from '@/utils/passwordVisibility'

/**
 * Custom Interfaces
 */
type VInputExposed = {
  inputRef: HTMLInputElement | null
}

type LoginForm = {
  email: string
  password: string
}

/**
 * Stores & i18n
 */
const router = useRouter()
const authStore = useAuthStore()
const { t, locale } = useI18n()

/**
 * Refs
 */
const emailInputComponent = ref<ComponentPublicInstance<VInputExposed> | null>(null)
const passwordInputComponent = ref<ComponentPublicInstance<VInputExposed> | null>(null)
const loginForm = ref<LoginForm>({ email: '', password: '' })
const isLoading = ref(false) // used to disable the form while submitting

/**
 * Composables
 */
const { showPassword, togglePasswordVisibility } = usePasswordVisibility()

/**
 * Submit Function
 */
const onSubmit = async () => {
  isLoading.value = true

  try {
    /**
     * To prevent uuid mismatches between the csrf_token and the access_token we delay the login by 250ms
     * The mismatch only happens when password managers autofill the login form and instantly submit it
     */
    await new Promise((resolve) => setTimeout(resolve, 250))

    await authStore.login(loginForm.value.email, loginForm.value.password)

    await nextTick()

    if (authStore.user) {
      await router.push({ name: 'events', params: { locale: locale.value } })
    } else {
      console.log('Unauthorized. Redirecting to login...')
    }
  } catch (error) {
    console.error('Login failed:', error)
  } finally {
    isLoading.value = false
  }
}

/**
 * Hooks
 */
onMounted(async () => {
  await nextTick(() => {
    /**
     * Access the exposed inputRef from the VInput component and focus it when the site is loaded
     */
    emailInputComponent.value?.inputRef?.focus()
  })
})

/**
 * Watchers
 *
 * These watchers enable password manager autocomplete. Sometimes the password manager is too quick for
 * the usual reactivity with v-model. With these watchers we can update the loginForm values directly
 */
watch(
  () => emailInputComponent.value?.inputRef?.value,
  (newVal) => {
    loginForm.value.email = newVal ?? ''
  }
)

watch(
  () => passwordInputComponent.value?.inputRef?.value,
  (newVal) => {
    loginForm.value.password = newVal ?? ''
  }
)
</script>

<template>
  <LoginLayout>
    <form
      class="text-tc-light-grey mt-4 flex w-[300px] flex-col items-end gap-1"
      @submit.prevent="onSubmit"
      autocomplete="on"
    >
      <!-- The min-h-24 is needed so that the errors from a wrong email address don't make the form boxes jump -->
      <VInput
        v-model="loginForm.email"
        type="email"
        input-id="loginEmail"
        label="Email"
        placeholder="login@email.com"
        :disabled="isLoading"
        ref="emailInputComponent"
        :error-message="t('global.mustBeEmail')"
        autocomplete="email"
        class="min-h-24"
      />
      <div class="relative w-full">
        <VInput
          v-model="loginForm.password"
          :type="showPassword ? 'text' : 'password'"
          input-id="loginPassword"
          label="Password"
          placeholder="Password"
          :disabled="isLoading"
          ref="passwordInputComponent"
        />
        <VButton
          type="button"
          appearance="empty"
          size="medium"
          :functionOnClick="togglePasswordVisibility"
          class="absolute right-1 top-1"
        >
          <FontAwesomeIcon
            v-if="showPassword"
            :icon="['fal', 'eye']"
            class="absolute right-1 top-1 [&_path]:fill-dark-grey [&_path]:dark:fill-light-grey"
          />
          <FontAwesomeIcon
            v-else
            :icon="['fal', 'eye-slash']"
            class="absolute right-1 top-1 [&_path]:fill-dark-grey [&_path]:dark:fill-light-grey"
          />
        </VButton>
      </div>
      <VButton
        type="submit"
        appearance="default"
        label="Login"
        :disabled="isLoading"
        size="small"
        :className="'mt-2'"
      />
    </form>
  </LoginLayout>
</template>
