<script setup lang="ts">
import { AudienceButton } from '@techcast/histoire'

import CloudImage from '@/components/utils/CloudImage.vue'
import { useEventSetup } from '@/composables/event/useEventSetup'
import { getImageName } from '@/utils/getImageName'

const props = defineProps<{
  access: boolean
  source: any
  currentEventLanguage: string
  description?: string
  startDate: string
  startHour: string
  endHour: string
  previewImage?: { public_id: string }
  logo?: { public_id: string }
  format?: string
  entityType?: string
  view?: string
  isPreview?: boolean
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t } = useEventSetup()
</script>

<template>
  <section
    :id="
      source && source.url && entityType === undefined
        ? `${source.url[currentEventLanguage]}-${source.id}-description`
        : source && source.url && entityType === 'design'
          ? `${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-description`
          : `${access ? 'access' : 'no-access'}-${view}-template-description`
    "
    class="container mx-auto mt-5 flex h-full w-full scroll-mt-16 flex-col gap-5 p-5 pb-10 md:gap-14 md:py-16"
    :class="isPreview && '@mobile:!py-8 @desktop:!py-10'"
  >
    <div
      v-if="!access"
      class="flex flex-col justify-between gap-5 md:flex-row"
      :class="isPreview && '@mobile:!flex-col @desktop:!flex-row'"
    >
      <div
        class="flex flex-row items-center justify-start md:flex-col md:items-start md:justify-between"
        :class="
          isPreview &&
          `@mobile:!flex-row @mobile:!justify-start @desktop:min-w-fit @desktop:!flex-col
          @desktop:!justify-between`
        "
      >
        <div>
          <CloudImage
            v-if="logo"
            :imageName="logo['public_id']"
            class="h-full max-h-[56px] w-full max-w-[152px] md:max-h-[100px] md:max-w-[275px]"
            :class="
              isPreview && '@mobile:!max-h-[56px] @mobile:!max-w-[152px] @desktop:!max-h-[100px]'
            "
            :alt="getImageName(logo['public_id'])"
          />
        </div>
        <div
          class="flex flex-col items-start md:ml-0 md:gap-2 md:border-none md:pl-0"
          :class="{
            'ml-3 border-l border-black pl-3': logo,
            [`@mobile:!ml-3 @mobile:!flex-col @mobile:!gap-0 @mobile:!border-solid @mobile:!pl-3 @desktop:!ml-0
            @desktop:!gap-2 @desktop:!border-none @desktop:!pl-0`]: isPreview
          }"
        >
          <div
            class="flex flex-row items-center gap-2 text-sm font-bold md:gap-4 md:text-xl"
            :class="isPreview && '@mobile:!text-base'"
          >
            <font-awesome-icon :icon="['fal', 'calendar']" class="text-[var(--secondary-color)]" />
            <span>{{ startDate }}</span>
          </div>
          <div class="flex flex-row items-center gap-2 text-sm md:gap-4">
            <font-awesome-icon
              :icon="['fal', 'clock']"
              class="text-[var(--secondary-color)] text-sm md:text-xl"
              :class="isPreview && '@mobile:!text-base'"
            />
            <span>{{ startHour.replace(' Uhr', '') }} - {{ endHour }}</span>
          </div>
        </div>
      </div>
      <div
        class="flex w-full justify-end lg:w-3/4"
        :class="isPreview && '@mobile:!w-full @desktop:!w-3/4'"
      >
        <div :class="format === 'rounded' ? 'overflow-hidden rounded-3xl' : 'rounded-none'">
          <CloudImage
            v-if="previewImage"
            :imageName="previewImage['public_id']"
            class="max-h-[473px] max-w-full"
            :alt="getImageName(previewImage['public_id'])"
          />
          <!-- Fallback image -->
          <img
            v-else
            class="aspect-video w-full"
            src="https://images.unsplash.com/photo-1540575467063-178a50c2df87?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            :alt="source.name[currentEventLanguage] || 'Event placeholder'"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col items-start gap-4 md:gap-6" :class="isPreview && '@desktop:!gap-6'">
      <h1
        class="text-[var(--primary-color)] text-2.5xl font-semibold leading-10 md:text-6xl md:leading-11"
        :class="{
          'md:text-4.5xl': access,
          '@mobile:!text-2xl @mobile:!leading-10 @desktop:!text-4xl @desktop:!leading-11': isPreview
        }"
      >
        {{ source.name[currentEventLanguage] }}
      </h1>
      <p
        v-if="source.subtitle?.[currentEventLanguage]"
        class="text-xl md:leading-10"
        :class="{ 'md:text-2.5xl': !access, '@mobile:!text-xl': !access && isPreview }"
      >
        {{ source.subtitle?.[currentEventLanguage] }}
      </p>
      <div
        v-if="access && description"
        class="leading-7"
        :class="isPreview && '@desktop:!text-sm'"
        v-html="description"
      ></div>
      <AudienceButton
        v-if="!access && source.protected"
        :linkTo="
          source && source.url && entityType === undefined
            ? `#${source.url[currentEventLanguage]}-${source.id}-registration`
            : source && source.url && entityType === 'design'
              ? `#${source.url[currentEventLanguage]}-${source.id}-${access ? 'access' : 'no-access'}-${view}-registration`
              : `#${access ? 'access' : 'no-access'}-${view}-template-registration`
        "
        :appearance="format || 'sharp'"
        :label="t('client.toRegistration')"
        :className="'bg-[var(--button-background-color)] hover:bg-[var(--button-hover-color)] text-[var(--button-text-color)]'"
      />
    </div>
  </section>
</template>
