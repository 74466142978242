<script setup lang="ts">
import { useFavicon, useFullscreen, useTitle } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useEventSetup } from '@/composables/event/useEventSetup'
import { useCustomHead } from '@/composables/head/useCustomHead'
import { cloneable } from '@/composables/useClone'
import router from '@/router'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import { useDesignsStore } from '@/stores/designs.store'
import { previewEvent } from '@/stores/objects/previewEvent'
import type { DesignColorVariables } from '@/types/DesignColorVariables'
import { getFormattedDate } from '@/utils/getFormattedDate'
import { updateColors } from '@/utils/updateClientFrontendColors'

import AgendaSection from './sections/AgendaSection.vue'
import DescriptionSection from './sections/DescriptionSection.vue'
import DownloadsSection from './sections/DownloadsSection.vue'
import EventMenuBar from './sections/EventMenuBar.vue'
import FooterSection from './sections/FooterSection.vue'
import HeaderBar from './sections/HeaderBar.vue'
import LinksSection from './sections/LinksSection.vue'
import SpeakersSection from './sections/SpeakersSection.vue'
import VideoSection from './sections/VideoSection.vue'

/****************************************
 * API KEY
 *****************************************/
const apiKey = import.meta.env.VITE_PUBLIC_USER_API_KEY

/****************************************
 * ROUTER
 *****************************************/
const route = useRoute()

/****************************************
 * COMPOSABLES
 *****************************************/
const { i18n, t, eventStore, eventId, currentEvent, currentEventLanguage, layoutComponent } =
  useEventSetup()

/****************************************
 * STORES
 *****************************************/
const designStore = useDesignsStore()
const { currentDesign } = storeToRefs(designStore)
const { fetchDesignById, resetCurrentDesign } = designStore
const designTemplateStore = useDesignTemplatesStore()
const { currentDesignTemplate } = storeToRefs(designTemplateStore)

/****************************************
 * PROPS
 *****************************************/
const props = withDefaults(
  defineProps<{
    isPreview: boolean // Prop to determine if it's a preview (child component) or acts as a site
    view?: string // Prop for view type (e.g., mobile, desktop)
    entityType?: string // Type of the entity (e.g., design, designTemplate)
  }>(),
  {
    isPreview: false
  }
)

/****************************************
 * CUSTOM HEADERS
 *
 * @deactivated until we have SSR support
 *****************************************/
// useCustomHead({
//   title: computed(() => currentEvent.value?.name?.[currentEventLanguage.value] ?? ''),
//   description: computed(() => currentEvent.value?.description?.[currentEventLanguage.value] ?? ''),
//   imageUrl: computed(() => currentEvent.value?.previewImages?.[0]?.secure_url ?? ''),
//   url: computed(() => route.fullPath)
// })

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
const eventName = computed(() => {
  const name = route.params.eventName || null
  return name ? name.replace(/-$/, '') : null // Remove last '-' character if it exists
})
const isPreviewDesign = props.entityType === 'design'
const isPreviewDesignTemplate = props.entityType === 'designTemplate'
// Determine the source of data (currentEvent or designTemplate) based on the entity type
const source = computed(() =>
  isPreviewDesignTemplate
    ? {
        ...previewEvent,
        design: { ...currentDesignTemplate.value, logos: currentDesignTemplate.value.logos }
      }
    : { ...currentEvent.value, design: currentDesign.value }
)
const menuItems = computed(() =>
  [
    { key: 'info', condition: (source.value.vodFileIds ?? []).length > 0 },
    { key: 'description', condition: !isDescriptionEmpty(source.value.description?.[currentEventLanguage.value]) },
    { key: 'agenda', condition: (source.value.agendaItems ?? []).length > 0 },
    { key: 'speakers', condition: (source.value.speakers ?? []).length > 0 },
    { key: 'links', condition: (source.value.links ?? []).length > 0 },
    { key: 'downloads', condition: (source.value.downloads ?? []).length > 0 }
  ]
    .filter((item) => item.condition)
    .map((item) => item.key)
)
const isDescriptionAvailable = computed(() => {
  const description = source.value.description?.[currentEventLanguage.value]
  return !isDescriptionEmpty(description) // True if the description is NOT empty
})

/****************************************
 * REFS
 *****************************************/
const showVideoSection = ref(false)
const { isFullscreen } = useFullscreen()

/****************************************
 * LIFECYCLE HOOKS
 *****************************************/
onMounted(async () => {
  if (!apiKey) {
    console.error('API key is missing')
    return
  }

  if (eventId.value && !isPreviewDesignTemplate) {
    // Set the favicon and the title of the page
    const icon = useFavicon()
    const title = useTitle()

    /**
     * Redirect to the 404 page if the event is not found
     */
    try {
      // Update the currentEvent reference with the fetched event
      await eventStore.fetchEventById(+eventId.value, true, apiKey)
    } catch (error) {
      console.error("Can't find event:", error)
      await router.push({ name: 'not-found' })
    }

    showVideoSection.value = true

    if (currentEvent.value.design?.id) {
      await fetchDesignById(+currentEvent.value.design.id, apiKey)

      if (route.name === 'event-show') {
        // Set the favicon dinamically with the event logo
        icon.value = currentDesign.value.logos?.[0]?.secure_url
        // Set the title of the page with the event name
        title.value = currentEvent.value.name?.[currentEventLanguage.value]
      }
    }
  } else if (isPreviewDesignTemplate) {
    showVideoSection.value = true
  }

  updateColors(source.value.design.colors as any as DesignColorVariables)

  // Look for the current locale of this event: if the event has a URL with the same name as the event, it means that the event is available in this language
  if (currentEvent.value && currentEvent.value.url) {
    for (const key of Object.keys(currentEvent.value.url)) {
      const eventUrlValue = currentEvent.value.url[key]
      const eventNameValue =
        typeof eventName.value === 'string' ? eventName.value.replace(/-$/, '') : eventName.value
      if (cloneable.isEqual(eventUrlValue, eventNameValue)) {
        // console.log('Event language found: ', key)

        currentEventLanguage.value = key
      }
    }
  }
})

onUnmounted(() => {
  resetCurrentDesign()
})

/****************************************
 * METHODS
 *****************************************/
function isDescriptionEmpty(description?: string): boolean {
  if (!description) return true // Handle null, undefined, or empty strings
  const trimmedDescription = description.trim()
  return trimmedDescription === '<p><br></p>' || trimmedDescription === '' // Check for default empty value because it's a WYSIWYG field
}
</script>

<template>
  <!-- @container: This class is required by the tailwindcss-container-queries plugin. 
  It marks this element as a container, allowing us to apply different styles 
  based on the size of the container, not just the viewport. 
  You can then use container query variants like @mobile: and @desktop: 
  to style elements inside the container according to its size.
  Breakpoints: https://github.com/tailwindlabs/tailwindcss-container-queries?tab=readme-ov-file#configuration -->
  <div
    v-if="currentEvent"
    :class="[
      'client-container',
      '@container',
      'text-audience-dark-grey',
      'bg-white',
      'flex',
      'flex-col',
      'min-h-screen',
      {
        'preview-wrapper': isPreviewDesign || isPreviewDesignTemplate,
        mobile: view === 'mobile',
        'fixed h-screen w-screen overflow-hidden': isFullscreen
      }
    ]"
  >
    <HeaderBar :class="isFullscreen && 'hidden'" />
    <div
      v-if="showVideoSection"
      class="container mx-auto flex h-full w-full grow scroll-mt-16 flex-col gap-5 p-5 md:py-16"
      :class="[
        isPreview
          ? isFullscreen
            ? 'h-full w-full'
            : '@mobile:!min-h-0 @mobile:!p-5'
          : 'xl:min-h-[calc(100vh-93px)]'
      ]"
    >
      <VideoSection
        :apiKey="apiKey"
        :access="true"
        :source="source"
        :currentEventLanguage="currentEventLanguage"
        :startDate="getFormattedDate(currentEventLanguage, source.startDate ?? '', false, true)"
        :startHour="getFormattedDate(currentEventLanguage, source.startDate ?? '', true, false)"
        :endHour="getFormattedDate(currentEventLanguage, source.endDate ?? '', true, false)"
        :previewImage="source.previewImages?.[0]"
        :logo="source.design?.logos?.[0]"
        :format="source.design?.format"
        :entityType="entityType"
        :view="view"
        :isPreview="isPreview"
        :isFullscreen="isFullscreen"
        :rta-icons-position="source.design?.positions.rtaIcons?.position"
      />
    </div>
    <EventMenuBar
      v-if="menuItems.length > 1"
      :access="true"
      :menuItems="menuItems"
      :entityType="entityType"
      :view="view"
      :class="[isPreview && '@mobile:mt-5', isFullscreen && 'hidden']"
    />
    <DescriptionSection
      v-if="isDescriptionAvailable"
      :access="true"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :description="source.description?.[currentEventLanguage]"
      :startDate="getFormattedDate(currentEventLanguage, source.startDate ?? '', false, true)"
      :startHour="getFormattedDate(currentEventLanguage, source.startDate ?? '', true, false)"
      :endHour="getFormattedDate(currentEventLanguage, source.endDate ?? '', true, false)"
      :previewImage="source.previewImages?.[0]"
      :logo="source.design?.logos?.[0]"
      :format="source.design?.format"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
      :class="isFullscreen && 'hidden'"
    />
    <AgendaSection
      v-if="source?.agendaItems && source?.agendaItems.length > 0"
      :access="true"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :agendaItems="source?.agendaItems ?? []"
      :speakers="source?.speakers ?? []"
      :format="source.design?.format"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
      :class="isFullscreen && 'hidden'"
    />
    <SpeakersSection
      v-if="source?.speakers && source?.speakers.length > 0"
      :access="true"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :speakers="source?.speakers ?? []"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
      :class="isFullscreen && 'hidden'"
    />
    <LinksSection
      v-if="source?.links && source?.links.length > 0"
      :access="true"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :links="source?.links ?? []"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
      :class="isFullscreen && 'hidden'"
    />
    <DownloadsSection
      v-if="source?.downloads && source?.downloads.length > 0"
      :access="true"
      :source="source"
      :currentEventLanguage="currentEventLanguage"
      :downloads="source?.downloads ?? []"
      :entityType="entityType"
      :view="view"
      :isPreview="isPreview"
      :class="isFullscreen && 'hidden'"
    />
    <FooterSection :footerData="source.design?.footer" :class="isFullscreen && 'hidden'" />
  </div>
</template>
