import { nextTick } from 'vue'
import type { DesignColorVariables } from '@/types/DesignColorVariables'

// Function to convert hex to rgba and add opacity (Button background color)
function convertHexToRgbaWithOpacity(hex: string | undefined, opacity: number): string {
	// console.log('HEX:', hex);

	// Ensure hex is a valid string
	if (!hex || typeof hex !== 'string') {
		// console.error('Invalid hex value:', hex)
		return `rgba(0, 0, 0, ${opacity})` // Fallback to a default color
	}

	// Remove the leading '#' if present
	hex = hex.replace('#', '')

	// Handle shorthand hex (e.g., #FFF)
	if (hex.length === 3) {
		hex = hex.split('').map((char) => char + char).join('')
	}

	// Ensure the hex code is valid (i.e., 6 characters long)
	if (hex.length !== 6) {
		// console.error('Invalid hex length:', hex)
		return `rgba(0, 0, 0, ${opacity})` // Fallback to a default color
	}

	const r = parseInt(hex.substring(0, 2), 16)
	const g = parseInt(hex.substring(2, 4), 16)
	const b = parseInt(hex.substring(4, 6), 16)

	return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

/**
 * Default color variables for the design template if the user has not yet saved a new design template
 */
export const defaultDesignColorVariables: Record<string, string> = {
	primaryColor: '#000',
	secondaryColor: '#000',
	buttonTextColor: '#fff',
	buttonBackgroundColor: '#000'
}

export function updateColors(colors: DesignColorVariables) {

	nextTick(() => {
		document.documentElement.style.setProperty(
			'--primary-color',
			colors.primaryColor
		)
		document.documentElement.style.setProperty(
			'--primary-background-color',
			colors.primaryColor
		)
		document.documentElement.style.setProperty(
			'--secondary-color',
			colors.secondaryColor
		)
		document.documentElement.style.setProperty(
			'--button-background-color',
			colors.buttonBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--button-text-color',
			colors.buttonTextColor
		)
		document.documentElement.style.setProperty(
			'--border-secondary-color',
			colors.secondaryColor
		)
		document.documentElement.style.setProperty(
			'--button-hover-color',
			colors.buttonBackgroundColor ? convertHexToRgbaWithOpacity(colors.buttonBackgroundColor, 0.85) : convertHexToRgbaWithOpacity(defaultDesignColorVariables.buttonBackgroundColor, 0.85) // 85% opacity
		)
		document.documentElement.style.setProperty(
			'--secondary-background-color-opacity',
			colors.secondaryColor ? convertHexToRgbaWithOpacity(colors.secondaryColor, 0.2) : convertHexToRgbaWithOpacity(defaultDesignColorVariables.secondaryColor, 0.2) // 20% opacity
		)
	})
}


