<script setup lang="ts">
import { VButton, VImageUpload, VProgress } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useEventsStore } from '@/stores/events.store'
import { useStreamingStore } from '@/stores/streaming.store'

const { t } = useI18n()

/**
 * ----- Stores -----
 */
const streamingStore = useStreamingStore()
const { uploadStatus, uploadPercentage, uploadLocation, uploadFileId } = storeToRefs(streamingStore)

const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent, addVodFileToEvent } = eventStore

/**
 * ----- Emits -----
 */
const emit = defineEmits(['uploadCompleted'])

/**
 * ----- Data -----
 */
const CHUNK_UPLOAD_THRESHOLD = 25 * 1024 * 1024 // 25MB

const file = ref<File | null>(null)

/**
 * ----- Functions -----
 */
const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  file.value = target.files ? target.files[0] : null
}

const startUpload = async () => {
  if (!file.value) return

  try {
    await streamingStore.getUploadLocation(file.value.name, file.value.type)

    if (uploadLocation.value) {
      if (file.value.size > CHUNK_UPLOAD_THRESHOLD) {
        /**
         * If the file is larger than 100MB, we will upload it in chunks.
         */
        await streamingStore.uploadFileInChunks(file.value)
      } else {
        /**
         * If the file is smaller or equal to 100MB, we will upload it as a whole.
         */
        await streamingStore.uploadAsWholeFile(file.value)
      }
    }

    if (uploadFileId.value && currentEvent.value) {
      await addVodFileToEvent(currentEvent.value.id, uploadFileId.value)
      file.value = null

      emit('uploadCompleted')
    }
  } catch (error) {
    console.error('Upload failed:', error)
  }
}

const cancelUpload = () => {
  streamingStore.cancelUpload()
  file.value = null
}

const cancelFile = () => {
  file.value = null
}

/**
 * Resume upload function for chunked uploads.
 */
// const resumeUpload = async () => {
//   if (file.value && file.value.size > CHUNK_UPLOAD_THRESHOLD) {
//     try {
//       await streamingStore.resumeUpload(file.value)
//
//       // This code will run after the resumed upload has finished
//       if (uploadFileId.value && currentEvent.value) {
//         await addVodFileToEvent(currentEvent.value.id, uploadFileId.value)
//         file.value = null
//
//         emit('uploadCompleted')
//       }
//     } catch (error) {
//       console.error('Resume upload failed:', error)
//     }
//   }
// }

// const pauseUpload = () => {
//   streamingStore.pauseUpload()
// }

/**
 * ----- Watchers -----
 */
watch(file, (value, oldValue) => {
  if (value && value !== oldValue) {
    uploadStatus.value = ''
  }
})

/**
 * ----- Lifecycle hooks -----
 */
onMounted(async () => {
  const route = useRoute()
  const eventId = route.params.id as string

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await fetchEventById(+eventId)
})

onUnmounted(() => {
  resetCurrentEvent()
})
</script>

<template>
  <section>
    <div class="rounded-lg bg-white px-3 py-[18px] dark:bg-dark-grey">
      <!-- Video Upload Component -->
      <VImageUpload
        v-if="!file"
        v-model="file"
        input-id="upload-video"
        :outer-label="t('views.events.video.uploadNewVod')"
        :inner-label="t('views.events.downloadsLinks.chooseFile')"
        :allowed-file-types="'video/*'"
        @change="handleFileChange"
      />
      <!-- File Display after file is selected -->
      <div v-if="file" class="flex flex-col gap-2">
        <p class="text-base font-bold text-dark-grey dark:text-light-grey">
          {{ t('views.events.video.selectedVideo') }}
        </p>
        <div
          class="flex h-48 w-full flex-col items-center justify-center gap-3 rounded-md border border-dashed
            border-misty-grey/20 bg-ice-grey dark:border-light-grey/20 dark:bg-misty-grey dark:text-light-grey
            dark:hover:border-light-grey/50 dark:hover:text-light-grey
            dark:hover:shadow-[0_0_5px_0_rgba(255,255,255,0.2)]"
        >
          <p class="flex items-center gap-3">
            <font-awesome-icon :icon="['fal', 'photo-film']" class="size-10" />
            <span>{{ file.name }}</span>
          </p>
        </div>
      </div>
      <div class="mt-3 flex items-center justify-between gap-3">
        <!-- Progress while uploading-->
        <VProgress
          v-if="['uploading', 'paused'].includes(uploadStatus)"
          :value="uploadPercentage"
        />
        <!-- Success Message -->
        <p v-if="uploadStatus === 'completed'" class="text-dark-green">
          {{ t('views.events.video.uploadFinished') }}
        </p>
        <!-- Error Message -->
        <p v-if="uploadStatus === 'error'" class="text-dark-red">
          {{ t('views.events.video.uploadError') }}
        </p>
        <div v-else />
        <div class="flex gap-3">
          <!-- Start Upload -->
          <VButton
            v-if="uploadStatus !== 'uploading' && uploadStatus !== 'paused'"
            type="button"
            appearance="default"
            :disabled="!file || uploadStatus === 'completed'"
            :label="t('views.events.video.startUpload')"
            size="medium"
            :functionOnClick="startUpload"
          />
          <!-- Cancel Upload -->
          <VButton
            v-if="uploadStatus === 'uploading' || uploadStatus === 'paused'"
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="cancelUpload"
          />
          <!-- Cancel File -->
          <VButton
            v-if="uploadStatus === '' && file"
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="cancelFile"
          />
          <!--  Pause Functionality -->
          <!--          <VButton-->
          <!--            v-if="uploadStatus === 'uploading'"-->
          <!--            type="button"-->
          <!--            appearance="default"-->
          <!--            :label="'⏸' + '&nbsp;&nbsp;&nbsp;' + t('views.events.video.pauseUpload')"-->
          <!--            size="medium"-->
          <!--            :functionOnClick="pauseUpload"-->
          <!--          />-->
          <!--          <VButton-->
          <!--            v-if="uploadStatus === 'paused'"-->
          <!--            type="button"-->
          <!--            appearance="default"-->
          <!--            :label="'▶' + '&nbsp;&nbsp;&nbsp;' + t('views.events.video.resumeUpload')"-->
          <!--            size="medium"-->
          <!--            :functionOnClick="resumeUpload"-->
          <!--          />-->
        </div>
      </div>
    </div>
  </section>
</template>
