import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { components } from '@/types/swagger'

type DesignTemplate = components['schemas']['DesignTemplate']
type FooterItemClass = components["schemas"]["FooterItemClass"][]

export const emptyDesignTemplate: Partial<DesignTemplate> = {
  id: undefined,
  name: 'Neues Design Template',
  colors: {} as DesignColorVariables,
  positions: {
    rtaIcons: {
      position: 'bottom' as 'bottom' | 'top',
    }
  },
  footer: [
    {
      position: 'center',
      text: { 
        de: '<p></p>',
        en: '<p></p>'
      }
    }
  ] as FooterItemClass,
  logos: [],
  isMultilanguage: false,
  format: 'sharp',
  entityType: 'designTemplate'
}
