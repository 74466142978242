<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useStreamingStore } from '@/stores/streaming.store'
import type { components } from '@/types/swagger'
import { formatBytes } from '@/utils/formatBytes'

type UsageTotalsDto = components['schemas']['UsageTotalsDto']
type MonthlyUsageDto = components['schemas']['MonthlyUsageDto']

const { t } = useI18n()

/**
 * ----- Stores -----
 */
const streamingStore = useStreamingStore()
const { getEventUsage, getEventMonthlyUsage } = streamingStore

const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent } = eventStore

/**
 * ----- Refs -----
 */
const projectUsage = ref<UsageTotalsDto | null>(null)
const projectMonthlyUsage = ref<MonthlyUsageDto[]>([])

/**
 * ----- Lifecycle hooks -----
 */
onMounted(async () => {
  const route = useRoute()
  const eventId = route.params.id as string

  await fetchEventById(+eventId)

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await nextTick()

  projectUsage.value = await getEventUsage(currentEvent.value.id)
  projectMonthlyUsage.value = await getEventMonthlyUsage(currentEvent.value.id)
})

onUnmounted(() => {
  resetCurrentEvent()
})
</script>

<template>
  <MainLayout>
    <h1
      class="mb-10 mr-8 text-[32px] font-bold text-dark-grey lg:text-[42px] xl:text-[58px] dark:text-light-grey"
    >
      {{ t('views.events.video.videoOverview') }}
    </h1>
    <!-- Total Usage -->
    <section class="text-dark-grey xl:flex xl:flex-wrap dark:text-light-grey">
      <h2 class="mb-3 text-2xl font-bold xl:w-full">
        {{ t('views.events.video.totalUsageEvent') }}
      </h2>
      <!-- Live -->
      <div
        class="inline-block w-full rounded-t bg-white px-2 py-1 xl:w-[50%] xl:rounded-bl xl:rounded-tl
          xl:rounded-tr-none dark:bg-dark-grey"
      >
        <h3 class="text-xl font-bold">Live</h3>
        <hr class="my-1" />
        <ul v-if="projectUsage" class="text-md inline-block">
          <li v-for="(value, key) in projectUsage.live" :key="key">
            <template v-if="key !== 'drmLicenses'">
              <p v-if="['bandwidth', 'storage'].includes(key)">
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ formatBytes(value) }}
              </p>
              <p v-else>
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ value }}
              </p>
            </template>
          </li>
        </ul>
      </div>
      <!-- VOD -->
      <div
        class="inline-block w-full rounded-b bg-white px-2 py-1 xl:w-[50%] xl:rounded-bl-none xl:rounded-br
          xl:rounded-tr dark:bg-dark-grey"
      >
        <h3 class="mb-1 text-xl font-bold">Video on Demand</h3>
        <hr class="my-1" />
        <ul v-if="projectUsage" class="text-md inline-block">
          <li v-for="(value, key) in projectUsage.vod" :key="key">
            <template v-if="key !== 'drmLicenses'">
              <p v-if="['bandwidth', 'storage'].includes(key)">
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ formatBytes(value) }}
              </p>
              <p v-else>
                <span class="font-bold">
                  {{ `${key}: ` }}
                </span>
                {{ value }}
              </p>
            </template>
          </li>
        </ul>
      </div>
    </section>
    <hr class="my-3" />
    <!-- Usage per month -->
    <section class="text-dark-grey dark:text-light-grey">
      <h2 class="mb-3 text-2xl font-bold">
        {{ t('views.events.video.usagePerMonthEvent') }}
      </h2>
      <div
        class="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr] overflow-x-auto rounded-lg bg-white px-3 py-[18px]
          text-sm leading-tight dark:bg-dark-grey [&>*]:min-w-24"
      >
        <p class="mb-2 pr-3 font-bold">Month</p>
        <p class="mb-2 pr-3 font-bold">Bandwidth</p>
        <p class="mb-2 pr-3 font-bold">Storage</p>
        <p class="mb-2 pr-3 font-bold">Encoding Minutes</p>
        <p class="mb-2 pr-3 font-bold">Transcoding Minutes</p>
        <p class="mb-2 pr-3 font-bold">AI Minutes</p>
        <p class="mb-2 pr-3 font-bold">Users</p>
        <template v-for="month in projectMonthlyUsage">
          <hr class="col-span-full my-1" />
          <div class="flex justify-between gap-1 pr-5">
            <p class="flex flex-col">
              <span class="self-center">{{ month.month.split('-')[1] }}</span>
              <span class="text-sm">{{ month.month.split('-')[0] }}</span>
            </p>
            <p class="flex flex-col font-bold">
              <span>Live:</span>
              <span>Vod:</span>
            </p>
          </div>
          <p class="flex flex-col">
            <span>
              {{ formatBytes(month.live.bandwidth) }}
            </span>
            <span>
              {{ formatBytes(month.vod.bandwidth) }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ formatBytes(month.live.storage) }}
            </span>
            <span>
              {{ formatBytes(month.vod.storage) }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.encodingMinutes }}
            </span>
            <span>
              {{ month.vod.encodingMinutes }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.transcodingMinutes }}
            </span>
            <span>
              {{ month.vod.transcodingMinutes }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.videoAIMinutes }}
            </span>
            <span>
              {{ month.vod.videoAIMinutes }}
            </span>
          </p>
          <p class="flex flex-col">
            <span>
              {{ month.live.users }}
            </span>
            <span>
              {{ month.vod.users }}
            </span>
          </p>
        </template>
        <template v-if="projectMonthlyUsage.length === 0">
          <hr class="col-span-full my-1" />
          <p class="col-span-full my-1 text-center text-base">
            {{ t('views.events.video.noUsageData') }}
          </p>
        </template>
      </div>
    </section>
  </MainLayout>
</template>
