<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'

import { useStreamingStore } from '@/stores/streaming.store'
import type { PlayerIdentifiers, PlayerStatus } from '@/types/Custom3qTypes'
import type { components as components3q } from '@/types/types3q'

type embedParams = components3q['schemas']['FileEmbedParams']

const props = defineProps<{
  identifiers: PlayerIdentifiers
  embedParams?: embedParams
  /**
   * This can be used to control the player status from outside.
   */
  status: PlayerStatus
}>()

const emit = defineEmits<{
  'update-timestamp': [{ timestamp: number; delay: number }]
}>()

const streamingStore = useStreamingStore()
const { getLivePlayoutId, getVodPlayoutId } = streamingStore

/**
 * Load the js3q script and append it to the document head.
 */
const loadJs3qScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById('js3q-script')) {
      resolve()
      return
    }

    const script = document.createElement('script')
    script.id = 'js3q-script'
    script.src = 'https://player.3qsdn.com/js3q.latest.js'

    script.onload = () => resolve()
    script.onerror = () => reject(new Error('Failed to load js3q script'))

    document.head.appendChild(script)
  })
}

/**
 * 3Q Provides no types for their player so this is typed as any.
 */
const playerInstance = ref<any>(null)

const loadPlayer = async (dataId: string, params?: embedParams | undefined) => {
  /**
   * 3Q player is loaded as a global variable so we need to use window to access it.
   * Direct access makes typescript really unhappy.
   */
  return new (window as any).js3q({
    dataid: dataId,
    container: '3QPlayer',
    ...params
  })
}

let timestampInterval: ReturnType<typeof setInterval> | null = null

onMounted(async () => {
  const apiKey = import.meta.env.VITE_PUBLIC_USER_API_KEY

  await loadJs3qScript()

  /**
   * Live Stream
   */
  if ('channelId' in props.identifiers) {
    const livePlayoutId = await getLivePlayoutId(props.identifiers.channelId, apiKey)

    if (!livePlayoutId) {
      throw new Error('No live playout id found')
    }

    playerInstance.value = await loadPlayer(livePlayoutId, props.embedParams)

    timestampInterval = setInterval(() => {
      const timestamp = playerInstance.value.getCurrentTimeStamp()
      const delay = playerInstance.value.getLiveDelayInSeconds()
      if (timestamp > 0) {
        emit('update-timestamp', { timestamp, delay })
      }
    }, 1000)
  } else if ('projectId' in props.identifiers && 'fileId' in props.identifiers) {
    /**
     * Video on Demand
     */
    const vodPlayoutId = await getVodPlayoutId(
      props.identifiers.projectId,
      props.identifiers.fileId,
      apiKey
    )

    if (!vodPlayoutId) {
      throw new Error('No vod playout id found')
    }

    playerInstance.value = await loadPlayer(vodPlayoutId, props.embedParams)
  }

  /**
   * Double check the status onMounted to make sure the player is paused if it should be.
   */
  if (playerInstance.value && props.status === 'pause') {
    playerInstance.value.pause()
  }
})

onUnmounted(() => {
  if (playerInstance.value) {
    playerInstance.value.destroy()
  }

  if (timestampInterval) {
    clearInterval(timestampInterval)
  }
})

/**
 * Watcher for the player status.
 */
watch(
  () => props.status,
  (newStatus) => {
    if (playerInstance.value) {
      if (newStatus === 'play') {
        playerInstance.value.play()
      } else if (newStatus === 'pause') {
        playerInstance.value.pause()
      }
    }
  }
)
</script>

<template>
  <div id="3QPlayer" />
</template>

<style>
/* Manually deactivate fullscreen button
see: https://player.docs.3q.video/player-api/custom-player-templates#roles-and-classes
*/
.sdn-button-right.sdnicbsun-fullscreen {
  display: none !important;
}
</style>
