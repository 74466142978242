<script setup lang="ts">
import { useScroll } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'

import { useEventSetup } from '@/composables/event/useEventSetup'

const props = defineProps<{
  access: boolean
  menuItems: Array<string>
  entityType?: string
  view?: string
}>()

const { t, currentEvent, currentEventLanguage } = useEventSetup()
const activeMenuItem = ref(props.menuItems[0]) // Default to the first item

// Track scroll position
const scrollPos = ref<any>(null) // Initialize as ref to avoid undefined issue
let scrollContainer: HTMLElement | Window

// Detect the active section based on scroll position
onMounted(() => {
  if (props.entityType === undefined) {
    // Use window scroll when entityType is undefined
    scrollContainer = window
    scrollPos.value = useScroll(window)
  } else {
    // Use client container for scrolling when entityType is defined
    scrollContainer = document.getElementsByClassName('preview-design-container')[0] as HTMLElement

    if (scrollContainer) {
      scrollPos.value = useScroll(scrollContainer)
    }
  }

  if (scrollPos.value) {
    // Add scroll event listener if scrollPos is available
    scrollContainer.addEventListener('scroll', handleScroll)
    watch(scrollPos.value, handleScroll)
  }
})

// Detect the active section based on scroll position
const handleScroll = () => {
  props.menuItems.forEach((item) => {
    let sectionId = ''

    if (currentEvent.value && currentEvent.value.url) {
      if (props.entityType === undefined) {
        sectionId = `${currentEvent.value.url[currentEventLanguage.value]}-${currentEvent.value.id}-${item}`
      } else if (props.entityType === 'design') {
        sectionId = `${currentEvent.value.url[currentEventLanguage.value]}-${currentEvent.value.id}-${props.access ? 'access' : 'no-access'}-${props.view}-${item}`
      } else {
        sectionId = `${props.access ? 'access' : 'no-access'}-${props.view}-template-${item}`
      }
    }

    const element = document.getElementById(sectionId)

    if (element) {
      const rect = element.getBoundingClientRect()
      if (rect.top <= 200 && rect.bottom > 200) {
        activeMenuItem.value = item
      }
    }
  })
}

// Update active menu item when clicked
const handleClick = (menuItem: string) => {
  activeMenuItem.value = menuItem
}

watch(scrollPos.value, handleScroll)
</script>

<template>
  <div class="sticky top-0 z-50 overflow-x-auto border-b border-black bg-white">
    <div class="container mx-auto w-full">
      <ul class="flex w-max flex-row gap-12 px-5 py-2.5">
        <li v-for="menuItem in menuItems" :key="menuItem">
          <a
            :href="
              currentEvent && currentEvent.url && entityType === undefined
                ? `#${currentEvent.url[currentEventLanguage]}-${currentEvent.id}-${menuItem}`
                : currentEvent && currentEvent.url && entityType === 'design'
                  ? `#${currentEvent.url[currentEventLanguage]}-${currentEvent.id}-${access ? 'access' : 'no-access'}-${view}-${menuItem}`
                  : `#${access ? 'access' : 'no-access'}-${view}-template-${menuItem}`
            "
            :class="{
              'text-[var(--secondary-color)] font-bold': activeMenuItem === menuItem,
              'text-audience-dark-grey': activeMenuItem !== menuItem
            }"
            @click="handleClick(menuItem)"
            class="capitalize"
          >
            {{ t(`global.${menuItem}`) }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
