<script setup lang="ts">
import { VButton, VModal, type VTab, VTabs } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import EventTable from '@/components/events/EventTable.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import type { components } from '@/types/swagger'

type Event = components['schemas']['Event']

const toast = useToast()

const { t } = useI18n()

const eventStore = useEventsStore()
const { prevEvents, upcEvents } = storeToRefs(eventStore)
const { fetchEventById, deleteEvent, fetchAllEvents, resetCurrentEvent } = eventStore

onMounted(async () => {
  await fetchAllEvents()
})

onUnmounted(() => {
  // Necessary to reset the current event when leaving the page
  resetCurrentEvent()
})

const currentTab = ref('upcoming')

const tabs = computed(() => [
  {
    title: t('views.events.index.upcoming'),
    name: 'upcoming'
  },
  {
    title: t('views.events.index.previous'),
    name: 'previous'
  }
])

const isDeleteModalOpen = ref<boolean>(false)
const eventToDelete = ref<Event | null>(null)

async function openDeleteModal(eventId: number) {
  await fetchEventById(eventId).then((response) => {
    eventToDelete.value = response
    isDeleteModalOpen.value = true
  })
}

function closeModal() {
  isDeleteModalOpen.value = false
}

// Function to delete the speaker
async function handleDeleteEvent(eventId: number) {
  await deleteEvent(eventId).then((response) => {
    if (response.ok) {
      closeModal()
      eventToDelete.value = null
      toast.success(t('views.events.index.eventDeleted'))
    }
  })
}
</script>

<template>
  <MainLayout>
    <section class="flex h-[calc(100vh-86px)] w-full flex-col text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center gap-4">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">Online Events</h1>
        <I18nRouterLink to="/events/new">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.index.newEvent')"
            :disabled="false"
            size="large"
          >
            <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <!-- TODO: Replace VTable with propper table library -->
      <!-- TODO: Fix shadow issue -->
      <VTabs
        v-model="currentTab as KeyType"
        :tabs="tabs as VTab<KeyType>[]"
        className="[&>*>table]:shadow-none [&>div:first-child]:bg-transparent h-full"
      >
        <template #upcoming>
          <!--  For whatever reason ts does not accept upcEvents as Event[] here -->
          <!-- @vue-ignore -->
          <EventTable :events="upcEvents" @openDeleteModal="openDeleteModal" />
          <div
            v-if="upcEvents.length === 0"
            class="flex w-full items-center justify-center rounded-lg border border-zinc-300 bg-white py-5
              dark:border-dark-grey dark:bg-dark-grey"
          >
            <p>{{ t('views.events.index.noUpcomingEvents') }}</p>
          </div>
        </template>

        <template #previous>
          <!--  For whatever reason ts does not accept prevEvents as Event[] here -->
          <!-- @vue-ignore -->
          <EventTable :events="prevEvents" @openDeleteModal="openDeleteModal" />
          <div
            v-if="prevEvents.length === 0"
            class="flex w-full items-center justify-center rounded-lg border border-zinc-300 bg-white py-5
              dark:border-dark-grey dark:bg-dark-grey"
          >
            <p>{{ t('views.events.index.noPastEvents') }}</p>
          </div>
        </template>
      </VTabs>
    </section>
    <template #modal>
      <VModal
        :type="'delete'"
        :trigger="isDeleteModalOpen"
        @update:trigger="isDeleteModalOpen = $event"
      >
        <template #modalHeader>
          <div v-if="eventToDelete" class="flex flex-col text-center uppercase">
            <p v-if="eventToDelete.name.de" class="text-lg text-dark-grey dark:text-white">
              {{ eventToDelete.name.de }}
            </p>
            <p
              v-if="eventToDelete.name.en"
              class="text-md text-misty-grey/60 dark:text-light-grey/50"
            >
              {{ eventToDelete.name.en }}
            </p>
          </div>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.confirmDeleteEvent') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="closeModal"
            />
            <VButton
              v-if="eventToDelete"
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleDeleteEvent(+eventToDelete!.id)
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
