<script setup lang="ts">
import { computed } from 'vue'

import logoPoweredByTechcast from '@/assets/images/techcast-logo-grey.png'
import { useEventSetup } from '@/composables/event/useEventSetup'

/****************************************
 * PROPS
 *****************************************/
const props = defineProps<{
  footerData: Array<any>
}>()

/****************************************
 * COMPOSABLES
 *****************************************/
const { t, currentEventLanguage } = useEventSetup()

const footerIsVisible = computed(() => {
  return (
    props.footerData[0] &&
    props.footerData[0].text[currentEventLanguage.value] !== '<p></p>' &&
    props.footerData[0].text[currentEventLanguage.value] !== '<p><br></p>'
  )
})
</script>

<template>
  <footer class="footer-text-color mt-12 bg-audience-light-grey md:mt-16">
    <div
      class="container relative mx-auto flex h-full w-full flex-col px-5 py-3"
      :class="[footerData.length === 1 ? 'gap-4' : 'gap-8', footerIsVisible && 'py-8']"
    >
      <div
        v-if="footerIsVisible"
        class="flex w-full flex-col flex-wrap md:flex-row md:justify-between"
      >
        <div
          v-if="footerData[0]"
          class="text-left"
          :class="footerData.length === 1 && 'flex w-full'"
        >
          <slot name="footerLeft">
            <div class="@mobile:!text-sm" v-html="footerData[0].text[currentEventLanguage]"></div>
          </slot>
        </div>
        <div v-if="footerData[1]" class="text-left md:text-right">
          <slot name="footerRight">
            <div v-html="footerData[1].text[currentEventLanguage]"></div>
          </slot>
        </div>
      </div>
      <div class="flex w-full justify-end">
        <img
          :src="logoPoweredByTechcast"
          class="h-auto max-h-[32px] w-auto"
          alt="Powered by Techcast"
        />
      </div>
    </div>
  </footer>
</template>

<style>
.client-container footer ol,
.client-container footer ul {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
.client-container footer a {
  transition: 0.3s;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: black;
  }
}
</style>
