<script setup lang="ts">
import { VButton, VInput, VModal } from '@techcast/histoire'

import { reset } from '@formkit/vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import QuillEditor from '@/components/utils/QuillEditor.vue'
import { cloneable } from '@/composables/useClone'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEmailTemplatesStore } from '@/stores/emailTemplates.store'
import { useEventsStore } from '@/stores/events.store'
import type { components } from '@/types/swagger'

/**
 * Toast notification
 */
const toast = useToast()

/**
 * Translation
 */
const { t, locale } = useI18n()

/****************************************
* TYPES
*****************************************/
type EmailTemplate = components['schemas']['EmailTemplate']

/****************************************
* STORES
*****************************************/
const emailTemplatesStore = useEmailTemplatesStore()
const { emailTemplates, currentEmailTemplate, currentEmailTemplateLanguage } =
  storeToRefs(emailTemplatesStore)
const { fetchEmailTemplateById, resetCurrentEmailTemplate } = emailTemplatesStore

const route = useRoute()
const eventId = route.params.id

/**
 * Lyfecycle hooks
 */
onMounted(async () => {
  if (emailTemplateId.value) {
    await fetchEmailTemplateById(+emailTemplateId.value)
  } else {
    resetCurrentEmailTemplate()
  }

  // Save a deep copy of the original email template state
  originalEmailTemplateState.value = cloneable.deepCopy(currentEmailTemplate.value)
})

/****************************************
* REFS
*****************************************/
const openModalDeleteWebform = ref(false) // Modal to confirm the deletion of the webform
const openEditTemplateNameModal = ref<boolean>(false) // Modal to edit the name of the email template
const savedTempOldTemplateName = ref<string>('') // Save the old name of the email template
// Clone the initial form data to compare against later
let originalEmailTemplateState = ref<EmailTemplate | null>(null)

/****************************************
* COMPUTED VARIABLES
*****************************************/
// Use route params to get current emailTemplateId
const emailTemplateId = computed(() => route.params.id)

/****************************************
* METHODS
*****************************************/
// This function will open the modal to edit the name of the email template
function openModalAndSaveTempTemplateName() {
  // savedTempOldTemplateName.value = currentEmailTemplate.value.name
  openEditTemplateNameModal.value = true
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <!-- If the emailTemplateId exists, the name of the template will be displayed and it's editable, otherwise an input field will be displayed to give it a name -->
        <div v-if="true" class="relative">
          <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
            {{ currentEmailTemplate?.name || t('views.templates.email.emailTemplate') }}
          </h1>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            class="absolute right-0 top-0 cursor-pointer"
            :functionOnClick="openModalAndSaveTempTemplateName"
          >
            <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="size-5 p-1.5" />
          </VButton>
        </div>
        <VInput
          v-else
          v-model="currentEmailTemplate.name"
          :label="t('global.name')"
          help=""
          class="w-300px"
          type="text"
          inputId="email-template-name"
          :required="true"
          :tooltip="t('global.requiredField')"
          :errorMessage="t('global.invalidValue')"
        />
      </div>
      <form @submit.prevent class="prose-ul:list-none" novalidate>
        <div
          class="h-[calc(100svh-18rem)] overflow-y-auto rounded-lg bg-white p-10 shadow md:h-[calc(100svh-21.5rem)]
            dark:bg-dark-grey"
        >
          <div class="mb-4 grid grid-cols-1 gap-4 xl:grid-cols-12 xl:gap-8">
            <VInput
              v-model="currentEmailTemplate.subject[currentEmailTemplateLanguage]"
              :label="t('views.templates.email.subject')"
              :placeholder="t('global.title')"
              class="col-span-1 xl:col-span-8 gap-4"
              type="text"
              inputId="email-template-subject"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
            />
            <div class="max-h-80 w-full relative z-10 col-span-1 xl:col-span-8">
              <QuillEditor
                :inputId="'registration-email'"
                ref="quillEditorEmail"
                :label="t('views.templates.email.content')"
                v-model:content="currentEmailTemplate.content[currentEmailTemplateLanguage]"
                contentType="html"
                :placeholder="t('views.templates.email.contentPlaceholder')"
              />
            </div>
            <div class="col-span-1 xl:col-span-4">
              <h3 class="mb-4">
                <strong>{{ t('views.templates.email.textBlocks') }}</strong>
              </h3>
              <p class="mb-3 text-xs">
                {{ $t('views.templates.email.placeholderInfo') }}
              </p>
            </div>
            <VButton
              type="button"
              appearance="default"
              :label="t('views.templates.email.sendTestEmail')"
              :disabled="false"
              size="medium"
              :functionOnClick="() => console.log('sendTestEmail')"
            />
          </div>
        </div>
        <div class="relative z-10 flex justify-end gap-8 py-5">
          <!-- TODO: add logic to display 'update'/'delete' or 'save' -->
          <!-- TODO: add logic for unsaved changes (:disabled="!hasUnsavedChanges") -->
          <div v-if="true" class="flex items-center gap-4">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.delete')"
              :disabled="false"
              size="large"
              :functionOnClick="() => (openModalDeleteWebform = true)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.update')"
              size="large"
              :functionOnClick="() => console.log('update')"
            />
          </div>
          <VButton
            v-if="false"
            type="submit"
            appearance="default"
            :label="t('global.save')"
            size="large"
            :functionOnClick="() => console.log('save')"
          />
        </div>
      </form>
    </section>
    <template #modal>
      <!-- Modal to edit the name of the email template -->
      <VModal
        v-model:trigger="openEditTemplateNameModal"
        :functionOnClose="
          () => {
            currentEmailTemplate.name === ''
              ? (currentEmailTemplate.name = savedTempOldTemplateName)
              : currentEmailTemplate.name
            savedTempOldTemplateName = ''
            openEditTemplateNameModal = false
          }
        "
        includeForm
      >
        <template #modalBody>
          <VInput
            v-model="currentEmailTemplate.name"
            :label="t('global.name')"
            help=""
            class="w-300px"
            type="text"
            inputId="email-template-name"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
          />
        </template>
        <template #modalFooter>
          <div class="flex justify-end">
            <VButton
              type="submit"
              size="medium"
              appearance="default"
              :label="t('global.close')"
              :functionOnClick="() => (openEditTemplateNameModal = false)"
            />
          </div>
        </template>
      </VModal>
      <!-- Delete Modal -->
      <VModal
        v-model:trigger="openModalDeleteWebform"
        :title="t('views.templates.email.modalDeleteRegistrationEmail')"
      >
        <template #modalHeader>
          <p>
            <strong>{{ t('views.templates.email.modalDeleteRegistrationEmail') }}</strong>
          </p>
        </template>
        <template #modalBody>
          <p>
            {{ t('views.templates.email.confirmDeleteRegistrationEmail') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="medium"
              :functionOnClick="() => (openModalDeleteWebform = false)"
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.yes')"
              :disabled="false"
              size="medium"
              :functionOnClick="() => (openModalDeleteWebform = false)"
            />
          </div>
        </template>
      </VModal>
    </template>
    <!-- TODO: Unsaved Changes Modal -->
  </MainLayout>
</template>
