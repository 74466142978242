import type { FormElement } from '@/types/FormElement'

export const audienceFormStyleClasses = {
  text: {
    outer: 'formkit-disabled:opacity-50 mb-5',
    label: 'block mb-2 text-base',
    inner: 'rounded-none border border-audience-dark-grey',
    input: 'border-0 w-full h-10 px-3 text-base disabled:shadow-none focus-visible:outline-none focus-visible:ring-0 focus-visible:border focus-visible:border-audience-dark-grey bg-white',
    option: 'text-base',
    help: 'text-xs mb-2',
    messages: 'pt-2',
    message: 'text-xs text-dark-red',
  },
  textarea: {
    outer: 'formkit-disabled:opacity-50 mb-5',
    label: 'block mb-2 text-base',
    inner: 'rounded-none border border-audience-dark-grey',
    input: 'border-0 block w-full h-32 p-4 border-none text-base focus:shadow-outline',
    option: 'text-base',
    help: 'text-xs mb-2',
    messages: 'pt-2',
    message: 'text-xs text-dark-red',
  },
  checkbox: {
    outer: 'formkit-disabled:opacity-50 mb-5',
    fieldset: '',
    legend: 'text-base mb-2',
    wrapper: 'flex items-start mb-2 cursor-pointer',
    help: 'text-xs mb-2',
    input:
      'border-0 relative top-[1px] form-check-input h-5 w-5 mr-2 !border !border-solid bg-white focus:outline-none focus:ring-0 transition duration-200 cursor-pointer',
    label: 'text-base [&_a]:underline',
    inner: 'relative top-[2px]',
    messages: 'pt-2',
    message: 'text-xs text-dark-red',
  },
  submit: {
    outer: 'formkit-disabled:opacity-50 mb-5',
    wrapper: 'flex justify-start',
    input:
      'border-0 text-[var(--button-text-color)] bg-[var(--button-background-color)] hover:bg-[var(--button-hover-color)] uppercase text-base mt-4 px-8 py-2 duration-300',
    messages: 'pt-2',
    message: 'text-xs text-dark-red',
  },
  radio: null as any, // Placeholder for radio, reassigned below
  email: null as any, // Placeholder for radio, reassigned below
  month: null as any, // Placeholder for radio, reassigned below
  number: null as any, // Placeholder for radio, reassigned below
  password: null as any, // Placeholder for radio, reassigned below
  tel: null as any, // Placeholder for radio, reassigned below
  select: null as any, // Placeholder for radio, reassigned below
  date: null as any, // Placeholder for radio, reassigned below
  datetimeLocal: null as any, // Placeholder for radio, reassigned below
}

// Assign `radio` to reuse `checkbox`
audienceFormStyleClasses.radio = audienceFormStyleClasses.checkbox;
// Assign the rest of elements to reuse `text`
audienceFormStyleClasses.email = audienceFormStyleClasses.text;
audienceFormStyleClasses.month = audienceFormStyleClasses.text;
audienceFormStyleClasses.number = audienceFormStyleClasses.text;
audienceFormStyleClasses.password = audienceFormStyleClasses.text;
audienceFormStyleClasses.tel = audienceFormStyleClasses.text;
audienceFormStyleClasses.select = audienceFormStyleClasses.text;
audienceFormStyleClasses.date = audienceFormStyleClasses.text;
audienceFormStyleClasses.datetimeLocal = audienceFormStyleClasses.text;

export const managerFormStyleClasses = {
  // style classes default label
  labelClasses: 'font-bold text-base text-dark-grey dark:text-light-grey',
  // style classes default wrapper
  wrapperClasses: 'flex flex-col gap-2',
  // style classes Select input
  inputClassesSelect: 'outline-none relative z-10 bg-ice-grey dark:bg-misty-grey w-full border rounded-md border-misty-grey/20 py-2 px-4 text-sm text-dark-grey focus:border-dark-grey focus:shadow-[0_0_5px_0_rgba(0,0,0,0.2)] dark:text-light-grey dark:focus:outline-none dark:focus:border-light-grey/50 dark:focus:shadow-[0_0_5px_0_rgba(255,255,255,0.2)] ',
  optionClassesSelect: 'font-bold text-base text-dark-grey dark:text-light-grey',
  // style classes Text input
  inputClassesText: 'w-full border bg-ice-grey rounded-md border-misty-grey/20 py-2 px-4 text-sm text-dark-grey placeholder:text-misty-grey/20 focus:outline-none focus:border-dark-grey focus:shadow-[0_0_5px_0_rgba(0,0,0,0.2)] dark:text-light-grey dark:placeholder:text-light-grey/50 dark:bg-misty-grey dark:border-misty-grey/30 dark:focus:text-light-grey dark:focus:outline-none dark:focus:border-light-grey/50 dark:focus:shadow-[0_0_5px_0_rgba(255,255,255,0.2)]',
  // style classes Textarea input
  inputClassesTextarea: 'w-full border bg-ice-grey rounded-md border-misty-grey/20 py-2 px-4 text-sm text-dark-grey placeholder:text-misty-grey/20 focus:outline-none focus:border-dark-grey focus:shadow-[0_0_5px_0_rgba(0,0,0,0.2)] dark:text-light-grey dark:placeholder:text-light-grey/50 dark:bg-misty-grey dark:border-misty-grey/30 dark:focus:text-light-grey dark:focus:outline-none dark:focus:border-light-grey/50 dark:focus:shadow-[0_0_5px_0_rgba(255,255,255,0.2)]',
  // style classes Checkbox input
  wrapperClassesCheckbox: 'flex items-center justify-center cursor-pointer gap-4',
  outerClassesCheckbox: 'flex items-center',
  innerClassesCheckbox: 'flex justify-center items-center',
  inputClassesCheckbox: 'relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 bg-ice-grey transition-all checked:bg-dark-green checked:border-light-green dark:bg-light-grey dark:border-transparent dark:checked:border-dark-green dark:checked:bg-light-green dark:checked:accent-dark-grey',
  // style classes Radio input
  inputClassesRadio: 'relative h-5 w-5 cursor-pointer appearance-none rounded-full border-2 bg-ice-grey transition-all checked:bg-dark-green checked:border-light-green dark:bg-light-grey dark:border-transparent dark:checked:border-dark-green dark:checked:bg-light-green dark:checked:accent-dark-grey',
  legendClassesRadio: 'mb-2',
  optionsClassesRadio: 'flex flex-col gap-2',
  optionClassesRadio: 'flex flex-row gap-2',
  wrapperClassesRadio: 'flex flex-row gap-2',
  innerClassesRadio: 'relative top-[2px]',
}

/* 
  With <FormKitSchema :schema="schema" /> Formkit generates a form: https://formkit.com/essentials/schema#form-generation-example
  Each input will have at least a $formkit key which tells Formkit what kind of input it is
  Also inline styles are permitted in 'config: { classes: { key: value } }' for each input, so the classes above will be applied to the inputs below
*/
export function generatelistOfAvailableInputs() {
  return [
    {
      $formkit: 'text',
      name: 'text',
      label: { de: '', en: '' },
      displayedName: { de: 'Text', en: 'Text' },
      placeholder: { de: '', en: '' },
      help: { de: '', en: '' },
      validationVisibility: 'submit',
      config: {
        classes: {
          label: managerFormStyleClasses.labelClasses,
          wrapper: managerFormStyleClasses.wrapperClasses,
          input: managerFormStyleClasses.inputClassesText
        }
      }
    },
    {
      $formkit: 'textarea',
      name: 'textarea',
      label: { de: '', en: '' },
      displayedName: { de: 'Textarea', en: 'Textarea' },
      placeholder: { de: '', en: '' },
      help: { de: '', en: '' },
      config: {
        classes: {
          label: managerFormStyleClasses.labelClasses,
          wrapper: managerFormStyleClasses.wrapperClasses,
          input: managerFormStyleClasses.inputClassesTextarea
        }
      }
    },
    {
      $formkit: 'radio',
      name: 'radioOptions',
      label: { de: '', en: '' },
      displayedName: { de: 'Radiofeld', en: 'Radio field' },
      placeholder: { de: '', en: '' },
      help: { de: '', en: '' },
      options: { de: [], en: [] },
      validationVisibility: 'submit',
      config: {
        classes: {
          label: managerFormStyleClasses.labelClasses,
          input: managerFormStyleClasses.inputClassesRadio,
          options: managerFormStyleClasses.optionsClassesRadio,
          option: managerFormStyleClasses.optionClassesRadio,
          fieldset: managerFormStyleClasses.wrapperClasses,
          legend: managerFormStyleClasses.legendClassesRadio,
          wrapper: managerFormStyleClasses.wrapperClassesRadio,
          inner: managerFormStyleClasses.innerClassesRadio,
        }
      }
    },
    {
      $formkit: 'select',
      name: 'selectOptions',
      label: { de: '', en: '' },
      displayedName: { de: 'Selectfeld', en: 'Select field' },
      placeholder: { de: 'Wählen Sie eine Option aus', en: 'Select an option' },
      help: { de: '', en: '' },
      options: { de: [], en: [] },
      validationVisibility: 'submit',
      config: {
        classes: {
          label: managerFormStyleClasses.labelClasses,
          input: managerFormStyleClasses.inputClassesSelect,
          option: managerFormStyleClasses.optionClassesSelect,
          wrapper: managerFormStyleClasses.wrapperClasses,
        }
      }
    },
    {
      $formkit: 'checkbox',
      name: 'checkbox',
      label: { de: 'checkbox', en: 'checkbox' },
      displayedName: { de: 'Checkbox', en: 'Checkbox' },
      validationVisibility: 'submit',
      __raw__sectionsSchema: {
        label: {
          $el: 'label',
          attrs: {
            innerHTML: { de: '', en: '' }
          }
        }
      },
      config: {
        classes: {
          wrapper: managerFormStyleClasses.wrapperClassesCheckbox,
          outer: managerFormStyleClasses.outerClassesCheckbox,
          inner: managerFormStyleClasses.innerClassesCheckbox,
          label: managerFormStyleClasses.labelClasses,
          input: managerFormStyleClasses.inputClassesCheckbox
        }
      }
    }
  ]
}

/*
  submitButton is a default submit button that will be automatically inserted at the bottom of each form
  It doesnt have any functionality, but the label can be edited to be shown in the real form button in the client side
*/
export const submitButton: FormElement = {
  $formkit: 'submit',
  name: 'submit',
  label: { de: 'Senden', en: 'Submit' },
  config: {
    classes: {
      input: 'w-auto text-base font-normal px-4 py-2 rounded leading-normal flex-shrink-0 transition duration-300 flex items-center gap-3 select-none text-white stroke-white bg-dark-grey hover:shadow hover:bg-dark-green disabled:cursor-not-allowed disabled:text-white disabled:bg-light-grey dark:text-dark-grey disabled:shadow-none dark:bg-light-grey dark:hover:bg-light-green dark:hover:text-dark-grey dark:disabled:text-dark-grey dark:disabled:bg-misty-grey'
    }
  }
}
