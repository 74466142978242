<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import BackButton from '@/components/nav/utilities/BackButton.vue'
import NavIcon from '@/components/nav/utilities/NavIcon.vue'
import NavLink from '@/components/nav/utilities/NavLink.vue'
import { useAuthStore } from '@/stores/auth.store'

/**
 * Component: BackButton
 *
 * Description:
 * This component represents a user-specific navigation menu displayed in the left sidebar. It includes a back button
 * that navigates to the events page, as well as links to the profile page and, conditionally, to the management page
 * if the user has admin privileges. It uses child components for navigation links and icons, with localization support
 * for the displayed text.
 *
 * Template:
 *  - BackButton: Navigates back to the `/events` route.
 *  - NavLink (Profile): Navigates to the user's profile page.
 *  - NavLink (Management): Conditionally rendered link to the management page, visible only to users with the 'admin' role.
 *
 */

const { t } = useI18n()

/****************************************
 * STORES
 *****************************************/
const userStore = useAuthStore()
const { user } = storeToRefs(userStore)
</script>

<template>
  <section class="relative overflow-scroll pb-[2rem]">
    <BackButton :to="`/events`" />
    <!-- NavLink component for navigating to the profile page -->
    <NavLink :to="`/profile`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'circle-user']" />
      </NavIcon>
      <span>{{ t('global.profile') }}</span>
    </NavLink>
    <!-- Conditionally show the management link if the user is an admin -->
    <NavLink v-if="user?.roles.includes('admin')" :to="`/management`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'users']" />
      </NavIcon>
      <span>{{ t('views.user.users') }}</span>
    </NavLink>
    <NavLink v-if="user?.roles.includes('admin')" :to="`/statistics-usage`">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'chart-simple']" />
      </NavIcon>
      <span>{{ t('components.nav.UserInfoMenu.statisticsUsage') }}</span>
    </NavLink>
  </section>
</template>
