<script setup lang="ts">
import { VButton } from '@techcast/histoire'

import { useEventSetup } from '@/composables/event/useEventSetup'
import { useDarkMode } from '@/utils/useDarkMode'

/**
 * This component creates a top navigation menu with language switch buttons, dark mode toggles,
 * and additional icons. It uses functionality from the `useEventSetup` composable to handle language
 * switching and `useDarkMode` composable to toggle between light and dark modes.
 */

// Import dark mode utilities and language setup.
const { isDarkMode, setDarkMode } = useDarkMode()
const { i18n, switchLanguage, supportedLocales } = useEventSetup()
</script>

<template>
  <nav
    class="flex w-full items-center justify-end gap-4 py-[0.5625rem] text-2xl text-dark-grey
      dark:text-light-grey"
  >
    <!-- Loop through supportedLocales to create language switch buttons -->
    <template v-for="locale in supportedLocales" :key="locale">
      <VButton
        v-if="locale !== i18n.locale.value"
        :input-id="locale"
        :functionOnClick="switchLanguage"
        type="button"
        appearance="empty"
        has-language
        :language="locale"
        size="small"
      />
    </template>
    <!-- Icon separator -->
    <font-awesome-icon :icon="['fal', 'pipe']" />
    <!-- Dark mode toggle: sun icon for light mode, moon icon for dark mode -->
    <FontAwesomeIcon
      v-if="isDarkMode"
      @click="setDarkMode(false)"
      :icon="['fal', 'sun-bright']"
      class="cursor-pointer"
    />
    <FontAwesomeIcon
      v-if="!isDarkMode"
      @click="setDarkMode(true)"
      :icon="['fal', 'moon']"
      class="cursor-pointer px-[3px]"
    />
    <!-- Information icon -->
    <FontAwesomeIcon :icon="['fal', 'circle-info']" />
  </nav>
</template>
